import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, CategoryService, ConfirmationComponent, Pager, PreviewMediaComponent, Response, ResponseService, ResponseTypeEnum, UtilHelper } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ResponseEditComponent } from './response-edit/response-edit.component';

@Component({
  selector: 'response-component',
  templateUrl: 'response.component.html'
})
export class ResponseComponent extends AbstractComponent implements OnInit {

  pager: Pager<Response> = new Pager<Response>({ perPage: 10 });
  listObservable: Observable<Pager<Response>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  listCategory: any[] = [];

  labelFilter: string;

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public responseService: ResponseService,
    private categoryService: CategoryService,
    private matDialogPreview: MatDialog,
  ) {
    super(injector);
    this.createForm();
    this.loadingContent = true;
  }

  ngOnInit(): void {
    this.getList(this.searchString);
    this.getListCategory();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      type: [ResponseTypeEnum.QUICK],
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.responseService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Response>) => {
      this.pager = pager;
    });
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList(this.searchString);
  }

  search(termo: string) {
    this.pager.page = 1;
    this.searchString = termo;
    this.termoDaBusca.next(termo);
  }

  getList(term: string) {
    this.loadingContent = true;
    this.searchParams = {
      company: super.getIDCurrentCompany(),
      type: ResponseTypeEnum.QUICK,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
        this.setupObservableSearch();
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  add(): void {
    const dialogRef = this.dialog.open(ResponseEditComponent, {
      width: '800px',
      data: { type: this.formGroup.get('type')?.value }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList(this.searchString);
    });
  }

  edit(item: Response): void {
    const dialogRef = this.dialog.open(ResponseEditComponent, {
      width: '800px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList(this.searchString);
    });
  }

  delete(item: Response): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) == true) {
        this.responseService.delete(item._id).subscribe({
          next: (value) => {
            this.alertService.success('Resposta excluída com sucesso.');
            this.getList(this.searchString);
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      };
    });
  }

  preview(item: Response): void {
    const dialogRef = this.matDialogPreview.open(PreviewMediaComponent, {
      width: '600px',
      data: {
        isTableResponseApp: true,
        response: item,
      }
    });
  }

  selectedCategory(category: string): void {
    this.getList(category);
    this.labelFilter = category === '' ? 'Categorias' : category;
  }

  private getListCategory(): void {
    this.categoryService.getList().subscribe({
      next: (value) => {
        this.listCategory = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  public getFileExtension(item: Response) { 
    if(item.vcardContact) {
      return 'Contato';
    }
    if(item.place) {
      return 'Localização';
    }
    return UtilHelper.getFileExtension(item.contentType);
  }

}
