import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, Tag } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { getAttendance } from '../../state/actions';
import { AppState } from '../../state/app.state';
import { attendanceSelector, tagsSelector } from '../../state/selectors';

@Component({
  selector: 'service-tag-attendance',
  templateUrl: './tag-attendance.component.html',
  styleUrls: ['./tag-attendance.component.scss'],
})
export class TagAttendanceComponent extends AbstractComponent implements OnInit, OnDestroy {

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly tags$: Observable<Array<Tag>> = this.store.select(tagsSelector);

  separatorKeysCodes: number[] = [ENTER, COMMA];
  tags: Array<Tag> = new Array<Tag>();
  showSelectTags: boolean = true;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    private attendanceService: AttendanceService,
    private _dialogRef: MatDialogRef<TagAttendanceComponent>,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance,
  ) {
    super(injector);
    this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
  }

  ngOnInit(): void {
    this.attendance$.subscribe(attendance => {
      this.attendance = attendance;
    });

    this.tags$.subscribe(tags => this.tags = tags);
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getFilteredList() {
    return this.attendance?.tags;
  }

  selected(event: MatSelectChange): void {
    this.addRemoveTag(event.value._id);
    this.showSelectTags = true;
  }

  remove(tag: Tag): void {
    this.addRemoveTag(tag._id);
  }

  addRemoveTag(idTag: string) {
    if (this.attendance.status === AttendanceStatusEnum.CLOSED) {
      this.alertService.info('Não é possível adicionar ou remover tags para atendimentos com status fechado');
      return;
    }

    this.attendanceService.addRemoveTag({ idTag, idAttendance: this.attendance._id }).subscribe({
      next: () => {
        this.alertService.success();
        this.store.dispatch(getAttendance({ idAttendance: this.attendance._id }));
      },
      error: (err) => this.alertService.error(err.error.message),
      complete: () => { },
    });
  }

  openSelectTags(): void {
    this.showSelectTags = false;
  }

  closeComponentTag(): void {
    this._dialogRef.close();
  }
}
