import { Component, Inject, Injector } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Category, CategoryService, Contact, ContactListModalComponent, FirebaseService, GoogleMapsComponent, GooglePlaceType, Response, ResponseService, UploadTypeEnum, UtilHelper } from 'lib-trend-core';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { CategoryAddComponent } from '../../category/category-add/category-add.component';

@Component({
  selector: 'response-edit-component',
  templateUrl: './response-edit.component.html',
  styleUrls: ['response-edit.component.scss']
})
export class ResponseEditComponent extends AbstractComponent {
  filterControlCategory: FormControl = new FormControl('');

  response: Response = {} as Response;

  listCategory: Array<Category> = new Array<Category>();
  allCategories: Array<Category> = new Array<Category>();

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  maxCharacters: number = 1200;
  remainingCharacters: number = this.maxCharacters;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ResponseEditComponent>,
    public responseService: ResponseService,
    private categoryService: CategoryService,
    private dialogCategory: MatDialog,
    private firebaseService: FirebaseService,
    private modalSendContact: MatDialog,
    private modalSendLocation: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: Response
  ) {
    super(injector);
    this.response = {
      type: data.type
    } as Response;
    this.createForm();
    this.getListCategory();
    this.fetchFilterCategory();
  }

  ngOnInit() {
    this.isNew = !this.data || !this.data._id;

    if (!this.isNew) {
      this.response = this.data;
      this.response.type = this.data.type;
      this.response.category = this.data.category;
      this.formGroup.patchValue({ ...this.data, category: this.data.category._id });
      if (this.data.vcardContact) {
        this.formGroup.get('typeResponse').setValue('CONTACT');
      } else if (this.data.place) {
        this.formGroup.get('typeResponse').setValue('LOCATION');
      }
      this.updateCount(this.formGroup.get('message').value);
    } else {
      this.response.type = this.data.type;
      this.formGroup.patchValue({ type: this.data.type });
    }
    this.formGroup.controls['typeResponse'].valueChanges.subscribe(value => {
      if (value === 'CONTACT') {
        this.formGroup.get('vcardContact').setValidators([Validators.required]);
        this.formGroup.get('place').clearValidators();
      } else if (value === 'LOCATION') {
        this.formGroup.get('vcardContact').clearValidators();
        this.formGroup.get('place').setValidators([Validators.required]);
      } else {
        this.formGroup.get('vcardContact').clearValidators();
        this.formGroup.get('place').clearValidators();
      }
      this.formGroup.get('vcardContact').setValue(null);
      this.formGroup.get('message').setValue(null);
      this.formGroup.get('place').setValue(null);
      this.formGroup.get('vcardContact').updateValueAndValidity();
      this.formGroup.get('message').updateValueAndValidity();
      this.formGroup.get('place').updateValueAndValidity();
    });
  }

  updateCount(value?: string): void {
    if (value) {
      this.remainingCharacters = this.maxCharacters - value.length;
    }

    let lenthMessage: string = this.formGroup.get('message')?.value;
    if (lenthMessage.length > this.maxCharacters) {
      this.formGroup.get('message').setValue(lenthMessage.substring(0, this.maxCharacters));
    }

    this.remainingCharacters = this.maxCharacters - lenthMessage.length;
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      category: [null, [Validators.required]],
      message: [null, [Validators.required, Validators.maxLength(this.maxCharacters)]],
      type: [this.data.type, Validators.required],
      typeResponse: ['TEXT'],
      vcardContact: [null],
      place: [null]
    });
    this.formGroup.valueChanges.subscribe(value => {
      Object.assign(this.response, value);
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return;
    }

    this.response = { ...this.response, ...this.formGroup.value };

    if (this.isNew) {
      this.responseService.create(this.response).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Resposta salva com sucesso.');
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    } else {
      this.responseService.update(this.response._id, this.response).subscribe({
        next: (value) => {
          this.dialogRef.close(value);
          this.alertService.success('Resposta atualizada com sucesso.')
        },
        error: (err) => this.alertService.error(err.error.message),
      });
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          this.loadingSpinnerSubject.next(true);
          const filename = file.name + '_' + new Date().getTime();
          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.RESPONSE).then((snapshot: { url: string }) => {
            this.loading = false;
            this.response.midia = snapshot.url;
            this.response.contentType = file.type;
            this.loadingSpinnerSubject.next(false);
          });
        } catch (error) {
          this.alertService.error('Ops! Ocorreu um erro ao tentar fazer ulpoad do seu arquivo. Tente novamente mais tarde.');
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  addCategory(): void {
    const dialogRefCategory = this.dialogCategory.open(CategoryAddComponent, {
      width: '600px',
    });

    dialogRefCategory.afterClosed().subscribe(result => {
      this.fetchFilterCategory();
    });
  }

  midiaImage(): string {
    if (this.response.midia) {
      const mediaType = this.helperExtension(this.response.midia);

      switch (mediaType) {
        case 'image':
          return this.response.midia;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_file;
        default:
          return UtilHelper.default_image;
      }
    } else {
      return UtilHelper.no_image;
    }
  }

  removeMidia(): void {
    if (this.response.midia) {
      this.firebaseService.deleteFile(this.response.midia)
        .then(() => {
          this.resetFileState();
        })
        .catch(error => {
          this.alertService.error('Ops! Ocorreu um erro ao tentar deletar o arquivo.');
          this.resetFileState();
        });
    }
  }

  private helperExtension(midia: string): 'image' | 'audio' | 'video' | 'document' | 'text' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart.trim()) {
      case 'mp4':
        return 'video';
      case 'pdf':
        return 'document';
      case 'mp3':
        return 'audio';
      case 'png':
        return 'image';
      case 'jpg':
        return 'image';
      case 'jpeg':
        return 'image'
      default:
        return 'text';
    }
  }

  private resetFileState(): void {
    this.response.midia = '';
  }

  private getListCategory(): void {
    this.categoryService.getList().subscribe({
      next: value => {
        this.allCategories = value;
        this.listCategory = value;
      },
      error: err => this.alertService.error('Ops! Ocorreu um erro ao tentar buscar por todas as categorias. Tente novamente mais tarde.'),
    });
  }

  private fetchFilterCategory(): void {
    this.filterControlCategory.valueChanges.pipe(
      debounceTime(300),
    ).subscribe((value: string) => {
      const currentValue = this.formGroup.get('category')?.value || null;

      this.listCategory = this.allCategories.filter(category =>
        category.name.toLowerCase().includes(value.toLowerCase()) &&
        category._id !== currentValue
      );

      const existingCategory = this.allCategories.find(category => category._id === currentValue);
      if (existingCategory) {
        this.listCategory.push(existingCategory);
      }
    });
  }

  onFocusCategory(): void {
    this.listCategory = this.allCategories;
  }

  isTypeText(): boolean {
    return this.formGroup.get('typeResponse')?.value === 'TEXT';
  }

  isTypeContact(): boolean {
    return this.formGroup.get('typeResponse')?.value === 'CONTACT';
  }

  isTypeLocation(): boolean {
    return this.formGroup.get('typeResponse')?.value === 'LOCATION';
  }

  selectContact(): void {
    const dialogRef = this.modalSendContact.open(ContactListModalComponent, {
      maxHeight: '1200px',
      minHeight: '900px',
      width: '1200px'
    });
    dialogRef.afterClosed().subscribe((contact: Contact) => {
      if (!!contact) {
        this.formGroup.get('vcardContact').setValue(contact);
        this.formGroup.get('message').setValue(`Nome: ${contact.name} - Telefone: ${contact.phone}`);
      }
    });
  }

  selectLocation(): void {
    const dialogRef = this.modalSendLocation.open(GoogleMapsComponent, {
      maxHeight: '710px',
      minHeight: '710px',
      width: '790px'
    });
    dialogRef.afterClosed().subscribe((place: GooglePlaceType) => {
      if (!!place) {
        this.formGroup.get('place').setValue(place);
        this.formGroup.get('message').setValue(`Nome: ${place.formattedAddress} - Longitude: ${place.location.longitude} - Latitude: ${place.location.latitude}`);
      }
    });
  }

  getContact() {
    return this.formGroup.get('vcardContact').value;
  }

  getPlace() {
    return this.formGroup.get('place').value;
  }

  removeContact(): void {
    this.formGroup.get('vcardContact').setValue(null);
  }

  removePlace(): void {
    this.formGroup.get('place').setValue(null);
  }
}
