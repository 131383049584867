
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Attendance, Contact, Message, UtilHelper } from 'lib-trend-core';

interface ContactMessage {
  profile: string;
  name: string;
  phone: string;
};

@Component({
  selector: 'chat-message-contact-component',
  templateUrl: './chat-message-contact.component.html',
  styleUrls: ['./chat-message-contact.component.scss']
})
export class ChatMessageContactComponent extends AbstractComponent implements OnInit {

  @Input() attendance: Attendance;
  @Input() message: Message;

  dataContactMessage!: ContactMessage;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  ngOnInit(): void {
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  getIconForContentType(contentType: string): string {
    return UtilHelper.getIconForContentType(contentType);
  }

  scrollToMessage(event: MouseEvent, messageId: string) {
    event.preventDefault();
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  formatContactMessage(vcardContact: Contact): ContactMessage {
    return this.dataContactMessage = {
      profile: UtilHelper.no_image,
      name: vcardContact?.name,
      phone: vcardContact?.phone,
    };
  }
}

