import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Attendance, Contact, Note, NoteService } from 'lib-trend-core';
import { Store } from '@ngrx/store';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { delayWhen } from 'rxjs/operators';
import { AlertService } from 'lib-trend-core';
import { attendanceSelector, notesSelector } from '../../../state/selectors';
import { setNotes } from '../../../state/actions';
import { AppState } from '../../../state/app.state';

@Component({
  selector: 'service-note-attendance',
  templateUrl: './list-note-attendance.component.html',
})
export class ListNoteAttendanceComponent implements OnInit {

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly notes$: Observable<Array<Note>> = this.store.select(notesSelector);

  attendance: Attendance;
  formGroup: FormGroup;
  selectedNote: Note | null = null;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  constructor(
    private store: Store<AppState>,
    public dialog: MatDialog,
    public noteService: NoteService,
    private formBuilder: FormBuilder,
    private alertService: AlertService
  ) {
    this.createForm();
  }

  ngOnInit(): void {
    this.attendance$.subscribe(attendance => {
      this.attendance = attendance;
      // this.getList();
    });
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      description: ['', Validators.required],
    });
  }

  delete(note: Note): void {
    this.loadingSpinnerSubject.next(true);

    this.noteService.delete(note._id).subscribe({
      next: () => {
        this.getList();
        this.alertService.success('Nota excluída com sucesso.');
        this.loadingSpinnerSubject.next(false);
      },
      error: (err) => {
        this.alertService.error(err.error.message);
        this.loadingSpinnerSubject.next(false);
      }
    });
  }

  selectNoteForEdit(note: Note): void {
    this.selectedNote = note;
    this.formGroup.patchValue({ description: note.description });
  }

  save(): void {
    if (this.formGroup.valid) {
      this.loadingSpinnerSubject.next(true);
      if (this.selectedNote) {
        const updatedNote: Note = {
          ...this.selectedNote,
          description: this.formGroup.value.description,
          contact: <Contact>{ _id: this.attendance?.contact?._id },
        };

        this.noteService.update(updatedNote._id, updatedNote).subscribe({
          next: () => {
            this.getList();
            this.selectedNote = null;
            this.formGroup.reset();
            this.alertService.success('Nota editada com sucesso.');
            this.loadingSpinnerSubject.next(false);
          },
          error: (err) => {
            this.alertService.error(err.error.message);
            this.loadingSpinnerSubject.next(false);
          }
        });
      } else {
        const note: Note = {
          ...this.formGroup.value,
          attendance: this.attendance,
          contact: this.attendance.contact
        } as Note;
        this.noteService.create(note).subscribe({
          next: () => {
            this.getList();
            this.formGroup.reset();
            this.alertService.success('Nota salva com sucesso.');
            this.loadingSpinnerSubject.next(false);
          },
          error: (err) => {
            this.alertService.error(err.error.message);
            this.loadingSpinnerSubject.next(false);
          }
        });
      }
    } else {
      this.alertService.error('Por favor, preencha a descrição.');
    }
  }

  getList(): void {
    if (this.attendance?._id) {
      this.loadingSpinnerSubject.next(true);
      const contactId = this.attendance?.contact?._id || 'null';

      this.noteService.getByAttendanceAndContact(this.attendance._id, contactId)
        .subscribe({
          next: (notes) => {
            this.store.dispatch(setNotes({ notes }));
            this.loadingSpinnerSubject.next(false);
          },
          error: (err) => {
            this.alertService.error(err.error.message);
            this.loadingSpinnerSubject.next(false);
          }
        });
    }
  }

}
