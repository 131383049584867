import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard, AuthGuardAdmin, AuthGuardAttendant, AuthGuardSupervisor } from 'lib-trend-core'
import { ContactComponent } from './modules/contact/contact.component'
import { DashboardComponent } from './modules/dashboard/dashboard.component'
import { FlowComponent } from './modules/flow/flow.component'
import { BaseComponent } from './modules/layout/base/base.component'
import { RedirectComponent } from './modules/redirect/redirect.component'
import { ResponseComponent } from './modules/response/response.component'
import { TagComponent } from './modules/tag/tag.component'

const routes: Routes = [

  { path: 'login', loadChildren: () => import('../app/modules/login/login.module').then((m) => m.LoginModule) },
  { path: 'redirect', component: RedirectComponent },

  {
    path: '', component: BaseComponent, canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: DashboardComponent, canActivate: [AuthGuardAttendant] },
      { path: 'report', loadChildren: () => import('../app/modules/report/report.module').then((m) => m.ReportModule), canActivate: [AuthGuardAttendant] },
      { path: 'contact', loadChildren: () => import('../app/modules/contact/contact.module').then((m) => m.ContactModule), canActivate: [AuthGuardAttendant] },
      { path: 'department', loadChildren: () => import('../app/modules/department/department.module').then((m) => m.DepartmentModule), canActivate: [AuthGuardSupervisor] },
      { path: 'attendance', loadChildren: () => import('../app/modules/attendance/attendance.module').then((m) => m.AttendanceModule), canActivate: [AuthGuardAttendant] },
      { path: 'tag', component: TagComponent, canActivate: [AuthGuardSupervisor] },
      { path: 'user', loadChildren: () => import('../app/modules/user/user.module').then((m) => m.UserModule), canActivate: [AuthGuardAdmin] },
      { path: 'response', component: ResponseComponent, canActivate: [AuthGuardSupervisor] },
      { path: 'channel', loadChildren: () => import('../app/modules/channel/channel.module').then((m) => m.ChannelModule), canActivate: [AuthGuardAdmin] },
      { path: 'wa-template', loadChildren: () => import('../app/modules/wa-template/wa-template.module').then((m) => m.WaTemplateModule), canActivate: [AuthGuardAdmin] },
      { path: 'flow', loadChildren: () => import('../app/modules/flow/flow.module').then((m) => m.FlowModule), canActivate: [AuthGuardAdmin] },
      { path: 'campaign', loadChildren: () => import('../app/modules/campaign/campaign.module').then((m) => m.CampaignModule), canActivate: [AuthGuardSupervisor] },
      { path: 'config', loadChildren: () => import('../app/modules/config/config.module').then((m) => m.ConfigModule), canActivate: [AuthGuardSupervisor] },
      { path: 'notification', loadChildren: () => import('../app/modules/notification/notification.module').then((m) => m.NotificationModule), canActivate: [AuthGuardAttendant] },
      { path: 'integrations', loadChildren: () => import('../app/modules/integrations/integrations.module').then((m) => m.IntegrationsModule), canActivate: [AuthGuardAttendant] },
    ],
  },

  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
