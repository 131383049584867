
import { Component, Injector, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Attendance, GoogleMapsViewComponent, GooglePlaceType, Message, UtilHelper } from 'lib-trend-core';

@Component({
  selector: 'chat-message-location-component',
  templateUrl: './chat-message-location.component.html',
  styleUrls: ['./chat-message-location.component.scss']
})
export class ChatMessageLocationComponent extends AbstractComponent implements OnInit {

  @Input() attendance: Attendance;
  @Input() message: Message;

  dataLocationMessage!: GooglePlaceType;

  constructor(
    injector: Injector,
    private modalSendLocation: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    console.log(this.message);
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  getIconForContentType(contentType: string): string {
    return UtilHelper.getIconForContentType(contentType);
  }

  scrollToMessage(event: MouseEvent, messageId: string) {
    event.preventDefault();
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  openLocation() {
      this.modalSendLocation.open(GoogleMapsViewComponent, {
        maxHeight: '550px',
        minHeight: '550px',
        width: '790px',
        data: this.message
      });
  }

}

