"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageService = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const stream_1 = require("stream");
const message_1 = require("../models/cloud-api/message");
const base_service_1 = require("./base.service");
class MessageService extends base_service_1.RestService {
    constructor() {
        super();
    }
    sendTextMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.text,
                text: {
                    body: message.text,
                    preview_url: false
                },
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    sendMediaMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            let content = {};
            if (message.type === message_1.TypeMessageObjectEnum.image) {
                content = {
                    image: {
                        link: message.link,
                        caption: (_a = message.caption) !== null && _a !== void 0 ? _a : '',
                    }
                };
            }
            else if (message.type === message_1.TypeMessageObjectEnum.document) {
                content = {
                    document: {
                        link: message.link,
                        caption: (_b = message.caption) !== null && _b !== void 0 ? _b : '',
                    }
                };
            }
            else if (message.type === message_1.TypeMessageObjectEnum.video) {
                content = {
                    video: {
                        link: message.link,
                        caption: (_c = message.caption) !== null && _c !== void 0 ? _c : '',
                    }
                };
            }
            else if (message.type === message_1.TypeMessageObjectEnum.audio) {
                content = {
                    audio: {
                        link: message.link,
                    }
                };
            }
            else if (message.type === message_1.TypeMessageObjectEnum.sticker) {
                content = {
                    sticker: {
                        link: message.link,
                    }
                };
            }
            // type is audio, , , sticker, or 
            const json = Object.assign({ messaging_product: "whatsapp", to: message.to, type: message.type }, content);
            return yield _super.post.call(this, url, json, config);
        });
    }
    sendReactionMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.reaction,
                reaction: {
                    message_id: message.message_id,
                    emoji: message.emoji
                }
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    sendLocationMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const { latitude, longitude, name, address } = message.location;
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.location,
                location: {
                    latitude, longitude, name, address
                }
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    sendContactMessage(contactMessageRequest) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(contactMessageRequest.token);
            const url = this.getCloudAPIURL(contactMessageRequest.phoneNumberID, 'messages');
            const json = {
                messaging_product: "whatsapp",
                type: message_1.TypeMessageObjectEnum.contacts,
                to: contactMessageRequest.to,
                contacts: contactMessageRequest.contacts
            };
            console.log('json', JSON.stringify(json, null, 2));
            return yield _super.post.call(this, url, json, config);
        });
    }
    updateStatus(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const json = {
                messaging_product: "whatsapp",
                status: "read",
                message_id: message.id
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    getMediaById(message) {
        const _super = Object.create(null, {
            get: { get: () => super.get }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const baseUrl = this.getBaseCloudAPIURL();
            const url = `${baseUrl}/${message.id}`;
            return yield _super.get.call(this, url, config);
        });
    }
    getDownloadByURL(message) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, e_1, _b, _c;
            const url = `${message.url}`;
            const headers = { Authorization: `Bearer ${message.token}` };
            const response = yield axios_1.default.request({
                url,
                method: 'GET',
                responseType: 'stream',
                headers
            });
            const chunks = response.data.pipe(new stream_1.PassThrough({ encoding: 'base64' }));
            let str = '';
            try {
                for (var _d = true, chunks_1 = tslib_1.__asyncValues(chunks), chunks_1_1; chunks_1_1 = yield chunks_1.next(), _a = chunks_1_1.done, !_a; _d = true) {
                    _c = chunks_1_1.value;
                    _d = false;
                    let chunk = _c;
                    str += chunk;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (!_d && !_a && (_b = chunks_1.return)) yield _b.call(chunks_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return str;
        });
    }
}
exports.MessageService = MessageService;
