import { Component, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, ConfirmationComponent, Contact, Note, NoteService } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { getNotes } from '../../state/actions';
import { AppState } from '../../state/app.state';
import { attendanceSelector, notesSelector } from '../../state/selectors';
import { EditNoteAttendanceComponent } from './edit-note-attendance/edit-note-attendance.component';
import { ListNoteAttendanceComponent } from './list-note-attendance/list-note-attendance.componente';


@Component({
  selector: 'service-note-attendance',
  templateUrl: './note-attendance.component.html',
  styleUrls: ['./note-attendance.component.scss']
})
export class NoteAttendanceComponent extends AbstractComponent {

  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);
  readonly notes$: Observable<Array<Note>> = this.store.select(notesSelector);

  attendance: Attendance;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
    public dialog: MatDialog,
    public noteService: NoteService,
  ) {
    super(injector);
    this.attendance$.subscribe(attendance => {
      this.attendance = attendance;
    });
    this.createForm();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      description: ['', Validators.required],
    });
  }

  delete(note: Note): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.noteService.delete(note._id).subscribe({
          next: (value) => {
            this.store.dispatch(getNotes({ idAttendance: this.attendance._id, idContact: this.attendance.contact._id }));
            this.alertService.success('Nota excluída com sucesso.');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  edit(note: Note): void {
    const dialogRef = this.dialog.open(EditNoteAttendanceComponent, {
      width: '600px',
      data: { note: note }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.noteService.update(result._id, result).subscribe({
          next: () => {
            this.store.dispatch(getNotes({ idAttendance: this.attendance._id, idContact: this.attendance.contact._id }));
            this.alertService.success('Nota editada com sucesso.');
          },
          error: (err) => this.alertService.error(err.error.message)
        });
      }
    });
  }

  save(): void {
    if (this.formGroup.valid) {
      const note: Note = {
        ...this.formGroup.value,
        attendance: this.attendance,
        contact: <Contact>{ _id: this.attendance?.contact?._id },
      } as Note;
      this.noteService.create(note).subscribe({
        next: (value) => {
          this.formGroup.reset();
          this.alertService.success('Nota salva com sucesso.');
          this.store.dispatch(getNotes({ idAttendance: this.attendance._id, idContact: this.attendance.contact._id }));
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    } else {
      this.alertService.error('Por favor, preencha a descrição.');
    }
  }

  listNote() {
    this.dialog.open(ListNoteAttendanceComponent, {
      width: '600px',
      minHeight: '200px'
    });
  }

}
