<div [formGroup]="itemForm" class="item">
  @if (isWhatsappPhone() || isContactName()) {
  <div>
    <label class="font-black text-black text-[20px] flex justify-between">{{getLabel()}}
      <span *ngIf="!isRequired()" class="text-red-700 cursor-pointer" (click)="!isActive && deleteComponent()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.89362 6.10035H8.01797V5.20035C8.01797 3.98729 9.03007 3 10.2743 3H13.7263C14.9706 3 15.9827 3.98732 15.9827 5.20035V6.10035H19.107C19.6009 6.10035 20 6.48965 20 6.97148C20 7.45331 19.6009 7.84262 19.107 7.84262H18.6305V18.132C18.6313 19.7166 17.3157 21 15.6967 21H8.30404C6.68498 21 5.36946 19.7166 5.36946 18.132V7.84262H4.893C4.39908 7.84262 4 7.45405 4 6.97148C4.00076 6.48965 4.39984 6.10035 4.89376 6.10035H4.89362ZM14.1967 5.20035C14.1967 4.945 13.9881 4.74146 13.7263 4.74146H10.2743C10.0118 4.74146 9.80317 4.945 9.80317 5.20035V6.10035H14.1968L14.1967 5.20035ZM13.3273 16.7842C13.3273 17.266 13.7256 17.6553 14.2203 17.6553C14.7142 17.6553 15.1133 17.266 15.1133 16.7842V10.3161C15.1133 9.83429 14.7142 9.44498 14.2203 9.44498C13.7263 9.44498 13.3273 9.83429 13.3273 10.3161V16.7842ZM8.88741 16.7842C8.88741 17.266 9.28649 17.6553 9.78041 17.6553C10.2743 17.6553 10.6726 17.266 10.6726 16.7842V10.3161C10.6726 9.83429 10.2743 9.44498 9.78041 9.44498C9.28649 9.44498 8.88741 9.83429 8.88741 10.3161V16.7842Z"
            fill="#E24B41"></path>
        </svg>
      </span>
    </label>
    <p class="py-2 mb-3 font-normal text-[14px] text-mutted">
      {{getMessage()}}
    </p>
  </div>
  <div class="textarea-container">
    <textarea #messageTextarea formControlName="value" class="input-field h-[80px]" placeholder="Digite..."
      maxlength="320" (keydown)="onKeydown($event)"></textarea>
    <field-error-component [field]="'value'" [label]="'Valor'" [formGroup]="itemForm"></field-error-component>
    <div class="w-full h-[200px] overflow-y-scroll" style="
          padding: 1rem;
          box-sizing: border-box;
          border: 1px solid #bdbdbd;
          border-radius: 0.5rem;
        ">
      @for(request of webhookRequests | keyvalue; track $index){
      <div class="text-[14px]flex flex-row gap-1 pt-1 first:pt-0" id="requestText">
        <span class="w-full font-black text-black cursor-pointer" (click)="!isActive && addValue(request.key)">
          {{ request.key }}:
        </span>
        <span>
          {{ request.value }}
        </span>
      </div>
      }
    </div>
  </div>
  } @else if (isAddTag() || isRemoveTag()) {
  <div>
    <label class="font-black text-black text-[20px] flex justify-between">{{getLabel()}}
      <span *ngIf="!isRequired()" class="text-red-700 cursor-pointer" (click)="!isActive && deleteComponent()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.89362 6.10035H8.01797V5.20035C8.01797 3.98729 9.03007 3 10.2743 3H13.7263C14.9706 3 15.9827 3.98732 15.9827 5.20035V6.10035H19.107C19.6009 6.10035 20 6.48965 20 6.97148C20 7.45331 19.6009 7.84262 19.107 7.84262H18.6305V18.132C18.6313 19.7166 17.3157 21 15.6967 21H8.30404C6.68498 21 5.36946 19.7166 5.36946 18.132V7.84262H4.893C4.39908 7.84262 4 7.45405 4 6.97148C4.00076 6.48965 4.39984 6.10035 4.89376 6.10035H4.89362ZM14.1967 5.20035C14.1967 4.945 13.9881 4.74146 13.7263 4.74146H10.2743C10.0118 4.74146 9.80317 4.945 9.80317 5.20035V6.10035H14.1968L14.1967 5.20035ZM13.3273 16.7842C13.3273 17.266 13.7256 17.6553 14.2203 17.6553C14.7142 17.6553 15.1133 17.266 15.1133 16.7842V10.3161C15.1133 9.83429 14.7142 9.44498 14.2203 9.44498C13.7263 9.44498 13.3273 9.83429 13.3273 10.3161V16.7842ZM8.88741 16.7842C8.88741 17.266 9.28649 17.6553 9.78041 17.6553C10.2743 17.6553 10.6726 17.266 10.6726 16.7842V10.3161C10.6726 9.83429 10.2743 9.44498 9.78041 9.44498C9.28649 9.44498 8.88741 9.83429 8.88741 10.3161V16.7842Z"
            fill="#E24B41"></path>
        </svg>
      </span>
    </label>
    <p class="py-2 mb-3 font-normal text-[14px] text-mutted">
      {{getMessage()}}
    </p>
  </div>
  <div class="textarea-container">
    <mat-select class="input-field" formControlName="value">
      @for (item of tags; track item) {
      <mat-option [value]="item._id">{{item.title}}</mat-option>
      }
    </mat-select>
    <field-error-component [field]="'value'" [label]="'Tag'" [formGroup]="itemForm"></field-error-component>
  </div>
  } @else if(isStartAttendance()) {
  <div>
    <label class="font-black text-black text-[20px] flex justify-between">{{getLabel()}}
      <span *ngIf="!isRequired()" class="text-red-700 cursor-pointer" (click)="!isActive && deleteComponent()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.89362 6.10035H8.01797V5.20035C8.01797 3.98729 9.03007 3 10.2743 3H13.7263C14.9706 3 15.9827 3.98732 15.9827 5.20035V6.10035H19.107C19.6009 6.10035 20 6.48965 20 6.97148C20 7.45331 19.6009 7.84262 19.107 7.84262H18.6305V18.132C18.6313 19.7166 17.3157 21 15.6967 21H8.30404C6.68498 21 5.36946 19.7166 5.36946 18.132V7.84262H4.893C4.39908 7.84262 4 7.45405 4 6.97148C4.00076 6.48965 4.39984 6.10035 4.89376 6.10035H4.89362ZM14.1967 5.20035C14.1967 4.945 13.9881 4.74146 13.7263 4.74146H10.2743C10.0118 4.74146 9.80317 4.945 9.80317 5.20035V6.10035H14.1968L14.1967 5.20035ZM13.3273 16.7842C13.3273 17.266 13.7256 17.6553 14.2203 17.6553C14.7142 17.6553 15.1133 17.266 15.1133 16.7842V10.3161C15.1133 9.83429 14.7142 9.44498 14.2203 9.44498C13.7263 9.44498 13.3273 9.83429 13.3273 10.3161V16.7842ZM8.88741 16.7842C8.88741 17.266 9.28649 17.6553 9.78041 17.6553C10.2743 17.6553 10.6726 17.266 10.6726 16.7842V10.3161C10.6726 9.83429 10.2743 9.44498 9.78041 9.44498C9.28649 9.44498 8.88741 9.83429 8.88741 10.3161V16.7842Z"
            fill="#E24B41"></path>
        </svg>
      </span>
    </label>
    <p class="py-2 mb-3 font-normal text-[14px] text-mutted">
      {{getMessage()}}
    </p>
  </div>
  <div class="textarea-container pb-2">
    <mat-select class="input-field" formControlName="value" (selectionChange)="loadUsersByDepartment()">
      @for (item of departments; track item) {
      <mat-option [value]="item._id">{{item.name}}</mat-option>
      }
    </mat-select>
    <field-error-component [field]="'value'" [label]="'Departamento'" [formGroup]="itemForm"></field-error-component>
  </div>
  <div class="textarea-container">
    <mat-select class="input-field" formControlName="attendent">
      @for (item of users; track item) {
      <mat-option [value]="item._id">{{item.name}}</mat-option>
      }
    </mat-select>
  </div>
  } @else if (isSendMessage()) {
  <div>
    <label class="font-black text-black text-[20px] flex justify-between">{{getLabel()}}
      <span *ngIf="!isRequired()" class="text-red-700 cursor-pointer" (click)="!isActive && deleteComponent()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.89362 6.10035H8.01797V5.20035C8.01797 3.98729 9.03007 3 10.2743 3H13.7263C14.9706 3 15.9827 3.98732 15.9827 5.20035V6.10035H19.107C19.6009 6.10035 20 6.48965 20 6.97148C20 7.45331 19.6009 7.84262 19.107 7.84262H18.6305V18.132C18.6313 19.7166 17.3157 21 15.6967 21H8.30404C6.68498 21 5.36946 19.7166 5.36946 18.132V7.84262H4.893C4.39908 7.84262 4 7.45405 4 6.97148C4.00076 6.48965 4.39984 6.10035 4.89376 6.10035H4.89362ZM14.1967 5.20035C14.1967 4.945 13.9881 4.74146 13.7263 4.74146H10.2743C10.0118 4.74146 9.80317 4.945 9.80317 5.20035V6.10035H14.1968L14.1967 5.20035ZM13.3273 16.7842C13.3273 17.266 13.7256 17.6553 14.2203 17.6553C14.7142 17.6553 15.1133 17.266 15.1133 16.7842V10.3161C15.1133 9.83429 14.7142 9.44498 14.2203 9.44498C13.7263 9.44498 13.3273 9.83429 13.3273 10.3161V16.7842ZM8.88741 16.7842C8.88741 17.266 9.28649 17.6553 9.78041 17.6553C10.2743 17.6553 10.6726 17.266 10.6726 16.7842V10.3161C10.6726 9.83429 10.2743 9.44498 9.78041 9.44498C9.28649 9.44498 8.88741 9.83429 8.88741 10.3161V16.7842Z"
            fill="#E24B41"></path>
        </svg>
      </span>
    </label>
    <p class="py-2 mb-3 font-normal text-[14px] text-mutted">
      {{getMessage()}}
    </p>
  </div>
  <div class="textarea-container">
    <mat-select class="input-field" formControlName="typeMessage">
      <mat-option [value]="'text'">Texto</mat-option>
      <mat-option [value]="'image'">Imagem</mat-option>
      <mat-option [value]="'audio'">Áudio</mat-option>
      <mat-option [value]="'video'">Vídeo</mat-option>
      <mat-option [value]="'document'">Documento</mat-option>
    </mat-select>
    <field-error-component [field]="'typeMessage'" [label]="'Tipo da Mensagem'"
      [formGroup]="itemForm"></field-error-component>
  </div>
  <div class="textarea-container pt-2" *ngIf="isTypeMessageMedia()">
    <input class="input-field" maxlength="100" formControlName="caption" placeholder="Caption...">
  </div>
  <div class="textarea-container pt-2">
    <textarea #messageTextarea formControlName="value" class="input-field h-[80px]" (keydown)="onKeydown($event)"
      placeholder="Digite..." maxlength="320" (paste)="onPaste($event)"></textarea>
    <field-error-component [field]="'value'" [label]="'Texto'" [formGroup]="itemForm"></field-error-component>
    <div class="icon-container flex items-center justify-around mt-2">
      <div class="flex min-w-fit flex-1 items-center justify-end">
        <mat-icon (click)="!isActive && applyFormat('bold')" class="cursor-pointer ml-2">
          format_bold
        </mat-icon>
        <mat-icon (click)="!isActive && applyFormat('italic')" class="cursor-pointer ml-2">
          format_italic
        </mat-icon>
        <mat-icon (click)="!isActive && applyFormat('underline')" class="cursor-pointer ml-2">
          format_underline
        </mat-icon>
        <mat-icon (click)="!isActive && toogleContainerEmoji()" class="cursor-pointer ml-2">
          sentiment_satisfied
        </mat-icon>
        <div #emojiContainer class="relative" id="emoji-container">
          <ng-container *ngIf="showContainerEmoji">
            <emoji-mart (emojiSelect)="!isActive && addEmoji($event)" [i18n]="translator" [darkMode]="false"
              class="absolute bottom-300 right-[40px] lg:bottom-100">
            </emoji-mart>
          </ng-container>
        </div>
        <button [matMenuTriggerFor]="menuRequest"
          class="w-[fit-content] ml-3 px-2 py-2 flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]">
          Adicionar requisições
        </button>
      </div>
    </div>
  </div>
  <ng-container class="flex flex-1">
    <mat-menu #menuRequest="matMenu">
      @for(request of webhookRequests | keyvalue; track $index){
      <button class="text-center" mat-menu-item (click)="!isActive && insertRequestOnMessage(request.key)">
        {{ request.key }}
      </button>
      }
    </mat-menu>
  </ng-container>
  } @else if (isAddTemplate()) {

  <!-- template list -->
  <webhook-integration-wa-template *ngIf="!showPreviewTemplate" [channel]="channel"
    (onSelectTemplate)="onSelectTemplate($event)" />

  <div *ngIf="showPreviewTemplate">
    <div class="w-full flex flex-1 justify-between items-center mb-4">
      <h1 class="font-black text-[1.5rem] text-black">
        Personalize o template
      </h1>
      <button class="rounded-lg" style="padding: 0.5rem; border: 1px solid #bdbdbd"
        (click)="!isActive && changeTemplate()">
        Trocar template
      </button>
    </div>

    <!-- template params and preview -->
    <webhook-integration-wa-template-message 
      [template]="selectedTemplate"
      [webhookRequests]="webhookRequests" 
      [webhookIntegrations]="webhookIntegrations" 
      (templateParams)="setTemplateMessage($event)" 
      />
  </div>
  }
</div>