<loading-component class="loading-spinner" *ngIf="loadingSpinner$ | async"></loading-component>
<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Listar mensagens agendadas</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Veja todas as mensagens agendadas</h6>
  <mat-select class="input-field max-w-[100%] mt-[25px] mb-[25px]" 
  [(value)]="selectedStatus" 
  (selectionChange)="onStatusChange($event)">

  <mat-select-trigger>
    {{ selectedStatus === 'todos' ? 'Todos' : translateStatus(selectedStatus) }}
  </mat-select-trigger>

  <mat-option [value]="'todos'">Todos</mat-option>

  <mat-option *ngFor="let status of statusList" [value]="status">
    {{ status === 'todos' ? 'Todos' : translateStatus(status) }}
  </mat-option>
</mat-select>
  <div style="height: 355px; overflow-y: auto;">
    <div class="p-2">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let message of messagesScheduled" hideToggle>
          <mat-expansion-panel-header class="w-full flex flex-col justify-center items-center bg-[#fafbff]">
            <mat-panel-title class="flex items-center space-x-4">
              <span 
              [ngClass]="{
                'bg-red-500': message.status === 'ERROR',
                'bg-orange-500': message.status === 'WAITING',
                'bg-blue-500': message.status === 'DONE',
                'bg-amber-400': message.status === 'MANUAL',
                'py-2': message.status !== 'MANUAL',  
                'py-1': message.status === 'MANUAL'  
              }" 
              class="px-3 text-white rounded-lg flex-none w-[120px] text-center leading-tight"
            >
              {{ message.status === 'DONE' ? 'Concluído' :
              message.status === 'ERROR' ? 'Erro' :
              message.status === 'WAITING' ? 'Aguardando' :
              message.status === 'MANUAL' ? 'Manual' :
              '-' }}
              
              <span *ngIf="message.status === 'MANUAL'" class="inline-flex items-center text-xs">
                <span class="text-[10px] mr-1 font-bold">{{ message.updatedAt | date: 'dd/MM/yyyy' }}</span>
                <span class="text-[10px]  font-bold"> {{ message.updatedAt | date: 'HH:mm' }}</span>
              </span>
            </span>
              <p class="ml-4 flex-1">{{ message.contact?.name ?? 'Contato' | truncate: 20  }}</p>

              <p class="ml-4 flex-1">{{ message.channel?.name ?? 'Canal' }}</p>
              <div class="ml-4 text-xs flex-1 text-zinc-700">
                <div class="flex justify-center items-center space-x-4">
                  <p class="text-center">{{ message.scheduledDate | date: 'dd/MM/yyyy' }}</p>
                  <p class="text-center">{{ formatTime(message.scheduledTime) }}</p>
                </div>

              </div>
              <mat-icon [mat-dialog-close]="" *ngIf="message.contentType !== 'template'" 
              style="color: #fea81d;" 
              [style.visibility]="message.status === 'DONE' || message.status === 'MANUAL' || message.status === 'ERROR' ? 'hidden' : 'visible'" 
              class="ml-2 cursor-pointer" 
              (click)="triggerFlashAction(message); $event.stopPropagation()">
              flash_on
            </mat-icon>
            
            <mat-icon 
            style="color: #4213F6;" 
            [style.visibility]="message.status === 'DONE' || message.status === 'MANUAL' || message.status === 'ERROR' ? 'hidden' : 'visible'" 
            class="ml-2 cursor-pointer" 
            (click)="selectMessageForEdit(message); $event.stopPropagation()">
            edit
          </mat-icon>
  
            <mat-icon 
            class="ml-2 cursor-pointer"  
            [style.visibility]="message.status === 'WAITING' ? 'visible' : 'hidden'" 
            (click)="deleteMessage(message); $event.stopPropagation()" 
            style="color: #4213F6;">
            delete
          </mat-icon>
            
            </mat-panel-title>
          </mat-expansion-panel-header>

          <mat-panel-description>
            <mat-panel-description>
              <div class="flex justify-center flex-col bg-[#E5FFFA] shadow-global text-black p-3 rounded-lg" *ngIf="message.type === 'template'">
                @if (isTemplateMidia(message)) {
                  @if (isTemplateImage(message)) {
                    <div class="flex justify-center flex-col items-center max-w-[300px]">
                      <a data-fancybox="gallery" [href]="message.content">
                        <img [src]="message.content" alt="Image" class="max-w-[600px] w-full object-contain rounded-lg" />
                      </a>
                      <p class="message-text-operator mt-2 break-words items-center w-full"
                        [innerHTML]="getContentTemplateMessage(message)">
                      </p>
                    </div>
                  }
                  @if (isTemplateDocument(message)) {
                    <div class="flex justify-center flex-col items-center max-w-[350px]">
                      <a class="space-x-2 text-black my-2" [href]="message.content" target="_blank">
                        <div class="w-[300px] items-center flex">
                          <div class="w-[50px]">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12.75L12 20.25L4.5 12.75" />
                            </svg>
                          </div>
                          <h1 class="mt-2 ml-[10px] w-[300px] items-center">Clique aqui para abrir seu documento.</h1>
                        </div>
                      </a>
                      <p class="message-text-operator mt-2 break-words items-center w-full"
                        [innerHTML]="getContentTemplateMessage(message)">
                      </p>
                    </div>
                  }
                  @if (isTemplateVideo(message)) {
                    <div class="flex justify-center flex-col items-center max-w-[300px]">
                      <div class="flex items-center justify-between w-full">
                        <a data-fancybox="gallery" [href]="message.content">
                          <video width="320" height="240" controls class="rounded-lg">
                            <source [src]="message.content" type="video/mp4">
                            Seu navegador não suporta a tag de vídeo.
                          </video>
                        </a>
                      </div>
                      <p class="message-text-operator mt-2 break-words items-center w-full"
                        [innerHTML]="getContentTemplateMessage(message)">
                      </p>
                    </div>
                  }
                } @else {
                  <div>
                    <p class="message-text-operator max-w-[600px] break-words items-center"
                      [innerHTML]="formatWhatsappMessage(message.content)">
                    </p>
                    <div *ngIf="getTemplateButtons(message).length > 0">
                      <div class="mt-2 text-sm flex flex-col items-center w-full">
                        <ng-container *ngFor="let button of getTemplateButtons(message); let i = index">
                          <div class="flex items-center justify-center w-full border-t border-gray-500 py-1">
                            <mat-icon style="font-size: 16px; color: #000;">reply</mat-icon>
                            <span class="ml-2">{{ button.text }}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                }
              
                <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1">
                  <span class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span>
                </small>
              </div>

<div *ngIf="message.type !== 'template'">
  <p 
  class="text-black bg-[#E5FFFA] rounded-lg p-2 shadow-global max-w-[590px] break-words" 
  *ngIf="message.caption || message.contentType?.startsWith('image/') || message.contentType?.startsWith('video/'); else textMessage">

  <ng-container *ngIf="message.contentType === 'application/pdf'">
    <div class="flex border border-[#bdbdbd] rounded-lg p-4 mb-4 items-center justify-center">
      <mat-icon class="text-black">description</mat-icon>
      <p>Documento anexado.</p>
    </div>
    <span [innerHTML]="formatWhatsappMessage(message.caption ?? '')"></span>
  </ng-container>

  <ng-container *ngIf="message.contentType?.startsWith('video/')">
    <video controls [src]="message.content ?? ''" class="max-w-[400px] rounded-lg mb-4 m-auto p-2">
      Seu navegador não suporta vídeos.
    </video>
    <span [innerHTML]="formatWhatsappMessage(message.caption ?? '')"></span>
  </ng-container>

  <ng-container *ngIf="message.contentType?.startsWith('image/')">
    <img [src]="message.content ?? ''" alt="Imagem" class="max-w-[400px] rounded-lg mb-4 m-auto p-2">
    <span [innerHTML]="formatWhatsappMessage(message.caption ?? '')"></span>
  </ng-container>
</p>

<ng-template #textMessage>
  <ng-container *ngIf="message.contentType?.startsWith('text')">
    <p class="text-black bg-[#E5FFFA] rounded-lg p-2 shadow-global max-w-[590px] break-words">
      <span [innerHTML]="formatWhatsappMessage(message.content ?? '')"></span>
    </p>
  </ng-container>

  <ng-container *ngIf="message.content && message.contentType && !message.caption">
    <ng-container *ngIf="message.contentType === 'application/pdf'">
      <div class="flex border border-[#bdbdbd] rounded-lg p-4 mb-4 items-center justify-center">
        <mat-icon class="text-black">description</mat-icon>
        <p>Documento anexado.</p>
      </div>
    </ng-container>

    <ng-container *ngIf="message.contentType === 'audio/mpeg'">
      <div class="flex border border-[#bdbdbd] rounded-lg p-4 mb-4 items-center justify-center">
        <mat-icon class="text-black">audiotrack</mat-icon>
        <p>Audio anexado.</p>
      </div>
    </ng-container>

    <ng-container *ngIf="message.contentType?.startsWith('video/')">
      <video controls [src]="message.content ?? ''" class="max-w-[400px] rounded-lg mb-4 m-auto p-2">
        Seu navegador não suporta vídeos.
      </video>
    </ng-container>

    <ng-container *ngIf="message.contentType?.startsWith('image/')">
      <img [src]="message.content ?? ''" alt="Imagem" class="max-w-[400px] rounded-lg mb-4 m-auto p-2">
    </ng-container>
  </ng-container>
</ng-template>
</div>
            </mat-panel-description>
          </mat-panel-description>
          
          
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <empty-records-component 
    *ngIf="!loadingContent && pager.total === 0"
    [message]="'Nenhuma mensagem agendada encontrada'"
    [subMessage]="'Tente alterar o status. Elas aparecerão aqui assim que estiverem disponíveis.'">
  </empty-records-component>
  </div>

</div>
<paginator *ngIf="pager.total > pager.perPage" class="flex justify-content-center mt-4" [pager]="pager"
  (onPager)="loadPage($event)">
</paginator>


