import { Component, Injector } from '@angular/core'
import { FormArray, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api'
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum, Company, ConfirmationComponent, MetadataMessage, Traduct, Webhook, WebhookIntegrationItemType, WebhookIntegrationItemTypeTranslate, WebhookIntegrations, WebhookIntegrationsService, WebhookRequestTypeEnum } from 'lib-trend-core'
import { v4 as uuidv4 } from 'uuid'
import { environment } from '../../../../../environments/environment'

@Component({
  selector: 'webhook-integrations-form-component',
  templateUrl: './webhook-integrations-form.component.html',
  styleUrls: ['./webhook-integrations-form.component.scss'],
})
export class WebhookIntegrationsFormComponent extends AbstractComponent {
  webhookIntegrations?: WebhookIntegrations
  selectedChannel: Channel
  templateMessage: MetadataMessage;

  isActive: boolean = false;
  url: string
  webhookRequests: object
  webhookSelectedId: string
  showPreviewTemplate: boolean
  previewHeader: any
  translator!: Traduct

  requestsSelected: Array<object> = new Array<object>()
  channels: Array<Channel> = new Array<Channel>()

  selectedTemplate: TemplateMessageObject

  webhooks: Array<Webhook> = new Array<Webhook>()

  actionsType: string[];


  constructor(
    injector: Injector,
    public channelService: ChannelService,
    public webhookIntegrationsService: WebhookIntegrationsService,
    public dialog: MatDialog,
  ) {
    super(injector)
    this.createForm();
  }

  ngOnInit() {
    this.webhookIntegrations = <WebhookIntegrations>{}
    const idWebhook = this.getParam('id')
    this.isNew = !idWebhook ? true : false

    this.isActive = this.webhookIntegrations.actived;
    this.channelService.getList().subscribe({
      next: (channels) => {
        this.channels = channels;
        if (this.isNew) {
          this.webhookIntegrations.uuid = uuidv4();
          this.webhookIntegrations.url = this.generateWebhookIntegrationsURLbyCode(this.webhookIntegrations.uuid);
          this.formGroup.get('url').setValue(this.webhookIntegrations.url);
          this.formGroup.get('company').setValue(this.getIDCurrentCompany());
          this.formGroup.get('uuid').setValue(this.webhookIntegrations.uuid);
          this.addComponent(WebhookIntegrationItemType.WHATSAPP_PHONE);
          this.addComponent(WebhookIntegrationItemType.CONTACT_NAME);
        } else {
          this.webhookIntegrationsService.getById(idWebhook).subscribe({
            next: (webhookIntegrations) => {
              this.webhookIntegrations = webhookIntegrations;
              this.populateForm();
              this.getWebhookRequests();
              this.selectedChannel = this.channels.find((c) => c._id === this.formGroup.get('channel').value);
              this.createActionTypeList();
            },
            complete: () => {
            }
          })
        }
      },
      complete: () => {
        this.selectedChannel = !this.isNew ? this.channels.find((c) => c._id === this.webhookIntegrations.channel as unknown) : this.selectedChannel
      }
    })
  }

  ngAfterViewInit(): void {
  }

  override ngOnDestroy(): void {
  }

  selectChannel(idChannel: string): void {
    this.selectedChannel = this.channels.find((c) => c._id === idChannel)

    this.createActionTypeList();
    if (this.selectedChannel.type === ChannelTypeEnum.CLOUD_API) {
      this.items.controls = this.items.controls.filter((item) => {
        return item.get('type').value !== WebhookIntegrationItemType.ADD_TAG &&
          item.get('type').value !== WebhookIntegrationItemType.REMOVE_TAG &&
          item.get('type').value !== WebhookIntegrationItemType.SEND_MESSAGE &&
          item.get('type').value !== WebhookIntegrationItemType.ADD_TEMPLATE
      });
      this.addComponent(WebhookIntegrationItemType.ADD_TEMPLATE);
    } else {
      this.items.controls = this.items.controls.filter((item) => {
        return item.get('type').value !== WebhookIntegrationItemType.ADD_TEMPLATE
      });
    }
  }

  toggleWebhookStatus() {
    this.webhookIntegrations.actived = !this.webhookIntegrations.actived;
    if (this.isActive && !this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      this.isActive = false;
      return
    }
    if (this.isActive) {
      this.save();
    } else {
      this.ableDisableFields();
    }
  }

  changeChannelDialog(idChannel: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: 'Realmente deseja mudar de canal? As ações serão deletadas',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.selectChannel(idChannel)
        this.alertService.success('Mudança de canal realizada com sucesso.');
      }
    });
  }

  async copyUrl() {
    try {
      await navigator.clipboard.writeText(this.webhookIntegrations.url)
      this.alertService.info('A url do Webhook foi copiada!')
    } catch (error) {
      console.error(error.message)
    }
  }

  save() {
    if (!this.formGroup.valid) {
      console.log(this.formGroup);
      this.validateAllFormFields(this.formGroup)
      return
    }
    const actions = [];
    this.items.controls.forEach((item) => {
      let action = null;
      if (item.get('type').value === WebhookIntegrationItemType.WHATSAPP_PHONE) {
        action = {
          type: "phone",
          value: item.get('value').value
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.CONTACT_NAME) {
        action = {
          type: "name",
          value: item.get('value').value
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.ADD_TAG) {
        action = {
          type: "addTag",
          value: item.get('value').value
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.REMOVE_TAG) {
        action = {
          type: "removeTag",
          value: item.get('value').value
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.START_ATTENDANCE) {
        action = {
          type: "attendance",
          value: {
            department: item.get('value').value,
            user: item.get('attendent').value
          }
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.SEND_MESSAGE) {
        action = {
          type: "message",
          typeMessage: item.get('typeMessage').value,
          value: item.get('value').value,
          caption: item.get('caption').value
        }
      } else if (item.get('type').value === WebhookIntegrationItemType.ADD_TEMPLATE) {
        action = {
          type: "message",
          typeMessage: 'template',
          template: item.get('template').value,
          templateParamsHeader: item.get('templateParamsHeader').value,
          templateParamsBody: item.get('templateParamsBody').value,
          previewText: item.get('previewText').value,
        }
      }
      actions.push(action);
    });
    const webhook = <WebhookIntegrations>{
      _id: this.webhookIntegrations._id,
      uuid: this.webhookIntegrations.uuid,
      name: this.formGroup.get('name').value,
      url: this.formGroup.get('url').value,
      actived: this.isActive,
      company: <Company>{ _id: super.getIDCurrentCompany() },
      channel: <Channel>{ _id: this.selectedChannel._id },
      webhook: <Webhook>{ _id: this.webhookSelectedId },
      actions: actions,
    }

    if (!this.isNew) {
      this.webhookIntegrationsService
        .update(this.webhookIntegrations._id, webhook)
        .subscribe({
          next: () => {
            this.alertService.success();
            this.goBack();
          },
          error: (err) => this.alertService.error(err.error.message),
        })
    } else {
      this.webhookIntegrationsService.create(webhook).subscribe({
        next: () => {
          this.alertService.success();
          this.goBack();
        },
        error: (err) => this.alertService.error(err.error.message),
      })
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      name: [null, Validators.required],
      url: [null, Validators.required],
      channel: [null, Validators.required],
      company: [null, Validators.required],
      actived: [false, Validators.required],
      webhook: [null, Validators.required],
      uuid: [null, Validators.required],
      requestType: [WebhookRequestTypeEnum.DEFAULT, Validators.required],
      tags: [null],
      items: this.formBuilder.array([])
    });
  }

  populateForm() {
    this.formGroup.get('_id').setValue(this.webhookIntegrations._id);
    this.formGroup.get('name').setValue(this.webhookIntegrations.name);
    this.formGroup.get('url').setValue(this.webhookIntegrations.url);
    this.formGroup.get('uuid').setValue(this.webhookIntegrations.uuid);
    this.formGroup.get('actived').setValue(this.webhookIntegrations.actived);
    this.formGroup.get('channel').setValue(this.webhookIntegrations.channel);
    this.formGroup.get('webhook').setValue(this.webhookIntegrations.webhook);
    this.formGroup.get('company').setValue(this.webhookIntegrations.company);
    this.webhookSelectedId = this.webhookIntegrations.webhook as unknown as string;
    this.isActive = this.webhookIntegrations.actived;
    this.webhookIntegrations.actions.forEach((action: any) => {
      let item = null;
      if (action.type === 'phone') {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.WHATSAPP_PHONE, Validators.required],
          label: ['Telefone Whatsapp'],
          message: ['Selecione o campo com o número de WhatsApp da lista de requisições à esquerda. O código do país (DDI - country code) deve ser inserido junto com o telefone. Caso o mesmo estiver em um campo separado, adicione ambos os campos. Se o DDI não estiver no campo do número, nem em um campo separado, por favor insira-o manualmente.'],
          value: [action.value, Validators.required],
          required: [true],
        });

      } else if (action.type === 'name') {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.CONTACT_NAME, Validators.required],
          label: ['Criar/Atualizar Nome do Contato'],
          message: ['Selecione o campo com o nome completo do contato da lista de requisições à esquerda. O contato será cadastrado em sua audiência caso o mesmo não esteja cadastrado. No caso do nome estar separado em dois campos, primeiro nome e sobrenome, adicione ambos os campos.'],
          value: [action.value, Validators.required],
          required: [true],
        });
      } else if (action.type === 'addTag') {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.ADD_TAG, Validators.required],
          label: ['Adicionar etiqueta'],
          message: ['Selecione a etiqueta que será adicionada ao contato após o mesmo ser cadastrado em sua audiência.'],
          value: [action.value, Validators.required],
          required: [false],
        });
      } else if (action.type === 'removeTag') {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.REMOVE_TAG, Validators.required],
          label: ['Remover etiqueta'],
          message: ['Selecione a etiqueta que será removida do contato. Caso o contato não possua a etiqueta, essa ação será ignorada e pulada.'],
          value: [action.value, Validators.required],
          required: [false],
        });
      } else if (action.type === 'attendance') {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.START_ATTENDANCE, Validators.required],
          label: ['Iniciar atendimento'],
          message: ['Selecione um departamento para dar início a um atendimento, você também pode selecionar um atendente específico, mas essa parte é opcional'],
          value: [action.value.department, Validators.required],
          attendent: [action.value.user],
          required: [false],
        });
      } else if (action.type === 'message') {
        if (action.typeMessage === 'template') {
          item = this.formBuilder.group({
            type: [WebhookIntegrationItemType.ADD_TEMPLATE, Validators.required],
            template: [action.template, Validators.required],
            templateParamsHeader: [action.templateParamsHeader],
            templateParamsBody: [action.templateParamsBody, Validators.required],
            previewText: [action.previewText, Validators.required],
            required: [true],
          });
        } else {
          item = this.formBuilder.group({
            type: [WebhookIntegrationItemType.SEND_MESSAGE, Validators.required],
            label: ['Enviar mensagem'],
            message: ['Você pode enviar diferentes tipos de mensagens com esta ação. Selecione o tipo de mensagem que você deseja enviar e adicione o conteúdo da mesma abaixo. Para Imagem, Áudio, Vídeo ou Documento por favor insira o link do arquivo do mesmo terminado com o tipo da extensão do arquivo. (Ex: "https://yourfilelink.com/file/image.jpg")'],
            typeMessage: [action.typeMessage, Validators.required],
            value: [action.value, Validators.required],
            caption: [action.caption],
            required: [false],
          });
        }
      }
      if (item) {
        this.items.push(item);
      }
    });
    if (this.isActive) {
      this.formGroup.get('name').disable();
      this.formGroup.get('url').disable();
      this.formGroup.get('channel').disable();
      this.formGroup.get('company').disable();
      this.formGroup.get('webhook').disable();
      this.formGroup.get('tags').disable();
      this.formGroup.get('uuid').disable();
      this.formGroup.get('requestType').disable();
      this.items.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.formGroup.get('name').enable();
      this.formGroup.get('url').enable();
      this.formGroup.get('channel').enable();
      this.formGroup.get('company').enable();
      this.formGroup.get('webhook').enable();
      this.formGroup.get('tags').enable();
      this.formGroup.get('uuid').enable();
      this.formGroup.get('requestType').enable();
      this.items.controls.forEach(control => {
        control.enable();
      });
    }
    this.ableDisableFields();
  }

  get items(): FormArray {
    return this.formGroup.get('items') as FormArray;
  }

  getItemForm(index: number): FormGroup {
    return this.items.at(index) as FormGroup;
  }

  getWebhookRequests() {
    const excludeParams = new Set(['code', 'uuid'])

    this.webhookIntegrationsService
      .getEventsByUUID(this.webhookIntegrations.uuid)
      .subscribe((webhook: Array<Webhook>) => {
        webhook.map((webhook) => {
          const exist = this.webhooks.some((el) => el._id === webhook._id)
          if (!exist) {
            this.webhooks.push({
              _id: webhook._id,
              channel: webhook.channel,
              createdAt: webhook.createdAt,
              updatedAt: webhook.updatedAt,
              removed: webhook.removed,
              message: webhook.message,
              event: webhook.event,
              headers: webhook.headers,
              metadata: {
                ...webhook.metadata.query,
                ...Object.fromEntries(
                  Object.entries(webhook.metadata.params).filter(
                    (e) => !excludeParams.has(e[0])
                  )
                ),
                ...webhook.metadata.body,
              },
            })
          }
        })

        if (this.getParam('id')) {
          const webhookSelected = this.webhooks.filter(
            (wb) =>
              wb._id === (this.webhookIntegrations.webhook as unknown as string)
          )[0]
          this.webhookRequests = webhookSelected.metadata
          this.formGroup.get('tags').patchValue(webhookSelected._id)
        }
      })
  }

  webhookRequestSelected(index) {
    this.webhookRequests = this.webhooks[index].metadata;
    this.webhookSelectedId = this.webhooks[index]._id;
    this.formGroup.get('webhook').setValue(this.webhooks[index]._id);
  }

  createActionTypeList() {
    this.actionsType = [];
    if (this.selectedChannel?.type === ChannelTypeEnum.CLOUD_API) {
      this.actionsType.push(WebhookIntegrationItemType.ADD_TAG);
      this.actionsType.push(WebhookIntegrationItemType.REMOVE_TAG);
      this.actionsType.push(WebhookIntegrationItemType.START_ATTENDANCE);
    } else {
      this.actionsType.push(WebhookIntegrationItemType.ADD_TAG);
      this.actionsType.push(WebhookIntegrationItemType.REMOVE_TAG);
      this.actionsType.push(WebhookIntegrationItemType.SEND_MESSAGE);
      this.actionsType.push(WebhookIntegrationItemType.START_ATTENDANCE);
    }
  }

  getActionTypeLabel(type: string): string {
    return WebhookIntegrationItemTypeTranslate[type];
  }

  addComponent(type: string): void {
    let item = null;
    if (type === WebhookIntegrationItemType.WHATSAPP_PHONE) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.WHATSAPP_PHONE, Validators.required],
        label: ['Telefone Whatsapp'],
        message: ['Selecione o campo com o número de WhatsApp da lista de requisições à esquerda. O código do país (DDI - country code) deve ser inserido junto com o telefone. Caso o mesmo estiver em um campo separado, adicione ambos os campos. Se o DDI não estiver no campo do número, nem em um campo separado, por favor insira-o manualmente.'],
        value: ['', Validators.required],
        required: [true],
      });

    } else if (type === WebhookIntegrationItemType.CONTACT_NAME) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.CONTACT_NAME, Validators.required],
        label: ['Criar/Atualizar Nome do Contato'],
        message: ['Selecione o campo com o nome completo do contato da lista de requisições à esquerda. O contato será cadastrado em sua audiência caso o mesmo não esteja cadastrado. No caso do nome estar separado em dois campos, primeiro nome e sobrenome, adicione ambos os campos.'],
        value: ['', Validators.required],
        required: [true],
      });
    } else if (type === WebhookIntegrationItemType.ADD_TAG) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.ADD_TAG, Validators.required],
        label: ['Adicionar etiqueta'],
        message: ['Selecione a etiqueta que será adicionada ao contato após o mesmo ser cadastrado em sua audiência.'],
        value: [null, Validators.required],
        required: [false],
      });
    } else if (type === WebhookIntegrationItemType.REMOVE_TAG) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.REMOVE_TAG, Validators.required],
        label: ['Remover etiqueta'],
        message: ['Selecione a etiqueta que será removida do contato. Caso o contato não possua a etiqueta, essa ação será ignorada e pulada.'],
        value: [null, Validators.required],
        required: [false],
      });
    } else if (type === WebhookIntegrationItemType.START_ATTENDANCE) {
      if (!this.items.controls.some((item) => item.get('type').value === WebhookIntegrationItemType.START_ATTENDANCE)) {
        item = this.formBuilder.group({
          type: [WebhookIntegrationItemType.START_ATTENDANCE, Validators.required],
          label: ['Iniciar atendimento'],
          message: ['Selecione um departamento para dar início a um atendimento, você também pode selecionar um atendente específico, mas essa parte é opcional'],
          value: [null, Validators.required],
          attendent: [null],
          required: [false],
        });
      }
    } else if (type === WebhookIntegrationItemType.SEND_MESSAGE) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.SEND_MESSAGE, Validators.required],
        label: ['Enviar mensagem'],
        message: ['Você pode enviar diferentes tipos de mensagens com esta ação. Selecione o tipo de mensagem que você deseja enviar e adicione o conteúdo da mesma abaixo. Para Imagem, Áudio, Vídeo ou Documento por favor insira o link do arquivo do mesmo terminado com o tipo da extensão do arquivo. (Ex: "https://yourfilelink.com/file/image.jpg")'],
        typeMessage: [null, Validators.required],
        value: [null, Validators.required],
        caption: [null],
        required: [false],
      });
    } else if (type === WebhookIntegrationItemType.ADD_TEMPLATE) {
      item = this.formBuilder.group({
        type: [WebhookIntegrationItemType.ADD_TEMPLATE, Validators.required],
        template: [null, Validators.required],
        templateParamsHeader: [null],
        templateParamsBody: [null],
        previewText: [null],
        required: [true],
      });
    }
    if (item) {
      this.items.push(item);
    }
  }

  public generateWebhookIntegrationsURLbyCode(uuid: string): string {
    const code = super.getCodeCompanyCurrentUser()
    return `${environment.trendIntegrationsUrl}/webhook/${code}/${uuid}`
  }

  deleteComponent(index: number): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
      data: 'Tem certeza que deseja remover essa ação?',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.items.removeAt(index);
        this.alertService.success('Ação removida com sucesso.');
      }
    });
  }

  ableDisableFields() {
    if (this.isActive) {
      this.formGroup.get('name').disable();
      this.formGroup.get('url').disable();
      this.formGroup.get('channel').disable();
      this.formGroup.get('company').disable();
      this.formGroup.get('webhook').disable();
      this.formGroup.get('tags')?.disable();
      this.formGroup.get('uuid').disable();
      this.formGroup.get('requestType').disable();
      this.formGroup.get('caption')?.disable();
      this.items.controls.forEach(control => {
        control.disable();
      });
    } else {
      this.formGroup.get('name').enable();
      this.formGroup.get('url').enable();
      this.formGroup.get('channel').enable();
      this.formGroup.get('company').enable();
      this.formGroup.get('webhook').enable();
      this.formGroup.get('tags')?.enable();
      this.formGroup.get('uuid').enable();
      this.formGroup.get('requestType').enable();
      this.formGroup.get('caption')?.enable();
      this.items.controls.forEach(control => {
        control.enable();
      });
    }
  }
}