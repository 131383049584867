<div class="container-template shadow-global p-4 rounded-lg">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Escolha um Template</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">
      Selecione um dos templates disponíveis na lista abaixo.
    </h6>
    <hr />
  </div>

  <div class="mb-4 flex gap-2">
    <input
      type="text"
      class="input-field w-full"
      [ngClass]="{
        'opacity-50 cursor-not-allowed bg-gray-100': selectedTemplateId
      }"
      placeholder="Buscar template..."
      [formControl]="searchControl"
      (input)="filterTemplates($event)"
      [readonly]="selectedTemplateId"
    />
    <button
      type="button"
      [matMenuTriggerFor]="categoryMenu"
      class="flex justify-center items-center bg-white gap-2 p-2.5 text-sm font-medium text-gray-900 global-filter"
      [ngClass]="{
        'opacity-50 cursor-not-allowed bg-gray-100': selectedTemplateId
      }"
      [disabled]="selectedTemplateId"
    >
      <svg
        width="16"
        height="10"
        viewBox="0 0 16 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.33333 10H9.66667V8.33333H6.33333V10ZM0.5 0V1.66667H15.5V0H0.5ZM3 5.83333H13V4.16667H3V5.83333Z"
          [attr.fill]="selectedTemplateId ? '#9CA3AF' : '#667085'"
        />
      </svg>
      <span> Categoria </span>
    </button>
  </div>

  <mat-menu #categoryMenu="matMenu">
    <button mat-menu-item (click)="filterByCategory('')">
      Todas as categorias
    </button>
    <button
      mat-menu-item
      *ngFor="let category of categories"
      (click)="filterByCategory(category.value)"
    >
      {{ category.label }}
    </button>
  </mat-menu>

  <div *ngIf="waTemplates.length > 0; else noTemplates">
    <div class="table-container">
      <table class="rounded-lg">
        <thead class="text-align-left">
          <tr>
            <th class="header-name">Nome do modelo</th>
            <th class="header-preview">Prévia</th>
            <th class="header-category">Categoria</th>
            <th class="header-language">Idioma</th>
            <th class="header-action"></th>
          </tr>
        </thead>
        <tbody class="overflow-y-auto">
          <tr *ngFor="let template of waTemplates; let i = index">
            <td class="template-name">
              <div>{{ template.name }}</div>
            </td>

            <td class="template-preview">
              {{ template.bodyComponent?.text | slice : 0 : 50 }}...
            </td>
            <td class="template-category">
              <span *ngIf="template.category === 'MARKETING'">Marketing</span>
              <span *ngIf="template.category === 'UTILITY'">Utilitário</span>
              <span *ngIf="template.category === 'AUTHENTICATION'"
                >Autenticação</span
              >
              <span *ngIf="template.category === 'SERVICE'">Serviço</span>
              <span
                *ngIf="
                  template.category !== 'MARKETING' &&
                  template.category !== 'UTILITY' &&
                  template.category !== 'AUTHENTICATION' &&
                  template.category !== 'SERVICE'
                "
              >
                {{ template.category }}
              </span>
            </td>
            <td class="template-language">
              <ng-container [ngSwitch]="template.language">
                <ng-container *ngSwitchCase="'pt_BR'">
                  <div class="language">
                    <img
                      width="15"
                      src="../../../../assets/icons/br.svg"
                      alt=""
                    />
                    <h6>Português</h6>
                  </div>
                </ng-container>
                <ng-container *ngSwitchCase="'en_US'">
                  <div class="language">
                    <img
                      width="15"
                      src="../../../../assets/icons/us.svg"
                      alt=""
                    />
                    <h6>Inglês</h6>
                  </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  {{ template.language }}
                </ng-container>
              </ng-container>
            </td>
            <td>
              <button
                class="h-[34px] w-[100px] flex justify-center items-center rounded-lg text-white font-normal font-[inherit] px-4 focus:outline-none"
                [ngClass]="{
                  'bg-[#4213F6] hover:bg-[#1340f6]':
                    selectedTemplateId !== template.id,
                  'bg-gray-400 cursor-not-allowed':
                    selectedTemplateId === template.id
                }"
                (click)="showTemplateDetails(template)"
              >
                {{
                  selectedTemplateId === template.id
                    ? "Selecionado"
                    : "Selecionar"
                }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <ng-template #noTemplates>
    <empty-records-component
      [message]="'Nenhum template encontrado'"
      [subMessage]="
        'Tente ajustar os filtros de busca para encontrar o template desejado'
      "
    >
    </empty-records-component>
  </ng-template>
</div>
