"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RestService = void 0;
const tslib_1 = require("tslib");
const axios_1 = require("axios");
const openai_providers_1 = require("../providers/openai.providers");
class RestService {
    constructor() { }
    headerConfig(baseRequest) {
        const { organization, project, apiKey } = baseRequest;
        return {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${apiKey}`,
                'OpenAI-Organization': organization,
                'OpenAI-Project': project,
                'OpenAI-Beta': 'assistants=v2'
            }
        };
    }
    getBaseURL(path) {
        const baseURL = openai_providers_1.openAIProviders.OPENAI_API_URL;
        return `${baseURL}/${path}`;
    }
    request(config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return axios_1.default.request(config)
                .then((response) => (response.data))
                .catch((error) => this.handleError(error));
        });
    }
    get(url, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return axios_1.default.get(url, config)
                .then((response) => (response.data))
                .catch((error) => this.handleError(error));
        });
    }
    post(url, json, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return axios_1.default.post(url, json, config)
                .then((response) => (response.data))
                .catch((error) => this.handleError(error));
        });
    }
    put(url, json, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return axios_1.default.put(url, json, config)
                .then((response) => (response.data))
                .catch((error) => this.handleError(error));
        });
    }
    delete(url, config) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return axios_1.default.delete(url, config)
                .then((response) => (response.data))
                .catch((error) => this.handleError(error));
        });
    }
    handleError(err) {
        console.error('[trend-openai-api] >> handleError >> ' + JSON.stringify(err));
        return Promise.resolve({ error: Object.assign({}, err) });
    }
}
exports.RestService = RestService;
