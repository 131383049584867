import { Component, Injector, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AbstractComponent, Integration, IntegrationService, IntegrationTypeEnum } from 'lib-trend-core';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-openai-integration-form',
  templateUrl: './openai-integration-form.component.html',
  styleUrls: ['./openai-integration-form.component.scss']
})
export class OpenAIFormComponent extends AbstractComponent implements OnInit {

  integration: Integration;
  hideAllPasswords: boolean = false

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
  ) {
    super(injector);
    this.integration = <Integration>{
      type: IntegrationTypeEnum.OPENAI
    };
    this.setupForm();
  }

  ngOnInit() {
    this.isNew = true;
    const id = super.getParam('id');
    if (!!id) {
      this.isNew = false;
      this.getIntegrationById(id);
    }
  }

  setupForm() {
    this.formGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      metadata: new FormGroup({
        token: new FormControl('', [Validators.required]),
        organization: new FormControl('', [Validators.required]),
        project: new FormControl('', [Validators.required]),
      }),
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.integration = Object.assign(this.integration, value);
    });
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService.getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.formGroup.patchValue(this.integration);
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  // checkExistingConfig() {
  //   this.loading = true;
  //   this.openAIService.getConfig().subscribe({
  //     next: (config) => {
  //       if (config) {
  //         this.hasExistingConfig = true;
  //         this.configForm.patchValue(config);
  //         this.isConfigured = true;
  //         this.loadAssistants();
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error checking config:', error);
  //     },
  //     complete: () => {
  //       this.loading = false;
  //     }
  //   });
  // }

  // loadAssistants() {
  //   this.openAIService.listAvailableAssistants().subscribe({
  //     next: (assistants) => {
  //       this.assistants = assistants;
  //     },
  //     error: (error) => {
  //       console.error('Error loading assistants:', error);
  //       this.showError('Erro ao carregar assistentes');
  //     }
  //   });
  // }

  // loadChannels() {
  //   this.loading = true;
  //   this.channelService.getList().subscribe({
  //     next: (channels) => {
  //       this.channels = channels;
  //     },
  //     error: (error) => {
  //       console.error('Error loading channels:', error);
  //       this.showError('Erro ao carregar canais');
  //     },
  //     complete: () => {
  //       this.loading = false;
  //     }
  //   });
  // }

  onSubmit() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      // this.alertService.error('Por favor, preencha todos os campos obrigatórios.');
      return;
    };

    console.log('form:', this.formGroup.valid)


    this.loading = true;
    if (this.isNew) {
      this.integrationService.create(this.integration).subscribe({
        next: (value) => this.alertService.success(),
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.goBack(),
      });
    } else {
      this.integrationService.update(this.integration._id, this.integration).subscribe({
        next: (value) => this.alertService.success(),
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.goBack()
      });
    }
  }

  // linkAssistant(assistantId: string, name: string) {
  //   if (!this.selectedChannel) {
  //     this.showError('Selecione um canal primeiro');
  //     return;
  //   }

  //   this.loading = true;
  //   this.integrationService.linkAssistantToChannel(this.selectedChannel, { assistantId, name }).subscribe({
  //     next: () => {
  //       this.showSuccess('Assistente vinculado com sucesso');
  //       this.loadAssistants();
  //     },
  //     error: (error) => {
  //       console.error('Error linking assistant:', error);
  //       this.showError('Erro ao vincular assistente');
  //     },
  //     complete: () => {
  //       this.loading = false;
  //     }
  //   });
  // }


  maskApiKey(apiKey: string): string {
    if (!apiKey) return '';
    return `${apiKey.substring(0, 3)}...${apiKey.substring(apiKey.length - 4)}`;
  }

  copyApiKey() {
    const apiKey = this.formGroup.get('apiKey')?.value;
    if (apiKey) {
      navigator.clipboard.writeText(apiKey);
      // this.showSuccess('API Key copiada para a área de transferência');
    }
  }

  togglePasswordVisibility(): void {
    this.hideAllPasswords = !this.hideAllPasswords
  }

  cancel() {
    this.router.navigate(['/integrations/openai/list',]);
  }

  goToAssistants() {
    this.router.navigate(['/integrations/openai/assistants', this.integration._id]);
  }

  // private showSuccess(message: string) {
  //   this.snackBar.open(message, 'Fechar', {
  //     duration: 3000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top',
  //     panelClass: ['success-snackbar']
  //   });
  // }

  // private showError(message: string) {
  //   this.snackBar.open(message, 'Fechar', {
  //     duration: 5000,
  //     horizontalPosition: 'right',
  //     verticalPosition: 'top',
  //     panelClass: ['error-snackbar']
  //   });
  // }
}
