<div class=" rounded-lg p-1 flex flex-col ml-[-5px]" (click)="openLocation()">
  <div class="flex flex-col bg-[#eee] shadow-global rounded-lg pr-4 pl-4 pb-2 pt-2">
    <div class="flex gap-2 justify-center items-center">
      <mat-icon style="color: #4213F6;">location_on</mat-icon>
    </div>
    <hr class="mt-[15px] bg-[#bdbdbd]" />
    <small class="text-[#737373]">{{message?.metadata?.place?.displayName?.text}}</small>
    <small style="color: #aaabac !important"
      class="flex items-end justify-end text-[10px] pt-1"><span>{{message?.metadata?.place?.formattedAddress}}</span></small>
  </div>
  <small style="color: #aaabac !important" class="flex items-end justify-end text-[10px] pt-1"><span
      class="timestamp">{{message.updatedAt | date : 'dd/MM/yyyy HH:mm'}}</span></small>
</div>