"use strict";
/**
 * Documentation
 * https://platform.openai.com/docs/api-reference/introduction
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.openAIProviders = void 0;
exports.openAIProviders = {
    OPENAI_API_URL: 'https://api.openai.com/v1',
    // OPENAI_API_KEY: 'sk-proj-4daudhbZJmHKbOX4y47YT3BlbkFJmqtesTzyYC0MKJfHyzmM',
    // OPENAI_ORG_ID: 'org-qVwuTcQFiU38MtwyBnz1Z9kQ',
    // OPENAI_PROJECT_ID: 'proj_nKLcoRQ1G3oX8keglJGhPXMq',
};
