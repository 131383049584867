"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssistantService = void 0;
const tslib_1 = require("tslib");
const base_service_1 = require("./base.service");
class AssistantService extends base_service_1.RestService {
    constructor() {
        super();
    }
    listAssistants(assistantListRequest) {
        const url = this.getBaseURL(`assistants`);
        return super.get(url, this.headerConfig(assistantListRequest));
    }
    getAssistantById(assistantRetrievesRequest) {
        const url = this.getBaseURL(`assistants/${assistantRetrievesRequest.assistant_id}`);
        return super.get(url, this.headerConfig(assistantRetrievesRequest));
    }
    createAssistant(assistantCreateRequest) {
        const url = this.getBaseURL(`assistants`);
        const { apiKey, organization, project } = assistantCreateRequest, data = tslib_1.__rest(assistantCreateRequest, ["apiKey", "organization", "project"]);
        return super.post(url, data, this.headerConfig(assistantCreateRequest));
    }
    updateAssistant(assistantUpdateRequest) {
        const { assistant_id, apiKey, organization, project } = assistantUpdateRequest, data = tslib_1.__rest(assistantUpdateRequest, ["assistant_id", "apiKey", "organization", "project"]);
        const url = this.getBaseURL(`assistants/${assistant_id}`);
        return super.post(url, data, this.headerConfig(assistantUpdateRequest));
    }
    deleteAssistant(assistantDeleteRequest) {
        const url = this.getBaseURL(`assistants/${assistantDeleteRequest.assistant_id}`);
        return super.delete(url, this.headerConfig(assistantDeleteRequest));
    }
}
exports.AssistantService = AssistantService;
