"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrendOpenAIAPIService = void 0;
const tslib_1 = require("tslib");
const assistant_service_1 = require("./services/assistant.service");
class TrendOpenAIAPIService {
    constructor() {
        this.assistantService = new assistant_service_1.AssistantService();
    }
    /**
     * List assistants
     */
    listAssistants(assistantListRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.assistantService.listAssistants(assistantListRequest);
        });
    }
    /**
     * Get assistant by id
     */
    getAssistantById(assistantRetrievesRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.assistantService.getAssistantById(assistantRetrievesRequest);
        });
    }
    /**
     * Create a new assistant
     */
    createAssistant(assistantCreateRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.assistantService.createAssistant(assistantCreateRequest);
        });
    }
    /**
     * Update an existing assistant
     */
    updateAssistant(assistantUpdateRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.assistantService.updateAssistant(assistantUpdateRequest);
        });
    }
    /**
     * Delete an assistant
     */
    deleteAssistant(assistantDeleteRequest) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            return this.assistantService.deleteAssistant(assistantDeleteRequest);
        });
    }
}
exports.TrendOpenAIAPIService = TrendOpenAIAPIService;
