<section *ngIf="channel" class="w-full flex flex-row flex-wrap mt-[24px]">

  <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
  <label for="tab-one" (click)="changeTab('tab-one')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Dashboard
  </label>

  <input id="tab-two" type="radio" name="tabs" class="peer/tab-two opacity-0 absolute" />
  <label for="tab-two" (click)="changeTab('tab-two')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-two:border-b-2 peer-checked/tab-two:border-[#4213F6] peer-checked/tab-two:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Configurações
  </label>

  <input id="tab-three" type="radio" name="tabs" class="peer/tab-three opacity-0 absolute" />
  <label for="tab-three" (click)="changeTab('tab-three')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-three:border-b-2 peer-checked/tab-three:border-[#4213F6] peer-checked/tab-three:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Auto Atendimento (Chatbot)
  </label>

  <input id="tab-four" type="radio" name="tabs" class="peer/tab-four opacity-0 absolute" />
  <label for="tab-four" (click)="changeTab('tab-four')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-four:border-b-2 peer-checked/tab-four:border-[#4213F6] peer-checked/tab-four:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Respostas Automáticas
  </label>

  <input *ngIf="env !== 'prod' && channel?.type === 'CLOUD_API'" id="tab-five" type="radio" name="tabs" class="peer/tab-five opacity-0 absolute" />
  <label *ngIf="env !== 'prod' && channel?.type === 'CLOUD_API'" for="tab-five" (click)="changeTab('tab-five')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-five:border-b-2 peer-checked/tab-five:border-[#4213F6] peer-checked/tab-five:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Templates
  </label>

  <input id="tab-six" type="radio" name="tabs" class="peer/tab-six opacity-0 absolute" />
  <label for="tab-six" (click)="changeTab('tab-six')"
    class="text-base font-normal hover:text-[#4213F6] peer-checked/tab-six:border-b-2 peer-checked/tab-six:border-[#4213F6] peer-checked/tab-six:text-[#4213F6] cursor-pointer px-4 rounded-t-lg block">
    Módulos
  </label>

  <!-- First tab -->
  <div class="hidden peer-checked/tab-one:flex w-full mt-[24px] flex-col" >
    <h1 class="font-black text-[20px] text-black">Dashboard do canal com suas métricas.</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Quantas mensagens, quantos contatos e grupos.</h6>
    <div class="flex flex-col w-full">
      <div class="w-full mt-[15px]" *ngIf="!loadingContent">
        <div class="flex items-center w-full gap-4">
          <card-graphic-small-component [label]="'Mensagens'" [total]="totalMessages"></card-graphic-small-component>
          <card-graphic-small-component [label]="'Atendimentos'"
            [total]="totalAttendances"></card-graphic-small-component>
          <card-graphic-small-component [label]="'Contatos'" [total]="totalContacts"></card-graphic-small-component>
        </div>
        <!-- <div class="flex">
          <div class="flex flex-col">
            <label class="text-zinc-400 font-light text-sm my-2">Deseja bloquear ou desbloquear algum contato? Selecione
              um
              abaixo.</label>
            <div class="flex gap-4">
              <button *ngIf="connected && channel?.type !== 'CLOUD_API'"
                class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
                (click)="blockOrUnblockContact()">Selecionar contato
              </button>
            </div>
          </div>
        </div> -->
      </div>
      <div class="w-full mt-[15px] flex gap-4 custom-max:w-[100%]" id="container-with-graph">
        <mat-card class="w-[60%] max-w-[100%] flex justify-center shadow-lg rounded-lg p-4" id="graph">
          <mat-card-content>
            <ngx-charts-bar-vertical-stacked [xAxis]="true" [yAxis]="true"
              [results]="dataChartOfChannel" [showYAxisLabel]="true"
              [customColors]="customColors" [yAxisLabel]="'Métricas'">
            </ngx-charts-bar-vertical-stacked>
          </mat-card-content>
        </mat-card>
        <!-- Graph with Card -->
        <div class="flex items-end justify-end loading-container bg-white" id="card-connection">
          <mat-card class="p-3">
            <loading-component class="loading" *ngIf="loadingSpinner$ | async"></loading-component>
            <div class="flex flex-col items-center">
              <h6 class="text-black">{{channel?.name}}</h6>
              <span class="text-[#4213F6] font-bold">{{connected && channel?.type !== 'CLOUD_API' ? 'Conectado' :
                channel?.type === 'CLOUD_API' ? 'Conectado' : 'Desconectado'}}</span>
            </div>

            @if (!connected && channel?.type !== 'CLOUD_API') {
            <qrcode *ngIf="INSTANCE_CODE_DATA" [qrdata]="INSTANCE_CODE_DATA"
              [imageSrc]="'../../../../assets/images/logo-icon-sidebar.png'" [imageWidth]="50" [imageHeight]="50"
              [width]="456">
            </qrcode>
            } @else {
            <div class="flex items-center mt-[15px] justify-center">
              <svg width="96" height="96" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
                <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
                <path
                  d="M28 18C22.48 18 18 22.48 18 28C18 33.52 22.48 38 28 38C33.52 38 38 33.52 38 28C38 22.48 33.52 18 28 18ZM28 36C23.59 36 20 32.41 20 28C20 23.59 23.59 20 28 20C32.41 20 36 23.59 36 28C36 32.41 32.41 36 28 36ZM32.59 23.58L26 30.17L23.41 27.59L22 29L26 33L34 25L32.59 23.58Z"
                  fill="#039855" />
              </svg>
            </div>
            }
            <div class="w-full mt-[1rem]" *ngIf="connected && channel?.type === 'EVOLUTION_API'">
              <div class="flex gap-4 justify-between">
                <div class="text-center">
                  <p class="text-gray-500">Contatos</p>
                  <p class="font-semibold text-lg">{{countContacts}}</p>
                </div>
                <div class="text-center">
                  <p class="text-gray-500">Chats</p>
                  <p class="font-semibold text-lg">{{countChats}}</p>
                </div>
                <div class="text-center">
                  <p class="text-gray-500">Mensagens</p>
                  <p class="font-semibold text-lg">{{countMessages}}</p>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <hr class="mt-[15px]" />
    <div class="flex w-full items-center justify-end gap-4">
      <button
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#DC2626] text-white font-normal"
        (click)="delete(channel)">Remover canal
      </button>

      @if (channel?.type !== 'CLOUD_API') {
      <button *ngIf="connected"
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="sync()">Sincronizar
      </button>

      <button *ngIf="connected && channel?.type === 'EVOLUTION_API'"
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="restart()">Reiniciar Canal
      </button>

      <button
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="syncContacts()" *ngIf="connected && channel?.type === 'EVOLUTION_API'">
        {{ !loading ? 'Sincronizar Contatos' : '' }}
        <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
      </button>

      <button *ngIf="connected"
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="disconnect()">Desconectar Canal
      </button>
      }
      <button
        class="mt-3 w-[200px] h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal  hover:bg-[#1340f6]"
        (click)="edit(channel)">
        Editar canal
      </button>
    </div>
  </div>

  <!-- Second tab -->
  <div class="hidden peer-checked/tab-two:block w-full">
    <div class="flex flex-1 flex-col mt-4">
      <settings-attendance-component *ngIf="selectedTab === 'tab-two'" [channel]="channel"></settings-attendance-component>
    </div>
  </div>

  <!-- Third tab -->
  <div class="hidden peer-checked/tab-three:block w-full">
    <div class="flex flex-1 flex-col mt-4">
      <chatbot-component *ngIf="selectedTab === 'tab-three'" [channel]="channel"></chatbot-component>
    </div>
  </div>

  <!-- Fourth tab -->
  <div class="hidden peer-checked/tab-four:block w-full">
    <div class="flex flex-1 flex-col mt-4">
      <response-automatic-component *ngIf="selectedTab === 'tab-four'"
        [channel]="channel"></response-automatic-component>
    </div>
  </div>

  <!-- fifth tab -->
  <div class="hidden peer-checked/tab-five:block w-full">
    <div class="flex flex-1 flex-col mt-4">
      <setting-template-list-component [channel]="channel" *ngIf="selectedTab === 'tab-five' && channel?.type === 'CLOUD_API'" />
    </div>
  </div>

  <!-- Sixth tab -->
  <div class="hidden peer-checked/tab-six:block w-full">
    <div class="flex flex-1 flex-col mt-4">
      <settings-module-component *ngIf="selectedTab === 'tab-six'" [channel]="channel"></settings-module-component>
    </div>
  </div>

</section>
