import { Component, Injector } from '@angular/core'
import { Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import {
  AbstractComponent,
  Company,
  ConfirmationComponent,
  Department,
  DepartmentService,
  Team,
  TeamService,
} from 'lib-trend-core'
import { DepartmentTeamEditComponent } from '../department-team-edit/department-team-edit.component'

@Component({
  selector: 'department-edit-component',
  templateUrl: './department-edit.component.html',
})
export class DepartmentEditComponent extends AbstractComponent {
  departament: Department
  teamList: Team[] = []
  indexExpanded: number = -1

  showTeam: boolean = false
  indexEdit: number

  constructor(
    injector: Injector,
    public dialog: MatDialog,
    public departmentService: DepartmentService,
    public teamService: TeamService
  ) {
    super(injector)
    this.createForm()
  }

  ngOnInit() {
    const departamentId = this.getParam('id')
    this.isNew = !departamentId

    if (departamentId) {
      this.departmentService.getById(departamentId).subscribe((departament) => {
        this.departament = departament
        if (this.departament) {
          this.formGroup.patchValue({ ...this.departament })
        }
      })
      this.teamService.getByDepartment(departamentId).subscribe((resp) => {
        this.teamList = resp
      })
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null],
      name: [null, Validators.required],
      description: [null, Validators.required],
    })
  }

  back(): void {
    history.back()
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return
    }

    const department = this.createObject()
    if (!department._id) {
      this.departmentService
        .createDepartmentTeams(department, this.teamList)
        .subscribe({
          next: (value) => {
            this.alertService.success('Departamento salvo com sucesso.')
            this.router.navigate(['/department'])
          },
          error: (err) => {
            this.alertService.error(err.error.message)
          },
          complete: () => { },
        })
    } else {
      this.departmentService
        .updateDepartmentTeams(department, this.teamList)
        .subscribe({
          next: (value) => {
            this.alertService.success('Departamento alterado com sucesso.')
            this.router.navigate(['/department'])
          },
          error: (err) => {
            this.alertService.error(err.error.message)
          },
          complete: () => { },
        })
    }
  }

  private createObject(): Department {
    const departament = {} as Department
    departament._id = this.formGroup.get('_id').value
    departament.name = this.formGroup.get('name').value
    departament.description = this.formGroup.get('description').value
    departament.company = {} as Company
    departament.company._id = this.getIDCurrentCompany()
    return departament
  }

  isCreate() {
    return !this.formGroup.get('id').value
  }

  activateInactivateTeam(team: Team, index: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
      data: `Deseja ${team.active ? 'inativar' : 'ativat'} o time?`,
    })
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.teamList[index].active = !team.active
      }
    })
  }

  deleteTeam(index: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
      data: 'Deseja realmente remover o time?'
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.teamList.splice(index, 1)
      }
    })
  }

  editTeam(index: number) {
    this.indexEdit = index
    const team = structuredClone(this.teamList[index])
    const dialogRef = this.dialog.open(DepartmentTeamEditComponent, {
      width: '800px',
      data: team,
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.teamList[this.indexEdit] = result
      }
    })
  }

  getWeekDays(item: Team) {
    let weekDays = ''
    if (item.monday) {
      weekDays ? (weekDays += ' - Seg') : (weekDays += 'Seg')
    }
    if (item.tuesday) {
      weekDays ? (weekDays += ' - Ter') : (weekDays += 'Ter')
    }
    if (item.wednesday) {
      weekDays ? (weekDays += ' - Qua') : (weekDays += 'Qua')
    }
    if (item.thursday) {
      weekDays ? (weekDays += ' - Qui') : (weekDays += 'Qui')
    }
    if (item.friday) {
      weekDays ? (weekDays += ' - Sex') : (weekDays += 'Sex')
    }
    if (item.saturday) {
      weekDays ? (weekDays += ' - Sáb') : (weekDays += 'Sáb')
    }
    if (item.sunday) {
      weekDays ? (weekDays += ' - Dom') : (weekDays += 'Dom')
    }
    return weekDays
  }

  addNewTeam() {
    this.indexEdit = null
    const dialogRef = this.dialog.open(DepartmentTeamEditComponent, {
      width: '600px',
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.teamList.push(result)
      }
    })
  }

  expand(index: number) {
    this.indexExpanded = this.indexExpanded == index ? -1 : index
  }
}
