import { Component, EventEmitter, Injector, Input, Output } from '@angular/core';
import { BodyComponentObject, TemplateMessageObject, TemplateMessagePager, TemplateRequest, TrendCloudAPIService } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Channel } from 'lib-trend-core';
@Component({
  selector: 'webhook-integration-wa-template',
  templateUrl: './webhook-integrations-wa-template.component.html',
  styleUrls: ['./webhook-integrations-wa-template.component.scss'],
})
export class WebhookWaTemplateComponent extends AbstractComponent {

  @Input() set channel(channel: Channel) {
    this.selectedChannel = channel;
    this.getList();
  }

  @Output() onSelectTemplate = new EventEmitter<TemplateMessageObject>();

  selectedTemplate: TemplateMessageObject = null
  templates: Array<TemplateMessageObject> = new Array<TemplateMessageObject>()
  selectedChannel: Channel

  private trendCloudAPIService: TrendCloudAPIService = new TrendCloudAPIService()

  constructor(injector: Injector) {
    super(injector);
  }

  async getList() {
    if (!this.selectedChannel) return

    const templateRequest: TemplateRequest = {
      token: this.selectedChannel.metadata.token,
      whatsAppBusinessAccountID: +this.selectedChannel.metadata.whatsAppBusinessAccountID,
      phoneNumberID: +this.selectedChannel.metadata.phoneNumberID,
      fields: [],
      limit: 10,
      status: '',
    };

    const response: TemplateMessagePager = await this.trendCloudAPIService.getTemplates(templateRequest);
    this.templates = response.data as any; // FIXME: remove any
  }

  showTemplateDetails(template: TemplateMessageObject) {
    this.onSelectTemplate.emit(template)
  }

  getBodyText(template: TemplateMessageObject): string {
    const bodyComponent = <BodyComponentObject>template?.components.find(c => c.type === 'BODY');
    return bodyComponent.text;
  }

}
