import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact.component';
import { ContactViewComponent } from './contact-view/contact-view.component';


const routes: Routes = [
  { path: '', component: ContactComponent, },
  { path: 'view/:id', component: ContactViewComponent, },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
