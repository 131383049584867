import { Component, Inject, Injector, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, Channel, Contact, ContactEditComponent, MessageHelper, MetadataMessage } from 'lib-trend-core';

@Component({
  selector: 'app-chat-select-template',
  templateUrl: './chat-select-template.component.html',
  styleUrl: './chat-select-template.component.scss'
})
export class ChatSelectTemplateComponent extends AbstractComponent implements OnInit, OnDestroy {

  step = 1;
  selectedContact: Contact = null;
  contacts = new Array<any>();

  selectedTemplate: TemplateMessageObject = undefined;
  selectedChannel: Channel;
  templateMessage: MetadataMessage;
  variables: Record<string, string> = {};

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactEditComponent>,
    @Inject(MAT_DIALOG_DATA) public attendance: Attendance
  ) {
    super(injector);
    this.selectedChannel = attendance.channel;
    this.selectedContact = attendance.contact;
    this.buildVariables();
  }

  ngOnInit(): void {
  }

  onSelectTemplate(template: TemplateMessageObject) {
    this.selectedTemplate = template;
    this.nextStep();
  }

  setTemplateMessage(templateMessage: MetadataMessage) {
    this.templateMessage = templateMessage;
  }

  previousStep() {
    this.step--;
  }

  nextStep() {
    this.step++;
  }

  close() {
    this.dialogRef.close();
  }

  private buildVariables(): void {
    this.variables = {
      'Saudação': MessageHelper.getGreeting(),
      'Nome do Contato': this.selectedContact.name,
    };
    if (!!this.attendance?.user) {
      this.variables['Nome do Atendente'] = this.attendance.user.name;
    }
    if (!!this.attendance?.department) {
      this.variables['Departamento'] = this.attendance.department.name;
    }
    if (!!this.attendance.protocol) {
      this.variables['Protocolo'] = this.attendance.protocol;
    }
  }

  selectTemplateMessage() {
    // const bodyComponent = <BodyComponentObject>this.selectedTemplate?.components.find(c => c.type === 'BODY')
    // const templateVariables = bodyComponent?.text.match(/{{\d+}}/g) || [];

    // if (templateVariables.length > 0 && (this.templateMessage.params.length !== templateVariables.length)) {
    //   this.alertService.error('Por favor, preencha todas as variáveis necessárias.');
    //   return;
    // }

    // console.log(this.templateMessage.midiaHeader);

    // const requiresImage = !!this.previewHeader;
    // if (requiresImage) {
    //   if (!this.templateMessage.midiaHeader) {
    //     this.alertService.error('Por favor, insira a imagem requerida para o template.');
    //     return;
    //   }
    // }

    this.dialogRef.close(<MetadataMessage>{
      // template: <TemplateMessageObject>{ ...this.selectedTemplate },
      // params: this.templateMessage.params,
      // link: this.templateMessage.midiaHeader,
      // previewText: this.templateMessage.previewText
      ...this.templateMessage
    });
  }



}
