import { ChangeDetectorRef, Component, Inject, Injector, ViewEncapsulation } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TemplateMessageObject } from '@trendbuild/trend-cloud-api';
import { AbstractComponent, Attendance, Channel, Company, Contact, MessageHelper, MessageScheduled, MessageScheduledService, MessageScheduledStatusEnum, MessageService, MessageTypeEnum, MetadataMessage, User } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';
import { AppState } from '../../../../state/app.state';

@Component({
  selector: 'template-schedule',
  templateUrl: './chat-template-schedule.component.html',
  styleUrls: ['./chat-template-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatTemplateScheduleComponent extends AbstractComponent {
  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  private message: MessageScheduled;
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();
  selectedContact: Contact = null;
  selectedChannel: Channel;
  metadataMessage: MetadataMessage;
  variables: Record<string, string> = {};
  selectedTemplate: TemplateMessageObject = undefined;

  previewHeader: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { attendance?: Attendance; message?: MessageScheduled },
    injector: Injector,
    public _matDialogRef: MatDialogRef<MessageScheduled>,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    dialog: MatDialog,
    store: Store<AppState>,
    messageService: MessageService,
    private messageScgeduledService: MessageScheduledService,
  ) {
    super(injector);
    this.message = data.message
    this.formGroup = this.fb.group({
      scheduledDay: ['', Validators.required],
      scheduledHour: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (!this.isNew) {
      const { scheduledDate, scheduledTime } = this.data.message;
      this.formGroup.patchValue({
        scheduledDay: scheduledDate,
        scheduledHour: scheduledTime,
      });

      if (this.data.message && this.data.message.metadata) {
        this.selectedTemplate = this.data.message.metadata.template;
        this.setTemplateMessage(this.data.message.metadata);
        this.buildVariables();
      }
    }
  }

  saveMessageTemplateScheduled(): void {

    console.log('metadata', this.metadataMessage)
    const scheduledDay = this.formGroup.get('scheduledDay')?.value;
    const scheduledHourRaw = this.formGroup.get('scheduledHour')?.value;

    if (!scheduledDay || !scheduledHourRaw) {
      this.alertService.warning('Data e hora agendadas são obrigatórias.');
      return;
    }

    const scheduledHour = scheduledHourRaw.replace(/^(\d{2})(\d{2})$/, '$1:$2');

    if (!/^\d{2}:\d{2}$/.test(scheduledHour)) {
      this.alertService.error('A hora deve estar no formato HH:mm.');
      return;
    }

    const [hour, minute] = scheduledHour.split(':').map(Number);
    const scheduledDateTime = new Date(scheduledDay);
    scheduledDateTime.setHours(hour, minute, 0, 0);

    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 2);

    if (scheduledDateTime < currentTime) {
      this.alertService.error('A data e hora agendadas devem ser pelo menos 2 minutos acima da hora atual.');
      return;
    }

    if (!this.selectedTemplate) {
      this.alertService.warning('Selecione um template antes de agendar a mensagem.');
      return;
    }

    if (!this.metadataMessage.headerParams || this.metadataMessage.headerParams.includes(null) || this.metadataMessage.headerParams.includes('')) {
      this.alertService.warning('Preencha todas as variáveis do cabeçalho antes de agendar seu template.');
      return;
    }

    if (!this.metadataMessage.bodyParams || this.metadataMessage.bodyParams.includes(null) || this.metadataMessage.bodyParams.includes('')) {
      this.alertService.warning('Preencha todas as variáveis do corpo antes de agendar seu template.');
      return;
    }

    this.loading = true;
    const messageTemplateScheduled = <MessageScheduled>{
      content: this.metadataMessage?.headerParams[0] ?? this.metadataMessage?.previewText,
      contentType: MessageTypeEnum.template,
      contact: { _id: this.data.attendance.contact._id } as Contact,
      channel: { _id: this.data.attendance.channel._id } as Channel,
      company: { _id: super.getIDCurrentCompany() } as Company,
      attendance: { _id: this.data.attendance._id } as Attendance,
      user: { _id: super.getIDCurrentUser() } as User,
      type: MessageTypeEnum.template,
      removed: false,
      scheduledDate: scheduledDay,
      scheduledTime: scheduledHour,
      status: MessageScheduledStatusEnum.WAITING,
      metadata: this?.metadataMessage,
    };

    this.messageScgeduledService.create(messageTemplateScheduled).subscribe({
      next: () => {
        this.alertService.success('Mensagem agendada criada com sucesso');
        this._matDialogRef.close();
        this.loading = false;
      },
      error: (error) => {
        console.error('Erro ao agendar mensagem:', error);
        this.alertService.error('Erro ao agendar a mensagem.');
        this.loading = false;
      },
    });
  }

  setTemplateMessage(metadataMessage: MetadataMessage) {
    console.log(metadataMessage)
    this.metadataMessage = metadataMessage;
  }

  onSelectTemplate(template: TemplateMessageObject) {
    this.selectedTemplate = template;
    this.buildVariables();
  }

  changeTemplate() {
    this.selectedTemplate = null;
  }

  private buildVariables(): void {
    this.variables = {
      'Saudação': MessageHelper.getGreeting(),
    };
    if (!!this.data.attendance?.user) {
      this.variables['Nome do Atendente'] = this.data.attendance.user.name;
    }
    if (!!this.data.attendance?.department) {
      this.variables['Departamento'] = this.data.attendance.department.name;
    }
    if (!!this.data.attendance.protocol) {
      this.variables['Protocolo'] = this.data.attendance.protocol;
    }
  }

}
