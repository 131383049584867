<div class="mb-4 px-5">
  <h1 class="font-black text-[20px] text-black">Nova campanha</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">
    Preencha os dados e crie uma nova campanha
  </h6>
  <hr />
</div>

<form class="px-5 w-full" [formGroup]="formGroup">
  <div class="flex w-full gap-4">
    <div class="flex flex-col w-full">
      <div class="flex w-full gap-2">
        <div class="flex flex-col w-[100%]">
          <label class="font-semibold text-black">Nome da campanha *</label>
          <input
            class="input-field"
            maxlength="100"
            formControlName="name"
            placeholder="ex: Campanha"
          />
          <field-error-component
            [field]="'name'"
            [label]="'Nome da campanha'"
            [formGroup]="formGroup"
          ></field-error-component>
        </div>

        <!-- SELECIONO CANAL -->
        <div class="flex flex-col w-[100%]">
          <label class="font-semibold text-black">Canal *</label>
          <mat-select
            class="input-field"
            formControlName="channel"
            (selectionChange)="selectChannel($event.value)"
            placeholder="Selecione"
            #channel
          >
            <div class="w-full h-[35px] p-2 mb-2">
              <input
                style="height: 35px !important"
                class="input-field"
                type="text"
                (focus)="onFocusChannel()"
                [formControl]="filterControlChannel"
                placeholder="Pesquise por um canal..."
              />
            </div>
            @for (item of channels; track item) {
            <mat-option [value]="item._id">{{ item.name }}</mat-option>
            }
          </mat-select>
          <field-error-component
            [field]="'channel'"
            [label]="'Canal'"
            [formGroup]="formGroup"
          ></field-error-component>
        </div>
      </div>
      <div class="flex w-full flex-col">
        <div class="flex flex-col">
          <div class="flex">
            @if (selectedChannel){
            <div class="flex mb-[15px] w-[100%]">
              <div class="flex flex-col w-full h-[570px]">
                <div class="flex gap-2">
                  <div class="w-full">
                    <label class="font-semibold text-black mt-[15px]"
                      >Público alvo *</label
                    >
                    <mat-select
                      class="input-field"
                      formControlName="audienceType"
                      #audience
                    >
                      <mat-option
                        *ngFor="let ta of targetAudience"
                        [value]="ta._id"
                      >
                        {{ ta.description }}
                      </mat-option>
                    </mat-select>
                    <field-error-component
                      [field]="'audienceType'"
                      [label]="'Publico alvo'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>

                  @if (audience.value === 'TAGS') {
                  <div class="w-full">
                    <label class="font-semibold text-black mt-[15px]"
                      >Tags *</label
                    >
                    <mat-select
                      class="input-field"
                      formControlName="tags"
                      multiple
                      placeholder="Selecione"
                    >
                      <div class="input-select-filter w-full p-2 mb-2">
                        <input
                          class="input-select-filter input-field"
                          type="text"
                          (focus)="onFocusTags()"
                          [formControl]="filterControlTag"
                          placeholder="Pesquise por tags..."
                        />
                      </div>
                      @for (item of allTags; track item) {
                      <mat-option [value]="item._id">{{
                        item.title
                      }}</mat-option>
                      }
                    </mat-select>
                    <field-error-component
                      [field]="'tags'"
                      [label]="'Tags'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>
                  } @if (audience.value === 'CONTACT_GROUP') {
                  <div class="w-full">
                    <label class="font-semibold text-black mt-[15px]"
                      >Grupos de Contatos *</label
                    >
                    <mat-select
                      class="input-field"
                      formControlName="contactGroups"
                      multiple
                      placeholder="Selecione"
                    >
                      <div class="w-full h-[30px] p-2 mb-2">
                        <input
                          class="input-select-filter input-field"
                          type="text"
                          (focus)="onFocusGroup()"
                          [formControl]="filterControlGroup"
                          placeholder="Pesquise por um grupo..."
                        />
                      </div>
                      @for (item of allGroups; track item) {
                      <mat-option [value]="item._id">{{
                        item.name
                      }}</mat-option>
                      }
                    </mat-select>
                    <field-error-component
                      [field]="'contactGroups'"
                      [label]="'Grupos de Contatos'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>
                  }
                </div>

                <div class="flex gap-2">
                  <div class="flex flex-col w-full">
                    <label class="font-semibold text-black mt-[15px]"
                      >Envio *</label
                    >

                    <mat-select
                      formControlName="executionType"
                      #execTime
                      class="input-field"
                      placeholder="Selecione"
                    >
                      <mat-option
                        *ngFor="let exec of executeTime"
                        [value]="exec._id"
                        >{{ exec.description }}</mat-option
                      >
                    </mat-select>
                    <field-error-component
                      [field]="'executionType'"
                      [label]="'Tipo da execução'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>

                  @if (execTime && execTime.value !== 'IMEDIATE'){
                  <div class="flex flex-col w-[70%]">
                    <label class="font-semibold text-black mt-[15px]"
                      >Data *</label
                    >
                    <div
                      id="input-phone"
                      class="input-field h-[46px] flex justify-between items-center w-full"
                    >
                      <input
                        [matDatepicker]="picker"
                        formControlName="scheduledDay"
                        placeholder="00/00/0000"
                        class="outline-none border-none focus:ring-0 focus:border-transparent bg-transparent w-full"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="picker"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #picker></mat-datepicker>
                    </div>
                    <field-error-component
                      [field]="'scheduledDay'"
                      [label]="'Data'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>

                  <div class="flex flex-col w-[60%]">
                    <label class="font-semibold text-black mt-[15px]"
                      >Hora *</label
                    >
                    <input
                      class="input-field"
                      formControlName="scheduledHour"
                      [mask]="'00:00'"
                      [dropSpecialCharacters]="false"
                      placeholder="ex: 08:00"
                    />
                    <field-error-component
                      [field]="'scheduledHour'"
                      [label]="'Hora'"
                      [formGroup]="formGroup"
                    ></field-error-component>
                  </div>
                  }
                </div>
                <div
                  class="textarea-wrapper"
                  *ngIf="selectedChannel.type !== 'CLOUD_API'"
                >
                  <form [formGroup]="formGroup" class="w-full">
                    <label class="font-semibold text-black mt-[15px]"
                      >Mensagem</label
                    >
                    <div class="textarea-container">
                      <textarea
                        #messageTextarea
                        formControlName="message"
                        class="input-field h-[80px]"
                        placeholder="Digite..."
                        maxlength="320"
                        (paste)="onPaste($event)"
                      ></textarea>

                      <div class="icon-container flex items-center">
                        <mat-icon
                          (click)="applyFormat('bold')"
                          class="cursor-pointer ml-2"
                          >format_bold</mat-icon
                        >
                        <mat-icon
                          (click)="applyFormat('italic')"
                          class="cursor-pointer ml-2"
                          >format_italic</mat-icon
                        >
                        <mat-icon
                          (click)="applyFormat('underline')"
                          class="cursor-pointer ml-2"
                          >format_underline</mat-icon
                        >
                        <mat-icon
                          (click)="toogleContainerEmojiEvo()"
                          class="cursor-pointer ml-2"
                          >sentiment_satisfied</mat-icon
                        >
                        <div
                          #emojiContainer
                          class="relative"
                          id="emoji-container"
                        >
                          <ng-container *ngIf="showContainerEmoji">
                            <emoji-mart
                              (emojiSelect)="addEmoji($event)"
                              [i18n]="translator"
                              [darkMode]="false"
                              class="absolute bottom-300 right-[40px] lg:bottom-100"
                            >
                            </emoji-mart>
                          </ng-container>
                        </div>
                        <mat-icon
                          [matMenuTriggerFor]="menuVar"
                          class="cursor-pointer"
                          (click)="showSelectTemplate()"
                          >code</mat-icon
                        >
                      </div>
                    </div>
                    <ng-container>
                      <mat-menu #menuVar="matMenu">
                        <button
                          mat-menu-item
                          (click)="selectVarContactLeadEvo()"
                        >
                          <mat-icon color="primary">person</mat-icon> Nome do
                          contato
                        </button>
                        <button mat-menu-item (click)="selectVarGreetingEvo()">
                          <mat-icon color="primary">pan_tool</mat-icon> Saudação
                        </button>
                        <button
                          mat-menu-item
                          (click)="selectVarLinkChannelEvo()"
                        >
                          <mat-icon color="primary">insert_link</mat-icon> Link
                          canal Whatsapp
                        </button>
                        <button
                          mat-menu-item
                          (click)="selectVarOperatorNameEvo()"
                        >
                          <mat-icon color="primary"
                            >perm_contact_calendar</mat-icon
                          >
                          Nome atendente
                        </button>
                      </mat-menu>
                    </ng-container>
                  </form>
                </div>

                <div
                  *ngIf="selectedChannel.type === 'CLOUD_API'"
                  class="rounded-lg mt-[15px] shadow-campaign-template"
                >
                  @if (selectedTemplate) {
                  <div class="w-full flex justify-end">
                    <button
                      class="rounded-lg mb-3 hover:bg-gray-100"
                      style="padding: 0.5rem; border: 1px solid #bdbdbd"
                      (click)="selectedTemplate = null"
                    >
                      Trocar template
                    </button>
                  </div>
                  }

                  <wa-template-select
                    [channel]="selectedChannel"
                    [selectedTemplateId]="selectedTemplate?.id"
                    (onSelectTemplate)="onSelectTemplate($event)"
                  ></wa-template-select>

                  <!-- VARIAVEL -->

                  <div
                    *ngIf="
                      templateVariableList?.length > 0 &&
                      selectedTemplate != null
                    "
                    class="bg-white rounded-lg shadow-global flex flex-col p-4 mb-4"
                  >
                    <div class="mb-4">
                      <h1 class="font-black text-[20px] text-black">
                        Editar Variáveis
                      </h1>
                      <h6 class="text-zinc-400 font-light text-sm my-2">
                        Preencha os dados e cire sua campanha
                      </h6>
                      <hr />
                    </div>
                    <form
                      *ngIf="selectedTemplate"
                      [formGroup]="form"
                      class="w-full"
                    >
                      <div class="flex flex-wrap gap-2">
                        <ng-container
                          *ngFor="
                            let variable of templateVariableList;
                            let i = index
                          "
                        >
                          <div class="w-[calc(50%-1rem)] min-w-[250px]">
                            <label class="font-semibold text-black mt-[15px]">
                              Variável {{ i + 1 }} *
                            </label>
                            <div
                              class="flex justify-center items-center w-full"
                            >
                              <input
                                type="text"
                                matInput
                                class="input-field flex-1"
                                [formControlName]="'var' + (i + 1)"
                                [matAutocomplete]="auto"
                                (input)="onInputChange(i + 1)"
                                placeholder="Preencha a variável da mensagem"
                              />
                              <mat-autocomplete
                                #auto="matAutocomplete"
                                (optionSelected)="
                                  onOptionSelected($event.option.value, i + 1)
                                "
                              >
                                <mat-option
                                  *ngFor="let option of filteredOptions"
                                  [value]="option.text"
                                >
                                  <mat-icon color="primary">{{
                                    option.icon
                                  }}</mat-icon>
                                  {{ option.text }}
                                </mat-option>
                              </mat-autocomplete>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </form>
                  </div>
                  <div
                    class="flex flex-1 justify-end items-center w-full gap-4 mt-[70px] mb-4 relative right-[-400px]"
                  >
                    <button
                      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100"
                      (click)="goBack()"
                    >
                      Cancelar
                    </button>
                    <button
                      class="h-[45px] mt-3 px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
                      (click)="confirmSaveCampaign()"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>

            }
          </div>
        </div>
      </div>
    </div>

    <!-- PREVIEW-->
    <div class="flex">
      <div
        class="w-[23rem] h-[44.5rem] bg-gradient-to-b from-blue-100 to-blue-200 border border-blue-300 rounded-[3rem] flex items-center justify-center border-box p-[0.2rem] relative shadow-[1rem_1rem_6rem_rgba(0,0,0,0.4),0_0_0.1rem_rgba(0,0,0,0.4)]"
      >
        <div
          class="w-1 h-20 rounded-[0_0.1rem_0.1rem_0] bg-gradient-to-r from-neutral-400 via-blue-300 to-blue-300 border-r border-blue-100 absolute -right-1 top-[30%] shadow-[2px_0_1px_rgba(0,0,0,0.2)_inset]"
        ></div>
        <div
          class="w-1 h-10 rounded-[0_0.1rem_0.1rem_0] bg-gradient-to-l from-neutral-400 via-blue-300 to-blue-300 border-l border-blue-100 absolute -left-1 top-[38%] shadow-[2px_0_1px_rgba(0,0,0,0.2)_inset]"
        ></div>
        <div
          class="w-1 h-10 rounded-[0_0.1rem_0.1rem_0] bg-gradient-to-l from-neutral-400 via-blue-300 to-blue-300 border-l border-blue-100 absolute -left-1 top-[28%] shadow-[2px_0_1px_rgba(0,0,0,0.2)_inset]"
        ></div>
        <div
          class="w-1 h-5 rounded-[0_0.1rem_0.1rem_0] bg-gradient-to-l from-neutral-400 via-blue-300 to-blue-300 border-l border-blue-100 absolute -left-1 top-[20%] shadow-[2px_0_1px_rgba(0,0,0,0.2)_inset]"
        ></div>

        <div
          class="h-full w-full bg-black rounded-[2.9rem] flex items-center justify-center border-box p-[0.5rem]"
        >
          <div class="h-full w-full img-bg overflow-hidden">
            <div
              class="w-full h-full flex flex-col items-center overflow-auto overflow-x-hidden flex-grow"
            >
              <div
                class="flex flex-col w-[100%]"
                *ngIf="this.selectedChannel?.type === 'CLOUD_API'"
              >
                <campaign-wa-template-message
                  [channel]="selectedChannel"
                  [template]="selectedTemplate"
                  (templateParams)="setTemplateMessage($event)"
                  [initTemplateData]="selectedTemplateDataInit"
                />
              </div>

              <campaign-evo-template
                *ngIf="
                  selectedChannel?.type === 'EVOLUTION_API' ||
                  selectedChannel?.type === 'EVOLUTION_GO_API'
                "
                [inputText]="formGroup.get('message').value"
                (paramsTemplate)="onTemplateParamsReceived($event)"
              ></campaign-evo-template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
