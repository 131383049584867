<div class=" rounded-lg border border-[#bdbdbd] flex flex-col h-[87vh] bg-white shadow-global ">
  <div class="flex flex-col  flex-wrap p-3 w-[400px] bg-white rounded-lg">
    <div class="flex flex-1 w-100 justify-between">
      <div class="relative w-full">
        <div class="absolute inset-y-0 left-2 flex items-center pointer-events-none">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.52625 14.4874C7.34723 14.4874 6.19468 14.1378 5.21436 13.4828C4.23404 12.8278 3.46997 11.8967 3.01878 10.8075C2.56759 9.71819 2.44953 8.51958 2.67955 7.36321C2.90957 6.20684 3.47732 5.14465 4.31101 4.31095C5.14471 3.47726 6.2069 2.9095 7.36327 2.67949C8.51964 2.44947 9.71825 2.56752 10.8075 3.01872C11.8968 3.46991 12.8278 4.23398 13.4829 5.2143C14.1379 6.19462 14.4875 7.34717 14.4875 8.52619C14.4875 9.30904 14.3333 10.0842 14.0337 10.8075C13.7341 11.5307 13.295 12.1879 12.7415 12.7414C12.1879 13.295 11.5308 13.7341 10.8075 14.0337C10.0843 14.3333 9.3091 14.4874 8.52625 14.4874ZM8.52625 3.76036C7.58679 3.76036 6.66843 4.03894 5.8873 4.56088C5.10616 5.08282 4.49734 5.82467 4.13783 6.69261C3.77831 7.56056 3.68425 8.51563 3.86752 9.43704C4.0508 10.3584 4.5032 11.2048 5.1675 11.8691C5.8318 12.5334 6.67817 12.9858 7.59958 13.1691C8.52099 13.3524 9.47605 13.2583 10.344 12.8988C11.2119 12.5393 11.9538 11.9305 12.4757 11.1493C12.9977 10.3682 13.2763 9.44982 13.2763 8.51036C13.2763 7.25058 12.7758 6.0424 11.885 5.1516C10.9942 4.26081 9.78603 3.76036 8.52625 3.76036Z"
              fill="#757575" />
            <path
              d="M15.8333 16.427C15.7553 16.4274 15.678 16.4122 15.606 16.3823C15.5339 16.3523 15.4686 16.3084 15.4137 16.2528L12.1442 12.9833C12.0393 12.8707 11.9822 12.7218 11.9849 12.568C11.9876 12.4142 12.0499 12.2674 12.1587 12.1586C12.2675 12.0499 12.4143 11.9875 12.5681 11.9848C12.7219 11.9821 12.8708 12.0392 12.9833 12.1441L16.2529 15.4137C16.3641 15.525 16.4266 15.6759 16.4266 15.8333C16.4266 15.9906 16.3641 16.1415 16.2529 16.2528C16.1981 16.3084 16.1327 16.3523 16.0607 16.3823C15.9886 16.4122 15.9113 16.4274 15.8333 16.427Z"
              fill="#757575" />
          </svg>
        </div>
        <input type="text" #searchBox (keyup)="search(searchBox.value)" class="input-field pl-10"
        placeholder="Pesquisar..." style="padding-left: 30px; box-sizing: border-box;" />
      </div>
      <div class="flex">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon class="text-[#666E87]">filter_list</mat-icon>
        </button>
        <mat-menu #menu="matMenu" class="menu-content">
          <button mat-menu-item [matMenuTriggerFor]="channelMenu">Canal</button>
          <button mat-menu-item [matMenuTriggerFor]="departmentMenu">Departamento</button>
          <button mat-menu-item [matMenuTriggerFor]="tagMenu">Tag</button>
          <button mat-menu-item [matMenuTriggerFor]="userMenu">Atendente</button>
          <button mat-menu-item (click)="clearFilters()">Limpar Filtros</button> 
        </mat-menu>
        
        <mat-menu class="max-h-[500px]" #channelMenu="matMenu">
          <button mat-menu-item *ngFor="let channel of listChannel">
            <mat-checkbox color="primary" [checked]="selectedChannelIds.includes(channel._id)" (change)="searchByChannel(channel)">
              {{ channel.name }}
            </mat-checkbox>
          </button>
        </mat-menu>
        
        <mat-menu class="max-h-[500px]" #departmentMenu="matMenu">
          <button mat-menu-item *ngFor="let department of listDepartment">
            <mat-checkbox color="primary" [checked]="selectedDepartmentIds.includes(department._id)" (change)="searchByDepartment(department)">
              {{ department.name }}
            </mat-checkbox>
          </button>
        </mat-menu>
        
        <mat-menu class="max-h-[500px]" #tagMenu="matMenu">
          <button mat-menu-item *ngFor="let tag of listTag">
            <mat-checkbox color="primary" [checked]="selectedTagIds.includes(tag._id)" (change)="searchByTag(tag)">
              {{ tag.title }}
            </mat-checkbox>
          </button>
        </mat-menu>
        
        <mat-menu class="max-h-[500px]" #userMenu="matMenu">
          <button mat-menu-item *ngFor="let user of listUser">
            <mat-checkbox color="primary" [checked]="selectedUserIds.includes(user._id)" (change)="searchByUser(user)">
              {{ user.name }}
            </mat-checkbox>
          </button>
        </mat-menu>
        
        <button matTooltip="Adicionar Contato" class="ml-1 mr-1 mt-1" mat-icon-button color="primary" aria-label="Adicionar Contato"
          (click)="addContact()" *ngIf="showAddContact()">
          <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.8333 9.75065V7.58398H21.6667V9.75065H19.5V11.9173H21.6667V14.084H23.8333V11.9173H26V9.75065H23.8333Z"
              fill="#667085" />
            <path
              d="M8.66667 13.0007C11.0608 13.0007 13 11.0615 13 8.66732C13 6.27315 11.0608 4.33398 8.66667 4.33398C6.2725 4.33398 4.33333 6.27315 4.33333 8.66732C4.33333 11.0615 6.2725 13.0007 8.66667 13.0007ZM8.66667 6.50065C9.85833 6.50065 10.8333 7.47565 10.8333 8.66732C10.8333 9.85898 9.85833 10.834 8.66667 10.834C7.475 10.834 6.5 9.85898 6.5 8.66732C6.5 7.47565 7.475 6.50065 8.66667 6.50065Z"
              fill="#667085" />
            <path
              d="M8.66667 14.084C5.77417 14.084 0 15.5357 0 18.4173V21.6673H17.3333V18.4173C17.3333 15.5357 11.5592 14.084 8.66667 14.084ZM15.1667 19.5007H2.16667V18.4282C2.38333 17.6482 5.74167 16.2507 8.66667 16.2507C11.5917 16.2507 14.95 17.6482 15.1667 18.4173V19.5007Z"
              fill="#667085" />
            <path
              d="M13.5525 4.38815C14.5492 5.53648 15.1667 7.03148 15.1667 8.66732C15.1667 10.3032 14.5492 11.7982 13.5525 12.9465C15.6758 12.6757 17.3333 10.8773 17.3333 8.66732C17.3333 6.45732 15.6758 4.65898 13.5525 4.38815Z"
              fill="#667085" />
            <path
              d="M17.9075 14.9832C18.8717 15.8823 19.5 17.009 19.5 18.4173V21.6673H21.6667V18.4173C21.6667 16.8465 19.9442 15.6981 17.9075 14.9832Z"
              fill="#667085" />
          </svg>
        </button>
        <button matTooltip="Nova Conversa" class="ml-1 mr-1 mt-1" mat-icon-button color="primary" aria-label="Nova Conversa"
          (click)="startNewChat()">
          <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.2675 2.33268L15.5 7.56518V19.666H2.50004V2.33268H10.2675ZM11.1667 0.166016H2.50004C1.30837 0.166016 0.333374 1.14102 0.333374 2.33268V19.666C0.333374 20.8577 1.30837 21.8327 2.50004 21.8327H15.5C16.6917 21.8327 17.6667 20.8577 17.6667 19.666V6.66602L11.1667 0.166016ZM9.00004 13.166C10.1917 13.166 11.1667 12.191 11.1667 10.9993C11.1667 9.80768 10.1917 8.83268 9.00004 8.83268C7.80837 8.83268 6.83337 9.80768 6.83337 10.9993C6.83337 12.191 7.80837 13.166 9.00004 13.166ZM13.3334 16.8818C13.3334 16.0043 12.8134 15.2243 12.0117 14.8777C11.0909 14.4768 10.0725 14.2493 9.00004 14.2493C7.92754 14.2493 6.90921 14.4768 5.98837 14.8777C5.18671 15.2243 4.66671 16.0043 4.66671 16.8818V17.4993H13.3334V16.8818Z"
              fill="#667085" />
          </svg>
        </button>
      </div>
    </div>
    <mat-tab-group [(selectedIndex)]="selectedIndex" (selectedTabChange)="changeTab($event)" animationDuration="200ms">

      <!-- Aba Overtime -->
      <mat-tab [disabled]="loading" (click)="changeTab($event)">
        <ng-template mat-tab-label>
          <span matTooltip="Fora de horário" class="flex items-center">
            <mat-icon class="text-black text-[1.325rem] mt-1">error_outline</mat-icon>
            <p class="text-[#EF4444] w-7 text-sm font-bold">{{ overtimeCount }}</p>
          </span>
  
        </ng-template>
        <div (click)="clearFilters()" *ngIf="blockSocketWhenFilterIsActive" class="border border-[#BDBDBD] text-center p-2 rounded-lg mt-2 cursor-pointer hover:bg-gray-100 hover:shadow-md">
          <p class="text-sm text-red-600">Filtro ativado, clique aqui para limpar!</p>
        </div>
        <div class="h-[68vh] overflow-y-auto" (scroll)="onScroll($event)" #scrollContainer>
          <ng-container *ngIf="pager.total === 0 && loading;  else contactList">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
          <ng-template #contactList>
            <ng-container *ngFor="let item of pager.list;">
              <a href="javascript:void(0)" (click)="!loading && selectAttendance(item)">
                <attendance-contact-card-component [status]="'overtime'" [item]="item"
                  [selected]="item._id === idAttendanceParam || item._id === attendance?._id">
                </attendance-contact-card-component>
              </a>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="pager.total > 0 && loading">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
        </div>
      </mat-tab>

      <!-- Aba Pending -->
      <mat-tab [disabled]="loading" (click)="changeTab($event)">
        <ng-template mat-tab-label>
          <span matTooltip="Aguardando" class="flex items-center">
            <mat-icon class="text-black text-[1.325rem] mt-1">access_time</mat-icon>
            <p class="text-[#EE6820] w-7 text-sm font-bold">{{ pendingCount }}</p>
          </span>
        </ng-template>
        <div (click)="clearFilters()" *ngIf="blockSocketWhenFilterIsActive" class="border border-[#BDBDBD] text-center p-2 rounded-lg mt-2 cursor-pointer hover:bg-gray-100 hover:shadow-md">
          <p class="text-sm text-red-600">Filtro ativado, clique aqui para limpar!</p>
        </div>
        <div class="h-[68vh] overflow-y-auto" (scroll)="onScroll($event)" #scrollContainer>
          <ng-container *ngIf="pager.total === 0 && loading;  else pendingList">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
          <ng-template #pendingList>
            <ng-container *ngFor="let item of pager.list;">
              <a href="javascript:void(0)" (click)="!loading && selectAttendance(item)">
                <attendance-contact-card-component [status]="'wating'" [item]="item"
                  [selected]="item._id === idAttendanceParam || item._id === attendance?._id">
                </attendance-contact-card-component>
              </a>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="pager.total > 0 && loading">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
        </div>
      </mat-tab>

      <!-- Aba In Progress -->
      <mat-tab [disabled]="loading" (click)="changeTab($event)">
        <ng-template mat-tab-label>
          <span matTooltip="Em andamento" class="flex items-center">
            <mat-icon class="text-black text-[1.325rem] mt-2">chat_bubble_outline</mat-icon>
            <p class="text-[#039855] w-7 text-sm font-bold">{{ inProgressCount }}</p>
          </span>
        </ng-template>
        <div (click)="clearFilters()" *ngIf="blockSocketWhenFilterIsActive" class="border border-[#BDBDBD] text-center p-2 rounded-lg mt-2 cursor-pointer hover:bg-gray-100 hover:shadow-md">
          <p class="text-sm text-red-600">Filtro ativado, clique aqui para limpar!</p>
        </div>
        <div class="h-[68vh] overflow-y-auto" (scroll)="onScroll($event)" #scrollContainer>
          <ng-container *ngIf="pager.total === 0 && loading; else inProgressList">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
          <ng-template #inProgressList>
            <ng-container *ngFor="let item of pager.list;">
              <a href="javascript:void(0)" (click)="!loading && selectAttendance(item)">
                <attendance-contact-card-component [status]="'progress'" [item]="item"
                  [selected]="item._id === idAttendanceParam || item._id === attendance?._id">
                </attendance-contact-card-component>
              </a>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="pager.total > 0 && loading">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
        </div>
      </mat-tab>

      <!-- Aba Paused -->
      <mat-tab [disabled]="loading" (click)="changeTab($event)">
        <ng-template mat-tab-label>
          <div>
            <span matTooltip="Pausado" class="flex items-center">
              <mat-icon class="text-black text-[1.325rem] mt-1">pause_circle_outline</mat-icon>
              <p class="text-[#667085] w-7 text-sm font-bold">{{ pausedCount }}</p>
            </span>
          </div>
        </ng-template>
        <div (click)="clearFilters()" *ngIf="blockSocketWhenFilterIsActive" class="border border-[#BDBDBD] text-center p-2 rounded-lg mt-2 cursor-pointer hover:bg-gray-100 hover:shadow-md">
          <p class="text-sm text-red-600">Filtro ativado, clique aqui para limpar!</p>
        </div>
        <div class="h-[68vh] overflow-y-auto" (scroll)="onScroll($event)" #scrollContainer>
          <ng-container *ngIf="pager.total === 0 && loading;  else pausedList">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
          <ng-template #pausedList>
            <ng-container *ngFor="let item of pager.list;">
              <a href="javascript:void(0)" (click)="!loading && selectAttendance(item)">
                <attendance-contact-card-component [status]="'paused'" [item]="item"
                  [selected]="item._id === attendance?._id">
                </attendance-contact-card-component>
              </a>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="pager.total > 0 && loading">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
        </div>
      </mat-tab>

      <!-- Aba Closed -->
      <mat-tab [disabled]="loading" (click)="changeTab($event)">
        <ng-template mat-tab-label>
          <div>
            <span matTooltip="Fechado" class="flex items-center">
              <mat-icon class="text-black text-[1.325rem] mt-1">do_not_disturb</mat-icon>
              <p class="text-[#667085] w-7 text-sm font-bold">{{ closedCount }}</p>
            </span>
          </div>
        </ng-template>
        <div (click)="clearFilters()" *ngIf="blockSocketWhenFilterIsActive" class="border border-[#BDBDBD] text-center p-2 rounded-lg mt-2 cursor-pointer hover:bg-gray-100 hover:shadow-md">
          <p class="text-sm text-red-600">Filtro ativado, clique aqui para limpar!</p>
        </div>
        <div class="h-[68vh] overflow-y-auto" (scroll)="onScroll($event)" #scrollContainer>
          <ng-container *ngIf="pager.total === 0 && loading; else closedList">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
          <ng-template #closedList>
            <ng-container *ngFor="let item of pager.list;">
              <a href="javascript:void(0)" (click)="!loading && selectAttendance(item)">
                <attendance-contact-card-component [status]="'closed'" [item]="item"
                  [selected]="item._id === attendance?._id"></attendance-contact-card-component>
              </a>
            </ng-container>
          </ng-template>
          <ng-container *ngIf="pager.total > 0 && loading">
            <content-loading-attedance-card></content-loading-attedance-card>
          </ng-container>
        </div>
      </mat-tab>


    </mat-tab-group>
    <div class="relative top-5 flex justify-center flex-col items-center cursor-pointer"
      *ngIf="pager.list.length < pager.total" (click)="loadMoreContacts()">
      <span class="text-xs text-[#bdbdbd] mb-[-2px]">Carregar mais...</span>
      <mat-icon class="text-[#bdbdbd] mt-[-2px]">expand_more</mat-icon>
    </div>


  </div>
  <div style="display: none">
    <a (click)="updateAttendancesFromSocket()" #btnUpdateAttendancesFromSocket>Update attendances from socket</a>
  </div>

</div>