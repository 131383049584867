<!-- Reply message -->
<div class="inline-block text-left relative" x-init="window.addEventListener('closeMenu', () => { menu = false; })"
  x-data="{ menu: false }" *ngIf="attendance?.status !== 'CLOSED'">
  <!-- Botão para abrir o menu -->
  <button x-on:click="menu = !menu; $event.stopPropagation();" type="button"
    class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none" id="menu-button" aria-expanded="true"
    aria-haspopup="true">
    <span class="sr-only">Open menu</span>
    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
      <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
    </svg>
  </button>

  <!-- Menu suspenso -->
  <div x-show="menu" x-on:click.away="menu = false"
    [ngClass]="{'right-0': isMessageAttendant, 'left-0': !isMessageAttendant}"
    class="absolute top-full w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none z-50"
    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
    <div *ngIf="!isMessageAttendant && attendance.channel.type !== 'CLOUD_API'" role="none">
      <a href="javascript:void(0)"
        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 hover:rounded-lg block px-4 py-2 text-sm"
        role="menuitem" tabindex="-1" id="menu-item-0" (click)="replyMessage(message); $event.stopPropagation();">
        Responder mensagem
      </a>
    </div>
    <div *ngIf="(message.reactions.length < 2) && (!isMessageAttendant)" role="none">
      <a href="javascript:void(0)"
        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 hover:rounded-lg block px-4 py-2 text-sm"
        role="menuitem" tabindex="-1" id="menu-item-0" (click)="reactMessage(); $event.stopPropagation();">
        Mandar reação
        @if (showContainerEmoji) {
        <emoji-mart [emojiTooltip]="true" (emojiSelect)="addEmoji($event)" [i18n]="translator" [darkMode]="false"
          class="absolute left-40 top-0 md:bottom-28 lg:bottom-28 z-50">
        </emoji-mart>
        }
      </a>
    </div>
    <div
      *ngIf="(isMessageAttendant) && ((attendance?.channel.type !== 'CLOUD_API') && !message.automated) && ((isAttendant() && getCurrentUserUser().deleteMessage) || isAdmin() || isSupervisor())"
      role="none">
      <a href="javascript:void(0)"
        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 hover:rounded-lg block px-4 py-2 text-sm"
        role="menuitem" tabindex="-1" id="menu-item-0"
        (click)="deleteMessageForEveryone(message); $event.stopPropagation();">
        Deletar mensagem
      </a>
    </div>
    <div *ngIf="(isMessageAttendant) && (attendance?.channel.type !== 'CLOUD_API') && ((isAttendant() &&  getCurrentUserUser().editMessage) || isAdmin() || isSupervisor())" role="none">
      <a href="javascript:void(0)"
        class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 hover:rounded-lg block px-4 py-2 text-sm"
        role="menuitem" tabindex="-1" id="menu-item-0" (click)="editMessage(message); $event.stopPropagation();">
        Editar mensagem
      </a>
    </div>
  </div>
</div>
