<div class="w-full">

  <h1 class="font-black text-[20px] text-black mt-2.5">
    {{ data.mode === 'delete' ? 'Excluir' : (isNew ? 'Criar' : 'Editar') }} assistente
  </h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">
    {{ data.mode === 'delete' ? 'Tem certeza que deseja excluir este assistente?' : 'Configure seu assistente OpenAI' }}
  </h6>

  <form *ngIf="data.mode !== 'delete'" [formGroup]="formGroup" style="max-height: 465px; overflow-y: auto; overflow-x: hidden; padding: 5px;">
    <div class="flex w-full flex-col">
      <div class="flex gap-2">
        <div class="flex flex-col flex-1">
          <label class="font-medium text-black">Nome *</label>
          <input class="input-field" maxlength="100" formControlName="name" placeholder="Digite o nome do assistente..." required>
          <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
          </field-error-component>
        </div>
      </div>

      <div class="flex flex-col w-full mt-[15px]">
        <label class="font-medium text-black">Modelo *</label>
        <mat-select class="input-field" formControlName="model">
          @for (model of availableModels; track model) {
            <mat-option [value]="model">{{ model }}</mat-option>
          }
        </mat-select>
      </div>

      <div class="flex flex-col w-full mt-[15px]">
        <label class="font-medium text-black">Descrição</label>
        <input class="input-field" maxlength="200" formControlName="description" placeholder="Digite uma descrição...">
      </div>

      <div class="flex flex-col w-full mt-[15px]">
        <label class="font-medium text-black">Prompt *</label>
        <textarea class="input-textarea h-[120px]" style="width: 100%; resize: none; outline: none;" maxlength="32768"
          formControlName="instructions" placeholder="Digite as Prompt para o assistente..."></textarea>
        <field-error-component [field]="'instructions'" [label]="'Prompt'" [formGroup]="formGroup">
        </field-error-component>
      </div>

      <div class="flex flex-col w-full mt-[15px]">
        <label class="font-medium text-black">Ferramentas</label>
        <div formGroupName="tools" class="flex flex-col gap-2 mt-2">
          <mat-slide-toggle formControlName="code_interpreter" color="primary">
            Code Interpreter
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="retrieval" color="primary">
            Retrieval
          </mat-slide-toggle>
          <mat-slide-toggle formControlName="function_calling" color="primary">
            Function Calling
          </mat-slide-toggle>
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="data.mode === 'delete'" class="text-center">
    <div class="flex flex-col items-center justify-center">
      <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M40 76C59.33 76 75 60.33 75 41C75 21.67 59.33 6 40 6C20.67 6 5 21.67 5 41C5 60.33 20.67 76 40 76Z" fill="#FEE4E2" stroke="#D92D20" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M40 26V41L50 46" stroke="#D92D20" stroke-width="10" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
      <p class="text-red-600 font-semibold mt-4">Esta ação não pode ser desfeita</p>
      <p class="text-gray-600 mt-2">O assistente "{{ data.assistant?.name || 'sem nome' }}" será removido permanentemente</p>
    </div>
  </div>
</div>

<div class="flex justify-between items-center w-full gap-2 px-0 mt-4">
  <button
    class="h-[45px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[50%]"
    (click)="close()">Cancelar</button>
  <button
    *ngIf="data.mode === 'delete'"
    class="h-[45px] flex justify-center items-center rounded-lg bg-[#FF4D4F] text-white font-normal hover:bg-red-600 w-[50%]"
    (click)="onSubmit()" [disabled]="loading">
    <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
    <span *ngIf="!loading">Excluir Assistente</span>
  </button>
  <button
    *ngIf="data.mode !== 'delete'"
    class="h-[45px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#3A11D9] w-[50%]"
    (click)="onSubmit()" [disabled]="!formGroup.valid || loading">
    <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
    <span *ngIf="!loading">{{ isNew ? 'Criar' : 'Atualizar' }} assistente</span>
  </button>
</div>
