import { Component, Inject, Injector } from '@angular/core';
import { Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Flow, FlowService } from 'lib-trend-core';
import { timer } from 'rxjs';


export interface DialogData {
  name: string
  description: string
}

@Component({
  selector: 'flow-form-component',
  templateUrl: './flow-form.component.html',
})
export class FlowFormComponent extends AbstractComponent {
  public isLoading: boolean
  private timeOut

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<FlowFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public flowService: FlowService,
  ) {
    super(injector)
    this.createForm()
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      name: [null, Validators.required],
      description: [null, Validators.required],
    })
  }

  redirectToFlowBuilder(idFlow: string) {
    timer(3000).subscribe(() => {
      this.router.navigate([`/flow/edit/`, idFlow]);
      this.dialogRef.close();
      this.isLoading = false;
    });
  }


  createFlow(): void {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup)
      return
    }

    const flow = {} as Flow;
    flow.name = this.formGroup.get('name').value;
    flow.description = this.formGroup.get('description').value;
    flow.nodes = [];
    flow.edges = [];
    this.isLoading = true;
    this.flowService.create(flow).subscribe({
      next: (value) => {
        this.redirectToFlowBuilder(value._id)
      }
    });
  }

}
