import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Attendance, AttendanceService, AttendanceStatusEnum, Channel, ChannelService, ConfirmationComponent, Department, DepartmentService, Pager, SidebarService, Team, TeamService, User, UserService } from 'lib-trend-core';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, startWith, switchMap, tap } from 'rxjs';
import { AttendanceHistoryComponent, AttendanceHistoryData } from './attendance-history/attendance-history.component';
import { AssignedUserComponent } from './attendance-panel/attendance-panel-components/assigned-user/assigned-user.component';

@Component({
  templateUrl: 'attendance.component.html'
})
export class AttendanceComponent extends AbstractComponent implements OnInit {
  pager: Pager<Attendance> = new Pager<Attendance>({ perPage: 10 });
  showDetails: boolean = false;
  showDetailsContact: boolean = false;
  defaultValue: string = '';

  listObservable: Observable<Pager<Attendance>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  attendancesPending: number = 0;
  attendancesInProgress: number = 0;
  attendancesClosed: number = 0;
  attendancesOvertime: number = 0;
  attendancesPaused: number = 0;

  listStatusAttendances: Array<AttendanceStatusEnum> = new Array<AttendanceStatusEnum>(
    AttendanceStatusEnum.OVERTIME,
    AttendanceStatusEnum.PENDING,
    AttendanceStatusEnum.IN_PROGRESS,
    AttendanceStatusEnum.PAUSED,
    AttendanceStatusEnum.CLOSED
  );
  listDepartment: Array<Department> = new Array<Department>();
  listChannel: Array<Channel> = new Array<Channel>();
  listUser: Array<User> = new Array<User>();
  listTeam: Array<Team> = new Array<Team>();

  labelStatus: string = 'Status';
  labelDepartment: string = 'Departamentos';
  labelChannel: string = 'Canais';
  labelUser: string = 'Responsável';

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private attendanceService: AttendanceService,
    private sidebarService: SidebarService,
    private departmentService: DepartmentService,
    private channelService: ChannelService,
    private userService: UserService,
    private teamService: TeamService,
  ) {
    super(injector);
    this.loadingContent = true;
    this.createForm();
  }

  ngOnInit() {
    this.getList();
    this.getAttendancesByStatus();
    this.getListChannel();
    this.setupCombinedFiltersAndSearch();

    if (this.isAdmin()) {
      this.getListUser();
      this.getListDepartment();
    } else if (this.isSupervisor() || this.isAttendant()) {
      this.getListTeam();
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      channel: [null],
      user: [null],
      department: [null],
      status: [null],
    });
  }

  getList() {
    this.loadingContent = true;
    this.searchParams = {};

    const status = this.formGroup.get('status')?.value;
    if (status) {
      this.searchParams['status'] = status;
    }

    const department = this.formGroup.get('department')?.value;
    if (department) {
      this.searchParams['department'] = department;
    }

    const channel = this.formGroup.get('channel')?.value;
    if (channel) {
      this.searchParams['channel'] = channel;
    }

    const user = this.formGroup.get('user')?.value;
    if (user) {
      this.searchParams['user'] = user;
    }

    this.attendanceService.getPagerToAttendance(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Attendance>) => {
        this.pager = pager;
      },
      complete: () => this.loadingContent = false,
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  delete(attendance: Attendance) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.attendanceService.delete(attendance._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success();
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.attendanceService.getPagerToAttendance(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Attendance>) => {
      this.pager = pager;
    });
  }

  private setupCombinedFiltersAndSearch(): void {
    combineLatest([
      this.formGroup.valueChanges.pipe(
        startWith(this.formGroup.value),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr))
      ),
      this.termOfSearch.pipe(
        startWith(''),
        debounceTime(500),
        distinctUntilChanged()
      )
    ])
      .pipe(
        tap(() => {
          this.pager.list = [];
        })
      )
      .subscribe(([formValues, searchTerm]) => {
        this.searchString = searchTerm;
        this.getList();
      });
  }

  search(term: string) {
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  changeShowDetails() {
    this.showDetails = !this.showDetails;
  }

  changeShowDetailsContact() {
    this.showDetailsContact = !this.showDetailsContact;
  }

  closeMenu() {
    this.sidebarService.setExpandedState(false);
  }

  private getAttendancesByStatus(): void {
    this.attendanceService.getCountAttendancesByStatus().subscribe({
      next: (aggResult: Array<{ status: AttendanceStatusEnum, count: number }>) => {
        aggResult.forEach((value: { status: AttendanceStatusEnum, count: number }) => {
          switch (value.status) {
            case AttendanceStatusEnum.PENDING:
              this.attendancesPending = value.count;
              break;
            case AttendanceStatusEnum.IN_PROGRESS:
              this.attendancesInProgress = value.count;
              break;
            case AttendanceStatusEnum.OVERTIME:
              this.attendancesOvertime = value.count;
              break;
            case AttendanceStatusEnum.OVERTIME:
              this.attendancesOvertime = value.count;
              break;
            case AttendanceStatusEnum.CLOSED:
              this.attendancesClosed = value.count;
              break;
            default:
              this.attendancesPaused = value.count;
              break;
          }
        });
      },
    });
  }

  openAssignedUserTransferModal(attendance: Attendance) {
    const dialogRef = this.dialog.open(AssignedUserComponent, {
      width: '600px',
      data: attendance
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList()
    });
  }

  showTransferButton(attendance: Attendance): boolean {
    return attendance.status === AttendanceStatusEnum.PENDING || attendance.status === AttendanceStatusEnum.IN_PROGRESS
      || attendance.status === AttendanceStatusEnum.OVERTIME || attendance.status === AttendanceStatusEnum.PAUSED;
  }

  showDetailsAttendanceHitory(item: Attendance) {
    this.dialog.open(AttendanceHistoryComponent, {
      width: '90vw',
      height: '95vh',
      data: <AttendanceHistoryData>{
        idContact: item.contact._id,
        idAttendance: item._id,
      }
    });
  }

  searchByStatus(status: AttendanceStatusEnum): void {
    if (status) {
      this.labelStatus = status === AttendanceStatusEnum.OVERTIME ? 'Fora de horário' :
        status === AttendanceStatusEnum.PENDING ? 'Aguardando' : status === AttendanceStatusEnum.IN_PROGRESS ?
          'Em progresso' : status === AttendanceStatusEnum.PAUSED ? 'Pausado' : status === AttendanceStatusEnum.CLOSED ?
            'Fechado' : '-';
      this.formGroup.get('status').setValue(status);
    } else {
      this.labelStatus = 'Status';
      this.formGroup.get('status').setValue(null);
    }

    this.pager.page = 1;
  }

  searchByDepartment(department: Department): void {
    if (department) {
      this.labelDepartment = department.name;
      this.formGroup.get('department').setValue(department._id);
    } else {
      this.labelDepartment = 'Departamentos';
      this.formGroup.get('department').setValue(null);
    }

    this.pager.page = 1;
  }

  searchByChannel(channel: Channel): void {
    if (channel) {
      this.labelChannel = channel.name;
      this.formGroup.get('channel').setValue(channel._id);
    } else {
      this.labelChannel = 'Canais';
      this.formGroup.get('channel').setValue(null);
    }

    this.pager.page = 1;
  }

  searchByUser(user: User): void {
    if (user) {
      this.labelUser = user.name;
      this.formGroup.get('user').setValue(user._id);
    } else {
      this.labelUser = 'Responsável';
      this.formGroup.get('user').setValue(null);
    }

    this.pager.page = 1;
  }

  private getListDepartment(): void {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.listDepartment = list;
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os departamentos. Tente novamente mais tarde.'),
    });
  }

  private getListChannel(): void {
    this.channelService.getList().subscribe({
      next: (list: Array<Channel>) => {
        this.listChannel = list;
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os canais. Tente novamente mais tarde.'),
    });
  }

  private getListUser(): void {
    this.userService.getList().subscribe({
      next: (list: Array<User>) => {
        this.listUser = list;
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os usuários da sua empresa. Tente novamente mais tarde.'),
    });
  }

  private getListTeam(): void {
    this.teamService.getList().subscribe({
      next: (list: Array<Team>) => {
        this.listTeam = list
          .filter((team: Team) => {
            const userId = this.getCurrentUserUser()._id;
            const isAgent = team.agents.some((agent: User) => agent._id === userId);
            const isSupervisor = team.supervisors.some((supervisor: User) => supervisor._id === userId);
            return isAgent || isSupervisor;
          })
          .filter((team: Team) => team.department && team.department.removed !== true);
      },
      error: () => this.alertService.error('Ops! Não foi possível trazer os times da sua empresa. Tente novamente mais tarde.'),
    });
  }

}
