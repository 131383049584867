<div class="flex flex-1 flex-col px-5">

  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Integrações OpenAI</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Organize seus departamentos conforme seu processo de atendimento
    </h6>
    <hr />
  </div>

  <div class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg bg-white border-gray-100 border-2 p-6">
    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
      <div class="flex flex-1 items-center gap-4 mb-4">
        <div class="flex flex-col flex-1">
          <label class="font-semibold text-black">Nome da Configuração *</label>
          <input class="input-field" formControlName="name" placeholder="Ex: Configuração Produção">
          <field-error-component [field]="'name'" [label]="'Nome'" [formGroup]="formGroup">
          </field-error-component>
        </div>
      </div>
      <ng-container formGroupName="metadata">

        <div class="flex flex-1 items-center gap-4 mb-4">
          <div class="flex flex-col flex-1">
            <label class="font-semibold text-black">API Key *</label>
            <div class="relative">
              <input
                class="input-field pr-10"
                [type]="hideAllPasswords ? 'password' : 'text'"
                formControlName="token"
                placeholder="sk-..."
              >
              <i
                class="material-icons absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
                (click)="togglePasswordVisibility()"
              >
                {{ hideAllPasswords ? 'visibility_off' : 'visibility' }}
              </i>
            </div>
            <field-error-component [field]="'metadata.token'" [label]="'Api Key'" [formGroup]="formGroup"></field-error-component>
          </div>
        </div>
        <div class="flex flex-1 items-center gap-4 mb-4">
          <div class="flex flex-col flex-1">
            <label class="font-semibold text-black">Organization ID *</label>
            <input class="input-field" formControlName="organization" placeholder="org-...">
            <field-error-component [field]="'metadata.organization'" [label]="'Organization'" [formGroup]="formGroup">
            </field-error-component>
          </div>
        </div>
        <div class="flex flex-1 items-center gap-4 mb-4">
          <div class="flex flex-col flex-1">
            <label class="font-semibold text-black">Project ID *</label>
            <input class="input-field" formControlName="project" placeholder="proj-...">
            <field-error-component [field]="'metadata.project'" [label]="'Project'" [formGroup]="formGroup">
            </field-error-component>
          </div>
        </div>
      </ng-container>
      <div class="flex justify-end mt-6">
        <button
        class="ml-4 w-[fit-content] px-3 h-[46px] flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 " (click)="cancel()"
        >Cancelar
        </button>
        <button (click)="goToAssistants()"
        class="ml-4 w-[fit-content] px-3 h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]">
        Ir para Assistentes
        </button>
        <button [disabled]="loading"  (click)="onSubmit()" class="ml-4 w-[fit-content] px-3 h-[46px] flex justify-center items-center rounded-lg bg-[#4213F6] text-white font-normal hover:bg-[#1340f6]">
          <mat-icon *ngIf="loading" class="animate-spin">refresh</mat-icon>
          <span>{{ loading ? 'Salvando...' : 'Salvar Configuração' }}</span>
        </button>
      </div>
    </form>
  </div>
</div>
