<div class="w-full h-full">
  <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="48" height="48" rx="24" fill="#D1FADF" />
    <rect width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path
      d="M14.9338 20.5H25.9338V22.5H14.9338V20.5ZM14.9338 18.5H25.9338V16.5H14.9338V18.5ZM14.9338 26.5H21.9338V24.5H14.9338V26.5ZM29.9438 23.37L30.6538 22.66C31.0438 22.27 31.6738 22.27 32.0638 22.66L32.7738 23.37C33.1638 23.76 33.1638 24.39 32.7738 24.78L32.0638 25.49L29.9438 23.37ZM29.2338 24.08L23.9338 29.38V31.5H26.0538L31.3538 26.2L29.2338 24.08Z"
      fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">{{isNew ? 'Adicionar' : 'Alterar'}} resposta</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e crie uma nova mensagem</h6>
  <form class="w-[100%] h-[100%]" [formGroup]="formGroup">
    <div class="flex flex-col">
      <label class="font-semibold text-black">{{isNew ? 'Selecionar' : 'Selecione'}} uma categoria *</label>
      <mat-select class="input-field" formControlName="category" placeholder="Selecione" style="margin-bottom: 0px;">
        <div class="input-select-filter w-full p-2 mb-2">
          <input class="input-field input-select-filter" type="text" (focus)="onFocusCategory()"
            [formControl]="filterControlCategory" placeholder="Pesquise por uma categoria...">
        </div>
        @for (item of listCategory; track item) {
        <mat-option [value]="item._id">{{item.name}}</mat-option>
        }
      </mat-select>
      <field-error-component [field]="'category'" [label]="'Categoria'" [formGroup]="formGroup">
      </field-error-component>
    </div>
    <div class="flex flex-col">
      <label class="text-black mt-[15px]">Tipo de Resposta *</label>
      <mat-radio-group class="w-full flex" color="primary" aria-label="Selecione o tipo de resposta"
        formControlName="typeResponse">
        <mat-radio-button value="TEXT">Texto/Imagem</mat-radio-button>
        <mat-radio-button value="CONTACT">Contato</mat-radio-button>
        <mat-radio-button value="LOCATION">Localização</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="flex flex-col">
      <button class="w-full flex text-[#4213F6]" (click)="selectContact()" *ngIf="isTypeContact() && !getContact()">Selecionar Contato</button>
    </div>
    <div class="flex flex-col">
      <button class="w-full flex text-[#4213F6]" (click)="selectLocation()" *ngIf="isTypeLocation() && !getPlace()">Selecionar Localização</button>
    </div>
    @if (isTypeText()) {
    <div class="flex flex-col">
      <label class="font-semibold text-black mt-[15px]">Mensagem *</label>
      <textarea (input)="updateCount()" class="input-field !mb-0" formControlName="message"
        placeholder="Digite a mensagem..." maxlength="1200" style="resize: none;"
        [attr.maxlength]="maxCharacters"></textarea>
      <field-error-component [field]="'message'" [label]="'Mensagem'" [formGroup]="formGroup">
      </field-error-component>
      <small class="text-[.825rem] text-end text-[#bdbdbd]">{{remainingCharacters}}</small>
    </div>
    <div class="flex justify-center items-center gap-9 w-full max-h-[500px]">
      <div class="loading-container">
        <loading-component class="loading" *ngIf="loadingSpinner$ | async"></loading-component>
        @if (response.midia) {
        <div class="w-full h-[100%] flex flex-col items-center">
          <img [src]="midiaImage()" class="w-full h-[80%] rounded-full object-contain" alt="Sua imagem">
          <button (click)="removeMidia()"
            class="bg-red-600 text-white flex items-center rounded mt-[10px]"><mat-icon>clear</mat-icon></button>
        </div>
        }
        @if (!response.midia) {
        <label for="dropzone-file"
          class="flex flex-col items-center justify-center w-[100%] h-[200px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-white">
          <div class="flex flex-col items-center justify-center pt-5 pb-6">
            <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="4.5" y="4" width="48" height="48" rx="24" fill="#E6E6FF" />
              <rect x="4.5" y="4" width="48" height="48" rx="24" stroke="#F1F1FF" stroke-width="8" />
              <g clip-path="url(#clip0_6074_436)">
                <path
                  d="M32.5 32L28.5 28M28.5 28L24.5 32M28.5 28V37M36.89 34.39C37.8653 33.8583 38.6358 33.0169 39.0798 31.9986C39.5239 30.9804 39.6162 29.8432 39.3422 28.7667C39.0682 27.6901 38.4434 26.7355 37.5666 26.0534C36.6898 25.3714 35.6108 25.0007 34.5 25H33.24C32.9373 23.8292 32.3731 22.7423 31.5899 21.821C30.8067 20.8996 29.8248 20.1678 28.7181 19.6806C27.6113 19.1933 26.4085 18.9633 25.2001 19.0079C23.9916 19.0524 22.809 19.3703 21.7411 19.9376C20.6732 20.505 19.7479 21.3071 19.0346 22.2836C18.3213 23.26 17.8387 24.3855 17.6229 25.5754C17.4072 26.7652 17.4641 27.9885 17.7892 29.1532C18.1143 30.318 18.6992 31.3938 19.5 32.3"
                  stroke="#4213F6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_6074_436">
                  <rect width="24" height="24" fill="white" transform="translate(16.5 16)" />
                </clipPath>
              </defs>
            </svg>
            <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span
                class="font-semibold text-[#4213F6] text-xl">Clique aqui para carregar</span> ou arraste e solte</p>
            <p class="text-xs text-gray-500 dark:text-gray-400">PNG, JPG ou JPEG, PDF, AUDIO - MAX(10MB)</p>
          </div>
          <input id="dropzone-file" type="file" class="hidden" (change)="onFileSelected($event)" />
        </label>
        }
      </div>
    </div>
    } @else if (isTypeContact()) {
      @if(getContact()) {
        <div id="table-modal-response">
          <table class="min-w-full divide-gray-100 divide-y-[3px]">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nome</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Email</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Telefone</th>
                <th scope="col" class="px-3 py-3 5 text-left text-sm font-light text-slate-500">Perfil</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4">
                  <span class="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-gray-100 divide-y-[3px] bg-white">
              <tr class="rounded-none">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{getContact()?.name | truncate: 50 }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{getContact()?.email ?? '-' | truncate:
                  50}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{getContact()?.phone}}</td>
                <td class="px3">
                  <img class="w-8 h-8 rounded-full" [src]="getContactImage(getContact())" [alt]="getContact()?.name"
                    (error)="onImageError($event)">
                </td>
                <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                  class="flex items-center justify-center w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none"
                      (click)="removeContact()"> <i class="ph-light ph-trash-simple"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    } @else if (isTypeLocation()) {
      @if(getPlace()) {
        <div id="table-modal-response">
          <table class="min-w-full divide-gray-100 divide-y-[3px]">
            <thead class="bg-gray-50">
              <tr>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Endereço</th>
                <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Longitude</th>
                <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Latitude</th>
                <th scope="col" class="relative py-3.5 pl-3 pr-4">
                  <span class="sr-only"></span>
                </th>
              </tr>
            </thead>
            <tbody class="divide-gray-100 divide-y-[3px] bg-white">
              <tr class="rounded-none">
                <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">{{getPlace()?.formattedAddress ? getPlace()?.formattedAddress : 'Local Selecionado' | truncate: 50 }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{getPlace()?.location.longitude}}</td>
                <td class="whitespace-nowrap px-3 py-4 text-sm font-light text-gray-900">{{getPlace()?.location.latitude}}</td>
                <td class="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                  <button
                  class="flex items-center justify-center w-50 p-2.5 mt-1 mb-2 border-2 border-[#BDBDBD] rounded-lg  text-[inherit] font-[inherit] hover:bg-gray-50 focus:border-[#BDBDBD] focus:outline-none"
                      (click)="removePlace()"> <i class="ph-light ph-trash-simple"></i></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      }
    }
  </form>
</div>
<div align="center" class="flex justify-between items-center w-full gap-2 mt-2">
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]"
    (click)="close()">Fechar</button>
  <button
    class="h-[45px] px-14 flex justify-center items-center rounded-lg  text-white font-normal   bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
    (click)="save()" [disabled]="!formGroup.valid">{{isNew ? 'Adicionar' : 'Alterar'}}</button>
</div>