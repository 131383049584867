
import { Component, Injector, Input, OnInit } from '@angular/core';
import { AbstractComponent, Attendance, Message, UtilHelper } from 'lib-trend-core';

@Component({
  selector: 'chat-message-reply-component',
  templateUrl: './chat-message-reply.component.html',
  styleUrls: ['./chat-message-reply.component.scss']
})
export class ChatMessageReplyComponent extends AbstractComponent implements OnInit {

  @Input() attendance: Attendance;
  @Input() message: Message;

  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  async ngOnInit(): Promise<any> {
  }

  formatWhatsappMessage(text: string): string {
    return UtilHelper.formatWhatsappMessage(text);
  }

  getIconForContentType(contentType: string): string {
    return UtilHelper.getIconForContentType(contentType);
  }

  scrollToMessage(event: MouseEvent, messageId: string) {
    event.preventDefault();
    const element = document.getElementById(messageId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  isMedia(contentType: string | null): boolean {
    if (!contentType) return false;

    const mimePrefix = contentType.split('/')[0];
    return ['audio', 'image', 'video', 'application'].includes(mimePrefix);
  }

  getAttachmentMessage(contentType: string): string {
    const mediaMessageMap: { [key: string]: string } = {
      'image': 'Clique para ver a imagem',
      'video': 'Clique para assistir ao vídeo',
      'audio': 'Clique para ouvir o áudio',
      'application': 'Clique para ver o documento'
    };

    const mimePrefix = contentType.split('/')[0];

    return mediaMessageMap[mimePrefix] ?? 'Clique para ver o anexo';
  }
}


