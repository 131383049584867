<div class="flex flex-1 flex-col px-5">
  <div class="flex flex-1 justify-between items-center mb-4">
    <div>
      <h1 class="font-black text-[20px] text-black">{{flow?.name}}</h1>
      <h6 class="text-zinc-400 font-light text-sm my-2">
        Configure o seu fluxo para que possa testá-lo, dispará-lo ou publicá-lo
      </h6>
    </div>
    <div class="flex gap-3">
      <button
      class="h-[45px] px-8 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      routerLink="/flow"
      *ngIf="isAdmin()"
    >
      Voltar
    </button>
    <button
      class="h-[45px] px-8 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      *ngIf="isAdmin()"
      (click)="triggerFlow()"
    >
      Testar
    </button>
    <button
      class="h-[45px] px-8 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      *ngIf="isAdmin()"
    >
      Salvar
    </button>
    <button
      class="h-[45px] px-8 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none"
      *ngIf="isAdmin()"
    >
      Publicar
    </button>
    </div>
  </div>
  <hr />
  <iframe width="100%" height="1000" *ngIf="flow" [src]="urlEmbeded"></iframe>
</div>