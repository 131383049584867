<div class="flex flex-1 flex-col px-5">
  <div class="mb-4">
    <h1 class="font-black text-[20px] text-black">Contatos e Grupos</h1>
    <h6 class="text-zinc-400 font-light text-sm my-2">Organize seus contatos e grupos conforme sua processo de
      atendimento</h6>
    <hr />
  </div>
  <div>
    <div class="flex flex-1 gap-4">
      <card-graphic-small-component 
      [label]="'Total de atendimentos'" 
      [total]="countAttendance.toString()">
    </card-graphic-small-component>
    
    <card-graphic-small-component 
      [label]="'Total de notas'" 
      [total]="totalNotes.toString()">
    </card-graphic-small-component>
    
    <card-graphic-small-component 
      [label]="'Total de tags'" 
      [total]="totalTags.toString()">
    </card-graphic-small-component>
    </div>
  </div>
  
  <section class="mt-3">
    <div class="grid lg:grid-cols-10 grid-cols-1 gap-6 w-full h-full items-stretch">
      <div class="lg:col-span-10">
        <div class="flex ">
          <input id="tab-one" type="radio" name="tabs" class="peer/tab-one hidden" (click)="changeNavType('notes')"
            [checked]="navType === 'notes'" />
          <label for="tab-one"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-one:border-b-2 peer-checked/tab-one:border-[#4213F6] peer-checked/tab-one:text-[#4213F6] cursor-pointer px-4 py-2">
            Notas
          </label>
    
          <input id="tab-two" type="radio" name="tabs" class="peer/tab-two hidden" (click)="changeNavType('tags')"
            [checked]="navType === 'tags'" />
          <label for="tab-two"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-two:border-b-2 peer-checked/tab-two:border-[#4213F6] peer-checked/tab-two:text-[#4213F6] cursor-pointer px-4 py-2">
            Tags
          </label>
    
          <input id="tab-three" type="radio" name="tabs" class="peer/tab-three hidden" (click)="changeNavType('attendance')"
            [checked]="navType === 'attendance'" />
          <label for="tab-three"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-three:border-b-2 peer-checked/tab-three:border-[#4213F6] peer-checked/tab-three:text-[#4213F6] cursor-pointer px-4 py-2">
            Atendimentos
          </label>
    
          <input id="tab-four" type="radio" name="tabs" class="peer/tab-four hidden" (click)="changeNavType('customFields')"
            [checked]="navType === 'customFields'" />
          <label for="tab-four"
            class="text-base font-medium hover:text-[#4213F6] peer-checked/tab-four:border-b-2 peer-checked/tab-four:border-[#4213F6] peer-checked/tab-four:text-[#4213F6] cursor-pointer px-4 py-2">
            Campos Personalizados
            <!-- <span class="bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full">
              {{ customFields.length }}
            </span> -->
          </label>
        </div>
      </div>

      <div class="lg:col-span-10 flex gap-6 h-full">
        <div class="flex-1 h-full ">
          <div class="w-full bg-[#fff] p-3 rounded-lg shadow-global mb-2">
            <div *ngIf="navType === 'notes'">

              <div class="flex justify-end mb-4">
                <button 
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="openNoteDialog()">
                  <mat-icon class="mr-2">add</mat-icon>
                  Nova Nota
                </button>
              </div>
              <div
              class="w-full  flex flex-wrap gap-[12px] mt-2"
            >
            
            <div class="inline-block min-w-full py-2 align-middle">

              <div   class="overflow-visible rounded-lg bg-white"
              [ngClass]="{
                'ring-1 ring-black ring-opacity-5 border-gray-100 border-2': pager.list?.length
              }">
<content-loading-list *ngIf="loadingContent"></content-loading-list>

<empty-records-component 
  *ngIf="!loadingContent && !pager.list?.length" 
  [message]="'Nenhuma nota encontrada'"
  [subMessage]="'Adicione uma nova nota para começar a registrar suas informações'">
</empty-records-component>

<table *ngIf="!loadingContent && pager.total > 0" class="min-w-full divide-gray-100 divide-y-[3px] relative">
                  <thead class="bg-gray-50">
                    <tr>
                      <th scope="col" class="px-3 py-3.5 text-left text-sm font-light text-slate-500">Data</th>
                      <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-light text-slate-500">Nota</th>
                      <th scope="col" class="w-16"></th>
                    </tr>
                  </thead>
                  <tbody class="divide-gray-100 divide-y-[3px] bg-white">
                    <tr *ngFor="let note of pager.list">
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                        {{ note.createdAt | date:'shortDate' }}
                      </td>
                      <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-light text-gray-900">
                        {{ (note.description || 'Sem descrição') | truncate : 50}}
                      </td>
                      <td class="relative">
                        <div class="inline-block text-left relative" x-data="{ menu: false }" style="position: relative;">
                          <button x-on:click="menu = ! menu" type="button"
                            class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                            id="menu-button" aria-expanded="true" aria-haspopup="true">
                            <span class="sr-only"></span>
                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                              fill="currentColor" aria-hidden="true">
                              <path
                                d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                            </svg>
                          </button>
                          <div class="p-2" x-show="menu" x-on:click.away="menu = false"
                            style="position: absolute; z-index: 9999;"
                            class="rounded-lg ring-1 ring-black ring-opacity-5 shadow-lg divide-y focus:outline-none bg-white right-0 mt-2"
                            role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                            <div class="w-[195px]" role="none">
                              <a href="javascript:void(0)" *ngIf="note.attendance"
                              (click)="openAttendanceNote(note.attendance._id, note.attendance.status)"
                              class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                              role="menuitem" tabindex="-1" id="menu-item-0">
                              Ir para o atendimento
                            </a>
                              <a href="javascript:void(0)"
                              class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                              role="menuitem" tabindex="-1" id="menu-item-0"
                              (click)="openNoteDialog(note); $event.stopPropagation();">
                              Visualizar
                            </a>
                          <a href="javascript:void(0)"
                          class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                          role="menuitem" tabindex="-1" id="menu-item-0"
                          (click)="deleteNote(note); $event.stopPropagation();">
                          Remover
                        </a>
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="notes?.length === 0">
                      <td colspan="3" class="text-center py-4 text-sm text-gray-500">
                        Nenhuma nota encontrada
                      </td>
               
                    </tr>
                  </tbody>
                </table>
                </div>
                <paginator *ngIf="pager.total > pager.perPage" 
                  class="flex justify-content-center mt-3 mb-3"
                  [pager]="pager" 
                  (onPager)="loadPage($event)">
                </paginator>
              </div>
            </div>
            
            </div>
            <div *ngIf="navType === 'tags'">
              <mat-select class="input-field" (selectionChange)="selected($event)" placeholder="Selecione">
                <mat-option *ngFor="let tag of availableTags; trackBy: trackByTagId" [value]="tag">
                  {{ tag.title }}
                </mat-option>
              </mat-select>
            
              <div class="flex flex-wrap gap-1 pt-4">
                <mat-chip-row *ngFor="let tag of selectedTags; trackBy: trackByTagId"
                  class="mr-1 mb-1"
                  [ngStyle]="{'background-color': tag.bgColor || '#fff'}"
                  (removed)="removeTag(tag)"
                  [editable]="false">
                  <span [ngStyle]="{'color': tag.fontColor || '#fff'}">
                    {{ tag.title }}
                  </span>
                  <button matChipRemove [attr.aria-label]="'Remover ' + tag.title">
                    <mat-icon>cancel</mat-icon>
                  </button>
                </mat-chip-row>
              </div>
            </div>
            
            <div *ngIf="navType === 'attendance'">
              <contact-view-attendance-list-component [idContact]="contact._id"></contact-view-attendance-list-component>
            </div>
            <div *ngIf="navType === 'customFields'">
              <div class="flex justify-end mb-4">
                <button 
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="addCustomField()">
                  <mat-icon class="mr-2">add</mat-icon>
                  Novo Campo
                </button>
              </div>

              <div class="flex flex-col gap-4">
                <div *ngFor="let field of customFields; let i = index" class="flex flex-col w-full">
                  <div class="flex gap-4">
                    <div class="flex flex-col w-1/2">
                      <label class="font-medium text-black">Nome</label>
                      <mat-select class="input-field" [(ngModel)]="field.name" (selectionChange)="onCustomFieldChange(i, $event.value)">
                        <mat-option *ngFor="let option of fieldOptions" [value]="option" [disabled]="isFieldSelected(option, i)">
                          {{ option }}
                        </mat-option>
                      </mat-select>
                    </div>

                    <div class="flex flex-col w-1/2">
                      <label class="font-medium text-black">{{ field.name || 'Valor' }}</label>
                      <input class="input-field" [(ngModel)]="field.value" placeholder="Digite o valor...">
                    </div>

                    <div class="flex items-end">
                      <button mat-icon-button color="warn" class="mb-2" (click)="removeCustomField(i)">
                        <mat-icon>delete</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>

                <div *ngIf="customFields.length === 0" class="text-center py-4 text-sm text-gray-500">
                  <empty-records-component [message]="'Nenhum campo personalizado encontrado'"
                  [subMessage]="'Adicione um novo campo personalizado acima'">
                </empty-records-component>
                </div>

       
              </div>
              <div class="flex justify-end mt-4">
                <button 
                  class="h-[45px] flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[200px]"
                  (click)="saveCustomFields()">
                  Salvar Campos
                </button>
              </div>
            </div>
          </div>
        </div>
          <div>
            <div class="w-[550px] rounded-lg shadow-global bg-white flex flex-col">
              <div class="w-full flex items-center p-3 justify-between">
                <div class="flex items-center">
                  <img [src]="contact.image" alt="image user" class="w-[50px] rounded-full ml-2" (error)="onImageError($event)" />
                  <div class="ml-2">
                    <p class="font-bold">{{ contact.name }}</p>
                    <p class="font-normal text-sm pt-2 flex items-center gap-1 text-gray-400">
                      <img src="../../../../../../../assets/images/icon-wp.svg" alt="icon not oficial" width="15" height="15" />
                      {{ contact.phone }}
                    </p>
                    <p *ngIf="contact.email" class="font-normal text-sm flex items-center text-gray-400">
                      <mat-icon class="text-base">email</mat-icon>{{ contact.email }}
                    </p>
                  </div>
                </div>
                <div class="inline-block text-left" x-data="{ menu: false }">
                  <button x-on:click="menu = ! menu" type="button"
                    class="flex items-center text-gray-400 hover:text-gray-600 focus:outline-none"
                    id="menu-button" aria-expanded="true" aria-haspopup="true">
                    <span class="sr-only"></span>
                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                      fill="currentColor" aria-hidden="true">
                      <path
                        d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
                    </svg>
                  </button>
                  <div x-show="menu" x-on:click.away="menu = false"
                    class="origin-top-right absolute right-20 w-40 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
                    role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                    <div class="" role="none">
                      <a href="javascript:void(0)"
                      class="text-gray-500 font-medium hover:text-gray-900 hover:bg-gray-50 block px-4 py-2 text-sm"
                      role="menuitem" tabindex="-1" id="menu-item-0"
                      (click)="edit(contact); $event.stopPropagation();">
                      Editar
                    </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="p-4 border-t">
                <div class="mb-3" *ngIf="contact?.gender">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">person</mat-icon>
                    Sexo: <span class="ml-1">{{ getGenderDisplay(contact?.gender) }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.createdAt">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">cake</mat-icon>
                    Data da criação: <span class="ml-1">{{ contact?.createdAt | date:'dd/MM/yyyy' }}</span>
                  </p>
              </div>

                <div class="mb-3" *ngIf="contact?.birthDate">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">cake</mat-icon>
                    Data de Nascimento: <span class="ml-1">{{ contact?.birthDate | date:'dd/MM/yyyy' }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.contactCompany">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">business</mat-icon>
                    Empresa: <span class="ml-1">{{ contact?.contactCompany }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.contactPosition">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">work</mat-icon>
                    Cargo: <span class="ml-1">{{ contact?.contactPosition }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.tin">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">badge</mat-icon>
                    CPF: <span class="ml-1">{{ formatTin(contact?.tin) }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.address">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">location_on</mat-icon>
                    Endereço: <span class="ml-1">{{ contact?.address }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.city">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">location_city</mat-icon>
                    Cidade: <span class="ml-1">{{ contact?.city }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.state">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">map</mat-icon>
                    Estado: <span class="ml-1">{{ contact?.state }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.zip">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">local_post_office</mat-icon>
                    CEP: <span class="ml-1">{{ contact?.zip }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.groups?.length">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">group</mat-icon>
                    Grupos: <span class="ml-1">{{ getGroupsDisplay(contact?.groups) }}</span>
                  </p>
                </div>

                <div class="mb-3" *ngIf="contact?.tags?.length">
                  <p class="font-normal text-sm flex items-center text-gray-400">
                    <mat-icon class="text-base mr-1">local_offer</mat-icon>
                    Tags: <span class="ml-1">{{ getTagsDisplay(contact?.tags) }}</span>
                  </p>
                </div>
              </div>
              <div class="w-full p-3 border-t">

                <button 
                  class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-full mt-4"
                  (click)="openNewConversation()">
                  Abrir atendimento
                </button>
              </div>
            </div>
          </div>
      </div>
    </div>
    
  </section>
</div>