<div class="overflow-y-auto overflow-x-hidden" #modalContainer>
  <div *ngIf="!selectedContact">
    <div class="flex flex-col px-[15px]">
      <div>
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
          <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
          <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
        </svg>
      </div>
      <div class="mt-[15px]">
        <h1 class="text-[22px] font-bold">Selecione um contato</h1>
        <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um contato para mandar um template</h6>

        <span class="errorMessage text-red-600 font-light text-sm">{{errorMessage}}</span>

        <contact-list-component (onSelect)="onContactSelected($event)"></contact-list-component>
      </div>
    </div>
  </div>

  @if (selectedContact) {
  <div [hidden]="step !== 2">
    <div class="w-14 h-14 flex cursor-pointer items-center justify-center rounded-full hover:bg-[#f2f2f3]"
      (click)="clearValuesToBack()">
      <mat-icon class="text-black">arrow_back</mat-icon>
    </div>

    <div class="attendance-conversation-main">
      <div class="content-title-conversation">
        <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M19 9.22C19 3.73 14.74 0 10 0C5.31 0 1 3.65 1 9.28C0.4 9.62 0 10.26 0 11V13C0 14.1 0.9 15 2 15H3V8.9C3 5.03 6.13 1.9 10 1.9C13.87 1.9 17 5.03 17 8.9V16H9V18H17C18.1 18 19 17.1 19 16V14.78C19.59 14.47 20 13.86 20 13.14V10.84C20 10.14 19.59 9.53 19 9.22Z"
            fill="#4213F6" />
          <path
            d="M7 11C7.55228 11 8 10.5523 8 10C8 9.44771 7.55228 9 7 9C6.44772 9 6 9.44771 6 10C6 10.5523 6.44772 11 7 11Z"
            fill="#4213F6" />
          <path
            d="M13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11Z"
            fill="#4213F6" />
          <path
            d="M16 8.03C15.52 5.18 13.04 3 10.05 3C7.02 3 3.76 5.51 4.02 9.45C6.49 8.44 8.35 6.24 8.88 3.56C10.19 6.19 12.88 8 16 8.03Z"
            fill="#4213F6" />
        </svg>
        <h6>Falta pouco para iniciar o atendimento</h6>
      </div>
    </div>

    <div class="title-template">Selecione as opções abaixo</div>
    <span class="errorMessage text-red-600 font-light text-sm">{{errorMessage}}</span>
    <div class="mt-[15px]">
      <h6 class="my-2 font-semibold">Selecione o departamento</h6>
      <div class="input-conversation">
        @if (isAdmin()) {
        <mat-select (selectionChange)="selectDepartment($event.value)" #department>
          <mat-option *ngFor="let department of listDepartments" [value]="department">{{department.name}}</mat-option>
        </mat-select>
        } @else {
        <mat-select (selectionChange)="selectDepartment($event.value)" #department>
          <mat-option *ngFor="let team of listTeam" [value]="team.department">{{team.department.name}}</mat-option>
        </mat-select>
        }
      </div>
    </div>
  </div>

  <div class="mt-[15px]" [hidden]="step !== 2">
    <div>
      <h6 class="my-2 font-semibold">Selecione o canal</h6>
      <div class="input-conversation">
        <mat-select (selectionChange)="selectChannel($event.value)" #channel>
          <mat-option *ngFor="let channel of channels" [value]="channel">{{channel.name}}</mat-option>
        </mat-select>
      </div>

      <div class="wa-template" *ngIf="selectedChannel && selectedChannel.type === 'CLOUD_API'">
        <wa-template
          [channel]="selectedChannel"
          (onSelectTemplate)="onSelectTemplate($event)"
          ></wa-template>
      </div>
    </div>
  </div>

  <div [hidden]="step !== 4">
    <div class="w-14 h-14 flex cursor-pointer items-center justify-center rounded-full hover:bg-[#f2f2f3]"
      (click)="goToBack()">
      <mat-icon class="text-black">arrow_back</mat-icon>
    </div>
    <wa-template-message *ngIf="selectedTemplate && variables" 
      [contact]="selectedContact"
      [variables]="variables"
      [template]="selectedTemplate" 
      (templateParams)="setTemplateMessage($event)">
    </wa-template-message>

    <div class="flex justify-center mt-5 items-center w-full gap-2">
      <button #btnSendTemplate
        class="h-[46px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]"
        (click)="sendTemplateMessage()">{{ !loading ? 'Enviar' : '' }}
        <mat-spinner class="mr-4" [diameter]="25" [color]="'accent'" mode="indeterminate" *ngIf="loading"></mat-spinner>
      </button>
    </div>
  </div>
  }
</div>