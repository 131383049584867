import { DatePipe } from '@angular/common';
import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, ConfirmationComponent, Integration, IntegrationService, Pager } from 'lib-trend-core';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'openai-integrations-list-component',
  templateUrl: 'openai-integration-list.component.html',
  providers: [DatePipe]
})
export class OpenAIListComponent extends AbstractComponent implements OnInit {

  pager: Pager<Integration> = new Pager<Integration>({ perPage: 10 });
  listObservable: Observable<Pager<Integration>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  override searchString: string;
  processedList: Integration[] = [];


  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private integrationService: IntegrationService
  ) {
    super(injector);
    console.log('termOfSearch initialized:', this.termOfSearch);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.loading = true;
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    };
    this.integrationService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (pager: Pager<Integration>) => {
          this.pager = pager;
          this.processedList = pager.list;
          console.log('Processed list in component:', this.processedList);
          this.setupObservableSearch();
        },
        complete: () => (this.loading = false),
      });
  }


  setupObservableSearch() {
    this.listObservable = this.termOfSearch.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap((term) => {
        console.log('Received term in switchMap:', term);
        return this.integrationService.getAll(
          this.pager.page,
          this.pager.perPage,
          term,
          this.searchParams
        );
      })
    );

    this.listObservable.subscribe({
      next: (pager: Pager<Integration>) => {
        console.log('Processed list:', pager.list);
        this.pager = pager;
        this.processedList = pager.list.filter(item =>
          item.name.toLowerCase().includes(this.searchString.toLowerCase())
        );
      },
      error: (err) => {
        console.error('Error in listObservable:', err);
      },
    });

  }


  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    console.log('term:', term);
    this.termOfSearch.next(term);
    console.log('termOfSearch:', this.termOfSearch);
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  delete(webhookId: string) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (Boolean(result) === true) {
        this.integrationService.delete(webhookId).subscribe({
          next: () => {
            this.getList();
            this.alertService.success();
          },
          error: (err) => this.alertService.error(err.error.message),
        });
      }
    });
  }
}
