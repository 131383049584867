<div class="w-full">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>

  <h1 class="font-black text-[20px] text-black mt-2.5">Adicionar mensagem agendada</h1>
  <h6 class="text-zinc-400 font-light text-sm my-2">Preencha os dados e agende uma mensagem</h6>

  <section class="w-full flex flex-row flex-wrap mt-4">
    <input id="tab-one" type="radio" name="tabs" class="peer/tab-one opacity-0 absolute" checked />
    <div class="hidden peer-checked/tab-one:flex w-full items-center">
      <div class="flex flex-1 flex-col mt-4">
        <form [formGroup]="formGroup" (ngSubmit)="saveMessageTemplateScheduled()">
          <div class="textarea-wrapper">
            <div class="flex gap-2 mb-[15px]">
              <div class="flex flex-col w-[60%]">
                <label class="font-semibold text-black mt-[15px]">Data *</label>
                <div id="input-phone" class="input-field h-[46px] flex justify-between items-center w-full">
                  <input readonly [matDatepicker]="picker" formControlName="scheduledDay" [placeholder]="'Selecione uma data'"
                    class="outline-none border-none focus:ring-0 focus:border-transparent bg-transparent w-full"
                    (focus)="picker.open()" />
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </div>
                <field-error-component [field]="'scheduledDay'" [label]="'Data'" [formGroup]="formGroup">
                </field-error-component>
              </div>
        
              <div class="flex flex-col w-[60%]">
                <label class="font-semibold text-black mt-[15px]">Hora *</label>
                <input class="input-field" formControlName="scheduledHour" placeholder="ex: 08:00" mask="00:00">
                <field-error-component [field]="'scheduledHour'" [label]="'Hora'" [formGroup]="formGroup">
                </field-error-component>
              </div>
            </div>
        
            <div class="wa-template" *ngIf="!selectedTemplate">
              <wa-template 
                [channel]="data?.attendance?.channel" 
                (onSelectTemplate)="onSelectTemplate($event)">
              </wa-template>
            </div>
            <div *ngIf="selectedTemplate" class="p-2 w-[100%]">
              <button class="rounded-lg" style="padding: 0.5rem; border: 1px solid #bdbdbd"
              (click)="changeTemplate()">
              Trocar template
            </button>
              <wa-template-message  
              [variables]="variables"
              [contact]="selectedContact"
              [template]="selectedTemplate" 
              (templateParams)="setTemplateMessage($event)">
            </wa-template-message>
            </div>
            <div class="row mt-4">
              <div class="flex justify-center items-center w-full gap-2">
                <button  type="button"
                  class="h-[45px] px-14 flex justify-center items-center rounded-lg border-2 border-[#BDBDBD] text-gray-400 font-normal hover:bg-gray-100 w-[47%]"
                [mat-dialog-close]="" >Cancelar</button>
        
                <button type="submit" [disabled]="loading"
                  class="h-[45px] px-14 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none w-[47%]">
                  <ng-container *ngIf="!loading">Adicionar</ng-container>
                  <ng-container *ngIf="loading">
                    <mat-spinner [diameter]="25" [color]="'accent'" mode="indeterminate"></mat-spinner>
                  </ng-container>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</div>
