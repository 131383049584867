<div class="container-template" style="max-height: 430px; overflow-y: auto">
  <table class="card-attendance-msg">
    <thead class="text-align-left">
      <tr>
        <th class="header-name">Nome do modelo</th>
        <th class="header-preview">Prévia</th>
        <th class="header-category">Categoria</th>
        <th class="header-language">Idioma</th>
        <th class="header-action"></th>
      </tr>
    </thead>
    <tr *ngFor="let template of templates; let i = index">
      <td class="template-name">
        <div>{{ template.name }}</div>
      </td>
      <td class="template-preview">
        <!-- {{ template.bodyComponent?.text | slice: 0:50 }}... -->
        {{ getBodyText(template) | slice : 0 : 50 }}
      </td>
      <td class="template-category">
        <span *ngIf="template.category === 'MARKETING'">Marketing</span>
        <span *ngIf="template.category === 'UTILITY'">Utilitário</span>
        <span *ngIf="template.category === 'AUTHENTICATION'">Autenticação</span>
        <!-- <span *ngIf="template.category === 'SERVICE'">Serviço</span> -->
        <span
          *ngIf="
            template.category !== 'MARKETING' &&
            template.category !== 'UTILITY' &&
            template.category !== 'AUTHENTICATION'
          "
        >
          {{ template.category }}
        </span>
      </td>
      <td class="template-language">
        <ng-container [ngSwitch]="template.language">
          <ng-container *ngSwitchCase="'pt_BR'">
            <div class="language">
              <img width="15" src="../../../../assets/icons/br.svg" alt="" />
              <h6>Português</h6>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'en_US'">
            <div class="language">
              <img
                width="15"
                src="../../../../../../../assets/icons/us.svg"
                alt=""
              />
              <h6>Inglês</h6>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'pt_PR'">
            <div class="language">
              <img
                width="15"
                src="../../../../../../../assets/icons/pt.svg"
                alt=""
              />
              <h6>Portugal</h6>
            </div>
          </ng-container>
          <ng-container *ngSwitchDefault>
            {{ template.language }}
          </ng-container>
        </ng-container>
      </td>
      <td class="pl-4">
        <button
          class="pl-4 pr-4 p-2 flex justify-center items-center rounded-lg text-white font-normal bg-[#4213F6] font-[inherit] hover:bg-[#1340f6] focus:outline-none ml-4"
          (click)="showTemplateDetails(template)"
        >
          Selecionar
        </button>
      </td>
    </tr>
  </table>
</div>
