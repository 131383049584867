import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AbstractComponent, AttendanceService, Channel, ConfirmationComponent, Contact, ContactEditComponent, ContactService, Note, NoteService, Pager, Tag, TagService, CustomFieldContact, TypeFieldEnum, CustomFieldService } from 'lib-trend-core';
import { ContactViewNoteEditComponent } from './contact-view-note-edit/contact-view-note-edit.component';
import { debounceTime, distinctUntilChanged, Observable, Subject, switchMap } from 'rxjs';
import { T } from '@angular/cdk/keycodes';

@Component({
  selector: 'contact-view-component',
  templateUrl: './contact-view.component.html',
  styleUrl: 'contact-view.component.scss'
})
export class ContactViewComponent extends AbstractComponent implements OnInit {
  contact: Contact;
  disabledCustomInput: boolean = true;
  navType: string = 'notes';
  contactDetails: any; 
  tags: Array<Tag> = new Array<Tag>();
  showSelectTags: boolean = true;

  private totalAttendances;

  fields: Array<{ id: number; name: string; value: string }> = [];
  pager: Pager<Note> = new Pager<Note>({ perPage: 10 });
  listObservable: Observable<Pager<Note>>;
  private termoDaBusca: Subject<string> = new Subject<string>();

  customFields: Array<CustomFieldContact> = [];

  fieldOptions: string[] = [];

  allCustomFields: any[] = [];

  constructor(
    injector: Injector,
    public contactService: ContactService,
    public attendanceService: AttendanceService,
    public noteService: NoteService,
    public tagService: TagService,
    public customFieldService: CustomFieldService,
    public dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.loadCustomFields();
    this.loadFieldOptions();
    const idContact = super.getParam('id');
    if (!!idContact) {
      this.loadContactData(idContact);
    }
    this.tagService.getList().subscribe(tag => this.tags = tag);
    this.getPagerNote()
  }

  setupObservableSearch() {
    this.listObservable = this.termoDaBusca
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.noteService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Note>) => {
      this.pager = pager;
    });
  }
  
  getPagerNote(): void {
    console.log('Antes do carregamento:', this.loadingContent);
    this.loadingContent = true;
    console.log('Depois de iniciar o carregamento:', this.loadingContent);
    
    const idContact = super.getParam('id');
    if (!!idContact) {
      this.searchParams = { contact: idContact };
    }
  
    this.noteService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Note>) => {
        this.pager = pager;
        this.setupObservableSearch();
        console.log('Carregamento concluído. Atualizando loadingContent:', this.loadingContent);
        this.loadingContent = false;
        console.log('Depois de finalizar o carregamento:', this.loadingContent);
      },
      error: () => this.alertService.error('Ops! Não foi possível buscar os canais. Tente novamente mais tarde.'),
    });
  }
  
  
  
  loadPage(page: number) {
    this.pager.page = page;
    this.getPagerNote();
  }

  loadContactData(idContact: string) {
    this.contactService.getById(idContact).subscribe({
      next: (contact: Contact) => {
        this.contact = contact;
        this.customFields = contact.customFields || []; 
        this.loadContactDetails();
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  loadContactDetails() {
    if (this.contact?._id) {
      this.attendanceService.getContactDetailsWithCounts(this.contact._id).subscribe({
        next: (details) => {
          this.contactDetails = details;
        },
        error: (err) => this.alertService.error(err.error.message)
      });
    }
  }

  getGenderDisplay(gender: string): string {
    const genderMap = {
      'MALE': 'Masculino',
      'FEMALE': 'Feminino',
      'OTHER': 'Outro',
    };
    return genderMap[gender] || gender;
  }

  formatTin(tin: string): string {
    if (!tin) return '-';
    return tin.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  getGroupsDisplay(groups: any[]): string {
    if (!groups || groups.length === 0) return '-';
    return groups.map(group => group.name || group).join(', ');
  }

  getTagsDisplay(tags: any[]): string {
    if (!tags || tags.length === 0) return '-';
    return tags.map(tag => tag.title || tag).join(', ');
  }

  loadCustomFields() {
    this.customFieldService.getList().subscribe(fields => {
      this.fieldOptions = fields.map(field => field.name);
      this.allCustomFields = fields;
    });
  }

  loadFieldOptions() {
    this.customFieldService.getList().subscribe(fields => {
      this.fieldOptions = fields.map(field => field.name);
    });
  }

  //GETTERS

  get notes(): any[] {
    if (!this.contactDetails?.[0]?.notes) {
      return [];
    }
    return this.contactDetails[0].notes;
  }

  get contactTags(): any[] {
    if (!this.contactDetails?.[0]?.tags) {
      return [];
    }
    return this.contactDetails[0].tags;
  }

  get totalNotes(): number {
    return this.contactDetails?.[0]?.totalNotes || 0;
  }

  get totalTags(): number {
    return this.contactDetails?.[0]?.totalTags || 0;
  }

  get countAttendance(): number {
    return this.contactDetails?.[0]?.countAttendance || 0;
  }

  get availableTags(): Tag[] {
    return this.tags ?? [];
  }

  get selectedTags(): Tag[] {
    return this.contactDetails?.[0]?.tags || [];
  }

  trackByTagId(index: number, tag: Tag): string {
    return tag._id;  
  }

  changeNavType(type: string) {
    this.navType = type;
  }

  removeTag(tagToRemove: Tag) {
    if (this.contact && this.contact.tags) {
      const updatedTags = this.contact.tags.filter(tag => tag._id !== tagToRemove._id);
      
      const updatedContact = {
        ...this.contact,
        tags: updatedTags
      };

      this.contactService.update(this.contact._id, updatedContact).subscribe({
        next: (response) => {
          this.contact.tags = updatedTags; 
          this.alertService.success('Tag removida com sucesso');
          this.loadContactDetails(); 
        },
        error: (err) => {
          this.alertService.error('Erro ao remover tag');
          this.loadContactDetails(); 
        }
      });
    }
  }

  isTagSelected(tagId: string): boolean {
    return this.contact?.tags?.some(tag => tag._id === tagId) || false;
  }

  selected(event: MatSelectChange): void {
    if (this.contact && event.value) {
      const tagExists = this.contact.tags?.some(tag => tag._id === event.value._id);
      
      if (!tagExists) {
        this.contact.tags = this.contact.tags || [];
        this.contact.tags.push(event.value);
        
        this.contactService.update(this.contact._id, this.contact).subscribe({
          next: (updatedContact) => {
            this.alertService.success('Tag adicionada com sucesso');
            this.loadContactDetails(); 
          },
          error: (err) => {
            this.alertService.error('Erro ao adicionar tag');
            this.contact.tags = this.contact.tags.filter(tag => tag._id !== event.value._id);
          }
        });
      }
    }
    this.showSelectTags = true;
  }
  
  getChannelContact(contactId: string): string {
    if (this.totalAttendances && this.totalAttendances.length > 0) {
      const channel = this.totalAttendances
        .filter((value: { _id: string, count: number, channel: Channel }) => value && value._id === contactId)
        .map((value: { _id: string, count: number, channel: Channel }) => value.channel)
        .reduce((channelAcc, channelCurrent) => channelCurrent || channelAcc, null);

      return (channel && channel.name) && (channel.removed !== true) ? channel.name : '-';
    }
    return '-';
  }

  addField(): void {
    const newField = {
      id: this.fields.length + 1,
      name: '',
      value: '',
    };
    this.fields.push(newField);
  }

  removeField(id: number): void {
    this.fields = this.fields.filter(field => field.id !== id);
  }

  addCustomField() {
    const newFieldName = 'Novo Campo';
    const exists = this.customFields.some(field => field.name === newFieldName);
    if (!exists) {
      this.customFieldService.getList().subscribe(fields => {
        const availableField = fields.find(field => !this.customFields.some(cf => cf._id === field._id));
        if (availableField) {
          const newField: CustomFieldContact = { 
            _id: availableField._id,
            name: '',
            description: availableField.description || '', 
            type: availableField.type || TypeFieldEnum.TEXT, 
            value: null 
          };
          this.customFields.push(newField);
        } else {
          this.alertService.error('Não há mais campos personalizados disponíveis.');
        }
      });
    } else {
      this.alertService.error('Campo personalizado já existe.');
    }
  }

  removeCustomField(index: number) {
    this.customFields.splice(index, 1);
  }

  isFieldSelected(fieldName: string, currentIndex: number): boolean {
    return this.customFields.some((field, index) => 
      index !== currentIndex && field.name === fieldName
    );
  }

  onCustomFieldChange(index: number, fieldName: string) {
    const field = this.customFields[index];
    if (!fieldName) {
      this.alertService.error('O nome do campo é obrigatório');
      return;
    }
    
    const selectedField = this.allCustomFields.find(f => f.name === fieldName);
    if (selectedField) {
      field._id = selectedField._id;
      field.name = fieldName;
      field.type = selectedField.type;
      field.description = selectedField.description;
    }
  }

  saveCustomFields() {
    const missingFields = this.customFields.filter(field => !field.name || !field.value);
  
    if (missingFields.length > 0) {
      this.alertService.warning('Por favor, preencha todos os campos obrigatórios.');
      return; 
    }
  
    const updatedContact = { ...this.contact, customFields: this.customFields };
    this.contactService.update(this.contact._id, updatedContact).subscribe({
      next: () => {
        this.alertService.success('Campos personalizados salvos com sucesso!');
        this.loadContactData(this.contact._id);
      },
      error: (err) => {
        this.alertService.error('Erro ao salvar campos personalizados.');
      }
    });
  }
  
  
  
  edit(item: Contact): void {
    const dialogRef = this.dialog.open(ContactEditComponent, {
      width: '700px',
      data: item
    });

    dialogRef.afterClosed().subscribe(() => {
      if (this.contact?._id) {
        this.loadContactData(this.contact._id);
      }
    });
  }

  openNoteDialog(note?: Note) {
    const dialogRef = this.dialog.open(ContactViewNoteEditComponent, {
      width: '600px',
      data: {
        note: note,
        contact: this.contact,
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.loadContactDetails();
        this.getPagerNote()  
      }
    });
  }

  delete(item: Contact): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.contactService.delete(item._id).subscribe({
          next: () => {
            this.alertService.success('Contato excluído com sucesso.');
          },
          error: err => this.alertService.error(err.error.message)
        });
      }
    });
  }

  deleteNote(note: Note): void{
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.noteService.delete(note._id).subscribe({
          next: () => {
            this.alertService.success('Nota excluída com sucesso.');
            this.getPagerNote()
          },
          error: err => this.alertService.error(err.error.message)
        });
      }
    });
  }

  openNewConversation() {
    this.router.navigate(['/attendance/panel'], {
      queryParams: { contactId: this.contact._id }
    });
  }

  openAttendanceNote(attendanceId: string, status: string) {
    if (attendanceId && status) {
      this.router.navigate(['/attendance/panel'], {
        queryParams: { 
          id: attendanceId,
          status: status
        }
      });
    }
  }
}