"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateMessageService = void 0;
const tslib_1 = require("tslib");
const message_1 = require("../models/cloud-api/message");
const base_service_1 = require("./base.service");
class TemplateMessageService extends base_service_1.RestService {
    constructor() {
        super();
    }
    /**
     * Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#text-based
     *
     * @deprecated
     * @param message
     * @returns
     */
    sendTextTemplateMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            const parameters = message.params.map((param) => {
                return {
                    type: "text",
                    text: param
                };
            });
            const json = {
                messaging_product: "whatsapp",
                recipient_type: "individual",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.template,
                template: {
                    name: message.template,
                    language: {
                        code: message.language || "en_US"
                    }
                }
            };
            if (!!parameters && parameters.length > 0) {
                const components = [
                    {
                        "type": "body",
                        "parameters": parameters
                    }
                ];
                if (json.template) {
                    json.template.components = components;
                }
            }
            return yield _super.post.call(this, url, json, config);
        });
    }
    /**
     * Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#media-based
     *
     * @deprecated
     * @param message
     * @returns
     */
    sendMediaTemplateMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g;
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            let headerComponent = {};
            let bodyComponent = {};
            const components = new Array();
            if ((!!message.link || (!!message.headerParams && message.headerParams.length > 0)) && !!message.format) {
                const type = message.format;
                const jsonHeaderParam = { type };
                if (message.format === 'text') {
                    jsonHeaderParam[type] = (_a = message === null || message === void 0 ? void 0 : message.link) !== null && _a !== void 0 ? _a : ((_b = message === null || message === void 0 ? void 0 : message.headerParams) === null || _b === void 0 ? void 0 : _b[0]);
                }
                else {
                    jsonHeaderParam[type] = {
                        link: (_c = message === null || message === void 0 ? void 0 : message.link) !== null && _c !== void 0 ? _c : ((_d = message === null || message === void 0 ? void 0 : message.headerParams) === null || _d === void 0 ? void 0 : _d[0]),
                    };
                }
                headerComponent = {
                    type: "header",
                    parameters: [jsonHeaderParam]
                };
                components.push(headerComponent);
            }
            if ((!!(message === null || message === void 0 ? void 0 : message.params) && ((_e = message === null || message === void 0 ? void 0 : message.params) === null || _e === void 0 ? void 0 : _e.length) > 0) || (!!message.bodyParams && message.bodyParams.length > 0)) {
                const parameters = (_g = ((_f = message === null || message === void 0 ? void 0 : message.params) !== null && _f !== void 0 ? _f : message === null || message === void 0 ? void 0 : message.bodyParams)) === null || _g === void 0 ? void 0 : _g.map((param) => {
                    return {
                        type: "text",
                        text: param
                    };
                });
                bodyComponent = {
                    type: "body",
                    parameters: parameters
                };
                components.push(bodyComponent);
            }
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.template,
                template: {
                    name: message.template,
                    language: {
                        code: message.language || "en_US"
                    },
                    components
                }
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
    /**
     *
     * Reference: https://developers.facebook.com/docs/whatsapp/cloud-api/guides/send-message-templates#media-based
     *
     * @param message
     * @returns
     */
    sendTemplateMessage(message) {
        const _super = Object.create(null, {
            post: { get: () => super.post }
        });
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const config = this.getHeaderConfig(message.token);
            const url = this.getCloudAPIURL(message.phoneNumberID, 'messages');
            let headerComponent = {};
            let bodyComponent = {};
            const components = new Array();
            if ((!!message.headerParams && message.headerParams.length > 0) && !!message.format) {
                const type = message.format;
                const jsonHeaderParam = { type };
                if (message.format === 'text') {
                    jsonHeaderParam[type] = (message.headerParams[0]);
                }
                else {
                    jsonHeaderParam[type] = {
                        link: (message.headerParams[0]),
                    };
                }
                headerComponent = {
                    type: "header",
                    parameters: [jsonHeaderParam]
                };
                components.push(headerComponent);
            }
            if (!!message.bodyParams && message.bodyParams.length > 0) {
                const parameters = message.bodyParams.map((param) => {
                    return {
                        type: "text",
                        text: param
                    };
                });
                bodyComponent = {
                    type: "body",
                    parameters: parameters
                };
                components.push(bodyComponent);
            }
            const json = {
                messaging_product: "whatsapp",
                to: message.to,
                type: message_1.TypeMessageObjectEnum.template,
                template: {
                    name: message.template,
                    language: {
                        code: message.language || "en_US"
                    },
                    components
                }
            };
            return yield _super.post.call(this, url, json, config);
        });
    }
}
exports.TemplateMessageService = TemplateMessageService;
