import { NgModule } from '@angular/core';
import { SharedModule } from 'lib-trend-core';
import { ContactGroupModule } from '../contact-group/contact-group.module';
import { ContactComponent } from './contact.component';
import { CardComponentModule } from "lib-trend-core";
import { ContactRoutingModule } from './contact-routing.module';
import { ContactViewComponent } from './contact-view/contact-view.component';
import { ContactViewAttendanceListComponent } from './contact-view/contact-view-attendance-list/contact-view-attendance-list.component';
import { ContactViewNoteEditComponent } from './contact-view/contact-view-note-edit/contact-view-note-edit.component';

@NgModule({
  imports: [
    SharedModule,
    ContactGroupModule,
    CardComponentModule,
    ContactRoutingModule
],
  declarations: [
    ContactComponent,
    ContactViewComponent,
    ContactViewAttendanceListComponent,
    ContactViewNoteEditComponent,
  ],
  exports: [
    ContactComponent,
    ContactViewComponent,
    ContactViewAttendanceListComponent,
    ContactViewNoteEditComponent
  ]
})
export class ContactModule { }
