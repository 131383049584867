
import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'lib-trend-core';
import { WaTemplateModule } from '../wa-template/wa-template.module';
import { ChatMessageReactionsComponent } from './chat-messages/chat-message-reactions/chat-message-reactions.component';
import { ChatMessageReplyComponent } from './chat-messages/chat-message-reply/chat-message-reply.component';
import { ChatMessageComponent } from './chat-messages/chat-messages.component';
import { ChatSelectTemplateComponent } from './chat-select-template/chat-select-template.component';
import { ChatMessageScheduleComponent } from './chat-messages/chat-message-schedule/chat-message-schedule';
import { ChatMessageScheduleListComponent } from './chat-messages/chat-message-schedule/chat-message-schedule-list/chat-message-schedule-list';
import { ChatMessageContactComponent } from './chat-messages/chat-message-contact/chat-message-contact.component';
import { MenuActionsModule } from '../menu-actions/menu-actions.module';
import { ChatMessageLocationComponent } from './chat-messages/chat-message-location/chat-message-location.component';
import { ChatTemplateScheduleComponent } from './chat-messages/chat-template-schedule/chat-template-schedule.component';

@NgModule({
  declarations: [
    ChatMessageComponent,
    ChatSelectTemplateComponent,
    ChatMessageReactionsComponent,
    ChatMessageReplyComponent,
    ChatMessageScheduleComponent,
    ChatTemplateScheduleComponent,
    ChatMessageScheduleListComponent,
    ChatMessageContactComponent,
    ChatMessageLocationComponent
  ],
  exports: [
    ChatMessageComponent,
    ChatSelectTemplateComponent,
    ChatMessageReactionsComponent,
    ChatMessageReplyComponent,
  ],
  imports: [
    SharedModule,
    WaTemplateModule,
    PickerModule,
    MenuActionsModule
  ],
  providers: [],
})
export class ChatModule { }
