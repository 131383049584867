import { NgModule } from '@angular/core';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SharedModule } from 'lib-trend-core';
import { MenuActionsComponent } from './menu-actions.component';

@NgModule({
  imports: [
    SharedModule,
    PickerModule
  ],
  declarations: [
    MenuActionsComponent,
  ],
  exports: [
    MenuActionsComponent,
  ],
  providers: [

  ]
})

export class MenuActionsModule { }
