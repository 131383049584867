<div class="flex justify-between">
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="48" height="48" rx="24" fill="#D1FADF" />
    <rect x="4" y="4" width="48" height="48" rx="24" stroke="#ECFDF3" stroke-width="8" />
    <path d="M35 29H29V35H27V29H21V27H27V21H29V27H35V29Z" fill="#039855" />
  </svg>
  <div class="w-14 h-14 flex cursor-pointer items-center justify-center rounded-full hover:bg-[#f2f2f3]"
    (click)="closeComponentTag()">
    <mat-icon class="text-black">clear</mat-icon>
  </div>
</div>


<h1 class="font-black text-[20px] text-black mt-2.5">Adicionar ou remover uma etiqueta</h1>
<h6 class="text-zinc-400 font-light text-sm my-2">Selecione um etiqueta para adicionar ou remova alguma.</h6>

<form class="tag-main">
  <h4 class="label-attendance mb-1">Etiquetas</h4>
  <div class="main-tag-selector">
    @if (showSelectTags) {
    <button class="button-add" (click)="openSelectTags()">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8337 6.83268H6.83366V11.8327H5.16699V6.83268H0.166992V5.16602H5.16699V0.166016H6.83366V5.16602H11.8337V6.83268Z"
          fill="#4213F6" />
      </svg>
    </button>
    } @else {
    <mat-select class="input-field" (selectionChange)="selected($event)" placeholder="Selecione">
      @for (item of tags; track $index) {
      <mat-option [value]="item">{{ item.title }}</mat-option>
      }
    </mat-select>
    }
  </div>
  <div class="chip-list">
    @for (item of getFilteredList(); track item) {
    <mat-chip-row [ngStyle]="{'background-color': item.bgColor}" (removed)="remove(item)" [editable]="false">
      <span [ngStyle]="{'color': item.fontColor}">
        {{item.title}}
      </span>
      <button matChipRemove [attr.aria-label]="'Remover ' + item.title">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    }
  </div>
</form>
