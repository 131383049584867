import { state, style, transition, trigger } from '@angular/animations';
import { Component, Injector, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, Attendance, User } from 'lib-trend-core';
import { Observable } from 'rxjs';
import { AppState, PagerParamsState } from '../state/app.state';
import { attendanceSelector, openCloseSidebarRightSelector } from '../state/selectors';

@Component({
  selector: 'attendance-panel-info-component',
  templateUrl: './attendance-panel-info.component.html',
  styleUrls: ['./attendance-panel-info.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateX(0)',
        display: 'block',
      })),
      state('out', style({
        transform: 'translateX(100%)',
        display: 'none'
      })),
      transition('in => out', []),
      transition('out => in', [])
    ])
  ]
})
export class AttendancePanelInfoComponent extends AbstractComponent implements OnInit {

  sidebarState: string = 'out';

  openCloseSidebarRight$: Observable<boolean> = this.store.select(openCloseSidebarRightSelector);
  readonly attendance$: Observable<Attendance> = this.store.select(attendanceSelector);

  attendance: Attendance;
  pagerParamsState: PagerParamsState;
  selectedUser: User;

  @ViewChild('transferModal') transferModal: TemplateRef<any>;
  dialogRef: MatDialogRef<any>;

  constructor(
    injector: Injector,
    private store: Store<AppState>,
  ) {
    super(injector);
  }

  ngOnInit(): void {
    this.openCloseSidebarRight$.subscribe(isOpen => {
      this.sidebarState = isOpen ? 'in' : 'out';
    });

    this.attendance$.subscribe(attendance => {
      this.attendance = attendance;
    });
  }
}
