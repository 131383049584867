import { Component, Injector, OnInit } from '@angular/core';
import { AssistantListRequest, AssistantObject, TrendOpenAIAPIService } from '@trendbuild/trend-openai-api';
import { AbstractComponent, Channel, ChannelService, Integration, IntegrationService } from 'lib-trend-core';
import { forkJoin, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AssistantCreateComponent } from './assistant-create/assistant-create.component';
import { id } from '@swimlane/ngx-charts';

interface AssistantWithSelectedChannel {
  assistant: AssistantObject;
  selectedChannel?: string[];  // Modificado para string[] (IDs dos canais)
  connectedChannels: Channel[];
}

@Component({
  selector: 'app-openai-integration-assistants',
  templateUrl: './openai-integration-assistants.component.html',
  styleUrls: ['./openai-integration-assistants.component.scss']
})
export class OpenAIAssistantsComponent extends AbstractComponent implements OnInit {

  private trendOpenAIAPIService: TrendOpenAIAPIService = new TrendOpenAIAPIService();

  integration: Integration;
  assistants: AssistantWithSelectedChannel[] = [];
  channels: Channel[] = [];
  selectedChannel: Channel;

  constructor(
    injector: Injector,
    private integrationService: IntegrationService,
    private channelService: ChannelService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }

  ngOnInit() {
    this.isNew = true;
    const id = super.getParam('id');
    if (!!id) {
      this.isNew = false;
      this.getIntegrationById(id);
    }
  }

  getIntegrationById(idIntegration: string) {
    this.integrationService.getById(idIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (integration: Integration) => {
          this.integration = integration;
          this.loadData();
        },
        error: (err) => this.alertService.error(err.error.message)
      });
  }

  loadData() {
    this.loading = true;

    const assistantListRequest: AssistantListRequest = {
      apiKey: this.integration.metadata['token'],
      organization: this.integration.metadata['organization'],
      project: this.integration.metadata['project'],
    };

    forkJoin({
      assistants: this.trendOpenAIAPIService.listAssistants(assistantListRequest),
      channels: this.channelService.getList()
    }).subscribe({
      next: ({ assistants, channels }) => {
        this.channels = channels;
        const assistantsMetadata = this.integration.metadata?.['assistants'] || [];

        this.assistants = assistants.data.map(assistant => {
          const assistantMeta = assistantsMetadata.find((a: any) => a.assistantId === assistant.id);
          const connectedChannels = assistantMeta?.channels?.map(channel => channels.find(c => c._id === channel._id));
          return {
            assistant,
            selectedChannel: connectedChannels?.map(channel => channel._id) ?? [],  // Apenas IDs de canais
            connectedChannels
          };
        });

      },
      error: (error) => this.alertService.error(error.error.message),
      complete: () => this.loading = false
    });
  }

  toggleAssistant(assistantId: string) {
    const assistant = this.assistants.find(a =>
      a.assistant.id === assistantId);
    if (!assistant) {
      this.alertService.error('Assistente não encontrado.');
      return;
    }

    const selectedChannels = assistant.selectedChannel || [];


    assistant.connectedChannels = this.channels.filter(channel =>
      selectedChannels.includes(channel._id)
    );

    const existingAssistants = this.integration.metadata['assistants'] || [];

    const existingAssistantIndex = existingAssistants.findIndex(
      (a: any) => a.assistantId === assistantId
    );

    const mountChannels = () => {
      return selectedChannels.map(idChannel => {
        const channel = this.channels.find(c => c._id === idChannel);
        return { _id: channel._id, name: channel.name, type: channel.type };
      });
    }

    let updatedAssistants;
    if (existingAssistantIndex >= 0) {
      updatedAssistants = [...existingAssistants];
      updatedAssistants[existingAssistantIndex] = {
        ...updatedAssistants[existingAssistantIndex],
        channels: mountChannels()
      };
    } else {
      updatedAssistants = [
        ...existingAssistants,
        {
          assistantId: assistantId,
          channels: mountChannels()
        }
      ];
    }

    const updatedMetadata = {
      ...this.integration.metadata,
      assistants: updatedAssistants,
    };

    const updatedIntegration = {
      ...this.integration,
      metadata: updatedMetadata,
    };

    this.integrationService.update(this.integration._id, updatedIntegration)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.alertService.success('Canais atualizados com sucesso!');
          // Atualiza a lista de canais conectados
          const atualizaco = assistant.connectedChannels = this.channels.filter(channel =>
            selectedChannels.includes(channel._id)
          );
          console.log('canal atualizado:', atualizaco)
        },
        error: () => {
          this.alertService.error('Erro ao atualizar canais.');
          // Em caso de erro, reverte as alterações
          this.loadData();
        },
      });
  }



  openCreateAssistantModal() {
    const dialogRef = this.dialog.open(AssistantCreateComponent, {
      width: '600px',
      data: {
        integration: this.integration
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.loadData();
    });
  }

  openEditAssistantModal(assistant: AssistantWithSelectedChannel) {
    const dialogRef = this.dialog.open(AssistantCreateComponent, {
      width: '600px',
      data: {
        integration: this.integration,
        assistant: assistant.assistant
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.loadData();
    });
  }

  deleteAssistant(assistant: AssistantWithSelectedChannel) {
    const dialogRef = this.dialog.open(AssistantCreateComponent, {
      width: '600px',
      data: {
        integration: this.integration,
        assistant: assistant.assistant,
        mode: 'delete'
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) this.loadData();
    });
  }

}
