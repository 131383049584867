import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { AbstractComponent, ContactService, NewConversationService } from 'lib-trend-core';
import { clearFullState } from './state/actions';
import { AppState } from './state/app.state';
import { Effects } from './state/effects';
import { ContactNewConversationComponent } from './attendance-panel-components/new-conversation/new-conversation.component';
import { takeUntil, combineLatest } from 'rxjs';

@Component({
  selector: 'attendance-panel-component',
  templateUrl: 'attendance-panel.component.html'
})
export class AttendancePanelComponent extends AbstractComponent implements OnInit, OnDestroy {
  private modalRef: MatDialogRef<ContactNewConversationComponent>


  constructor(
    public injector: Injector,
    private store: Store<AppState>,
    private effects: Effects,
    private newConversationService: NewConversationService,
    private dialog: MatDialog,
  ) {
    super(injector);
  }


  ngOnInit(): void {
    this.store.dispatch(clearFullState({ clear: true }));
    
    const contactId = this.route.snapshot.queryParams['contactId'];
    if (contactId) {
      setTimeout(() => {
        this.openNewConversationModal(contactId);
      }, 100);
      return;
    }
    
    combineLatest([
      this.newConversationService.showModal$,
      this.newConversationService.contactId$
    ])
    .pipe(takeUntil(this.destroy$))
    .subscribe(([show, contactId]) => {
      if (show && contactId && !this.modalRef) {
        this.openNewConversationModal(contactId);
      } else if (!show && this.modalRef) {
        this.modalRef.close();
        this.modalRef = null;
      }
    });
  }

  openNewConversationModal(contactId: string) {
    if (this.modalRef) {
      return;
    }

    this.modalRef = this.dialog.open(ContactNewConversationComponent, {
      width: '1000px',
      data: {
        contactId: contactId,
        initialStep: 2
      }
    });

    this.modalRef.afterClosed().subscribe(() => {
      this.newConversationService.closeModal();
      this.modalRef = null;
    });
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.effects.destroyEffects();
    if (this.modalRef) {
      this.modalRef.close();
    }
  }
}
