import { ChangeDetectorRef, Component, ElementRef, Inject, Injector, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AbstractComponent, Attendance, AudioService, Channel, Company, Contact, FirebaseService, MessageScheduledService, MessageScheduledStatusEnum, MessageTypeEnum, Traduct, UploadTypeEnum, User } from 'lib-trend-core';
import { MessageScheduled } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'message-schedule',
  templateUrl: './chat-message-schedule.html',
  styleUrls: ['./chat-message-schedule.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatMessageScheduleComponent extends AbstractComponent {
  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();
  file!: File;
  form: FormGroup;
  private message: MessageScheduled;
  contentType: string = ''
  attachment: any;
  intervalId!: any;

  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;
  @ViewChild('audioContainer', { static: false }) audioContainer!: ElementRef;

  showContainerEmoji: boolean = false;
  translator!: Traduct;

  //AUDIO
  isRecording!: boolean;
  attendance: Attendance;
  isLoading: boolean = false;
  audioUrl!: string | null;
  isPreview: boolean = false;
  recorder!: MediaRecorder;
  stream!: MediaStream;
  recordingTime: number = 0;
  isCancel!: boolean;
  audioChunks: Blob[] = [];
  audioFile!: File | undefined;
  isRecordingInProgress: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    injector: Injector,
    @Inject(MAT_DIALOG_DATA) public data: { attendance?: Attendance; message?: MessageScheduled },
    private fb: FormBuilder,
    private messageScgeduledService: MessageScheduledService,
    private firebaseService: FirebaseService,
    private audioService: AudioService,
    public _matDialogRef: MatDialogRef<MessageScheduled>,
  ) {
    super(injector);
    this.message = data.message
    this.form = this.fb.group({
      scheduledDay: ['', Validators.required],
      scheduledHour: ['', Validators.required],
      message: ['',],
      attachment: [null],
    });
  }

  ngOnInit(): void {
    this.isNew = !this.data?.message;

    if (!this.isNew) {
      const { scheduledDate, scheduledTime, caption, content, contentType } = this.data.message;

      this.form.patchValue({
        scheduledDay: scheduledDate,
        scheduledHour: scheduledTime,
        message: caption || content,
      });

      this.attachment = content;
      this.contentType = contentType;
  
      if (caption === "" || (contentType === "audio/mpeg" && caption === null))  {
        this.form.patchValue({ message: "" });
      }
    }
  }

  saveMessageScheduled(): void {
    if (this.isRecordingInProgress) {
      return;
    }

    if (this.form.invalid) {
      this.alertService.warning('Por favor, preencha todos os campos obrigatórios.');
      return;
    }
    const scheduledDay = this.form.get('scheduledDay').value;
    let scheduledHour = this.form.get('scheduledHour').value?.replace(/^(\d{2})(\d{2})$/, '$1:$2');

    if (!scheduledDay || !/^\d{2}:\d{2}$/.test(scheduledHour)) {
      this.alertService.error('Data e hora agendadas são obrigatórias e devem estar no formato HH:mm.');
      return;
    }

    const [hour, minute] = scheduledHour.split(':').map(Number);
    const scheduledDateTime = new Date(scheduledDay);
    scheduledDateTime.setHours(hour, minute, 0, 0);

    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + 2);

    if (scheduledDateTime < currentTime) {
      this.alertService.error('A data e hora agendadas devem ser pelo menos 2 minutos acima da hora atual.');
      return;
    }

    const message = this.form.get('message')?.value;

    let contentType = this.file ? this.file.type : this.contentType || MessageTypeEnum.text;
    let messageType;

    if (contentType.includes('application/pdf')) {
      messageType = MessageTypeEnum.document;
    } else if (contentType.includes('image/')) {
      messageType = MessageTypeEnum.image;
    } else if (contentType.includes('audio/')) {
      messageType = MessageTypeEnum.audio;
    } else if (contentType.includes('video/')) {
      messageType = MessageTypeEnum.video;
    } else {
      messageType = MessageTypeEnum.text;
    }

    if (messageType === MessageTypeEnum.text && !message?.trim()) {
      this.alertService.warning('É necessário preencher a mensagem antes de salvar.');
      return;
    }

    const caption = (messageType !== MessageTypeEnum.audio && this.attachment) ? message : null;

    const messageScheduled = <MessageScheduled>{
      content: this.attachment || message,
      caption: caption,
      contact: this.isNew
        ? { _id: this.data.attendance.contact._id }
        : { _id: this.message.contact._id } as Contact,
      channel: this.isNew
        ? { _id: this.data.attendance.channel._id }
        : { _id: this.message.channel._id } as Channel,
      company: { _id: super.getIDCurrentCompany() } as Company,
      attendance: { _id: this.data.attendance._id } as Attendance,
      user: { _id: super.getIDCurrentUser() } as User,
      type: messageType,
      contentType,
      removed: false,
      scheduledDate: scheduledDay,
      scheduledTime: scheduledHour,
      status: MessageScheduledStatusEnum.WAITING,
    };

    const saveObservable = this.isNew
      ? this.messageScgeduledService.create(messageScheduled)
      : this.messageScgeduledService.update(this.message._id, messageScheduled);

    saveObservable.subscribe({
      next: () => {
        const successMessage = this.isNew
          ? 'Mensagem agendada criada com sucesso'
          : 'Mensagem agendada atualizada com sucesso';
        this.alertService.success(successMessage);
        this._matDialogRef.close();
      },
      error: () => {
        const errorMessage = this.isNew
          ? 'Erro ao agendar a mensagem'
          : 'Erro ao atualizar a mensagem agendada';
        this.alertService.error(errorMessage);
      },
    });
  }
  
  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        console.log('Formato desconhecido');
        return;
    }

    textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
  }

  isMessageValid(): boolean {
    const messageContent = this.form.get('message')?.value?.trim();
    const attachment = this.form.get('attachment')?.value?.trim();

    if (!this.isNew && !messageContent || !attachment) {
      return false;
    }

    return !(messageContent || attachment || this.message?.content);
  }

  removeAttachment(): void {
    if (this.attachment) {
      this.attachment = null;
      this.file = null;
      this.contentType = 'text';
      this.form.patchValue({ attachment: null });

      this.alertService.success('Arquivo removido com sucesso.');
    } else {
      this.alertService.warning('Nenhum arquivo para remover.');
    }
  }

  onMidiaChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;
  
    const file = input.files[0];
    if (file) {
      this.file = file;
      const mimeType = file.type;
  
      const reader = new FileReader();
      reader.onload = async () => {
        try {
          this.loadingSpinnerSubject.next(true);
          const filename = `${file.name}_${new Date().getTime()}`;
  
          const snapshot = await this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE);
          this.loadingSpinnerSubject.next(false);
  
          this.attachment = snapshot.url;
          this.contentType = mimeType;
  
          this.form.patchValue({
            attachment: snapshot.url,
          });
        } catch (error) {
          this.loadingSpinnerSubject.next(false);
          console.error('Erro no upload do arquivo:', error);
        }
      };
      reader.readAsArrayBuffer(file);
    }
  }
  
  //AUDIO
  private startRecording(idAttendance: string): void {
    this.isRecordingInProgress = true;
    if (this.isRecording) return;

    this.recordingTime = 0;
    this.intervalId = setInterval(() => {
      this.recordingTime++;
    }, 1000);

    sessionStorage.removeItem('preview_audio');

    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.isRecording = true;
        this.isCancel = false;
        this.stream = stream;
        this.recorder = new MediaRecorder(this.stream);
        this.audioChunks = [];

        this.recorder.ondataavailable = (event) => {
          if (!this.isCancel) {
            this.audioChunks.push(event.data);
          }
        };

        this.recorder.onstop = async () => {
          if (!this.isCancel) {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            const filename = `audio.mp3`;
            const newBlob = await this.audioService.convertWebMToMp3(audioBlob);
            this.audioFile = new File([newBlob], filename, { type: 'audio/mpeg' });

            this.audioUrl = URL.createObjectURL(audioBlob);
            if (this.isPreview) {
              const reader = new FileReader();
              reader.onload = () => {
                sessionStorage.setItem(`preview_audio_${idAttendance}`, reader.result as string);
              };
              reader.onerror = (error) => {
                this.alertService.error('Ops! Ocorreu um erro ao tentar ler o seu áudio. Tente novamente mais tarde.');
              };
              reader.readAsDataURL(this.audioFile);

              this.isPreview = false;
            }
          } else {
            this.audioChunks = [];
          }
        };

        this.recorder.start();
      }).catch((err) => this.alertService.error(err));
  }

  private async processFile(file: File): Promise<void> {
    try {
      this.isLoading = true;  
  
      const filename = `${file.name}_${new Date().getTime()}`;
      const mimeType = file.type;
  
      const snapshot = await this.firebaseService.uploadFile(file, filename, UploadTypeEnum.MESSAGE);
  
      this.attachment = snapshot.url; 
      this.contentType = mimeType;
  
      this.form.patchValue({
        attachment: snapshot.url,
      });
    } catch (error) {
      this.isLoading = false;
      console.error('Erro ao fazer o upload do arquivo:', error);
      this.alertService.error('Ops! Ocorreu um erro ao fazer o upload do arquivo. Tente novamente mais tarde.');
    } finally {
      this.isLoading = false;  
    }
  }
  
  stopRecording(): void {
    this.isRecordingInProgress = false;
    if (this.isRecording) {
      clearInterval(this.intervalId);
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());

      this.recorder.onstop = async () => {
        if (!this.isCancel) {
          try {
            const audioBlob = new Blob(this.audioChunks, { type: 'audio/webm' });
            const filename = `audio.mp3`;
  
            const newBlob = await this.audioService.convertWebMToMp3(audioBlob);
            this.audioFile = new File([newBlob], filename, { type: 'audio/mpeg' });
  
            this.audioUrl = URL.createObjectURL(audioBlob); 
  
            await this.processFile(this.audioFile);
          } catch (error) {
            console.error('Erro ao processar o áudio:', error);
            this.alertService.error('Ops! Ocorreu um erro ao processar o áudio. Tente novamente mais tarde.');
          }
        }
      };
    }
  }
  
  cancelRecording(): void {
    if (this.recorder && this.isRecording) {
      this.isCancel = true;
      this.recorder.stop();
      this.isRecording = false;
      this.stream?.getTracks().forEach(track => track.stop());
    }
  }

  toggleRecording(idAttendance: string): void {
    if (this.isRecording) {
      this.isLoading = true;
      this.stopRecording();
      this.audioUrl = sessionStorage.getItem(`preview_audio_${idAttendance}`);
    } else {
      this.isLoading = false;
      this.isPreview = true;
      this.startRecording(idAttendance);
    }
  }

  cancelPreview(idAttendance: string): void {
    this.isLoading = false;
    sessionStorage.removeItem(`preview_audio_${idAttendance}`);
    this.audioUrl = null;
    this.audioFile = undefined;

    if (this.audioContainer) this.audioContainer.nativeElement.innerHTML = '';
  }

  getFormattedTime(): string {
    const minutes: string = Math.floor(this.recordingTime / 60).toString().padStart(2, '0');
    const seconds: string = (this.recordingTime % 60).toString().padStart(2, '0');

    return `${minutes}:${seconds}`;
  }

  shouldDisableTextarea(): boolean {
    return (this.attachment && this.contentType?.includes('audio/')) || 
           this.contentType === 'audio/mpeg' || 
           this.isRecording && !this.isNew;
  }
}
