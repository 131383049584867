import { NgModule } from '@angular/core';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { QRCodeModule } from 'angularx-qrcode';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { ChatbotModule } from '../chatbot/chatbot.module';
import { ResponseModule } from '../response/response.module';
import { WhatsappSignupModule } from '../whatsapp-signup/whatsapp-signup.module';
import { ChannelConfigComponent } from './channel-config/channel-config.component';
import { ChannelEditComponent } from './channel-edit/channel-edit.component';
import { ChannelFormComponent } from './channel-form/channel-form.component';
import { ChannelRoutingModule } from './channel-routing.module';
import { ChannelViewComponent } from './channel-view/channel-view.component';
import { ChannelComponent } from './channel.component';
import { SettingTemplateList } from './channel-config/setting-template-list/setting-template-list.component';

@NgModule({
  imports: [
    SharedModule,
    CardComponentModule,
    QRCodeModule,
    ChannelRoutingModule,
    WhatsappSignupModule,
    NgxChartsModule,
    ChatbotModule,
    ResponseModule,
  ],
  declarations: [
    ChannelComponent,
    ChannelFormComponent,
    ChannelConfigComponent,
    ChannelViewComponent,
    ChannelEditComponent,
    SettingTemplateList,
  ],
  exports: [
    ChannelComponent,
    ChannelFormComponent,
    ChannelConfigComponent,
    ChannelViewComponent,
    ChannelEditComponent,
    SettingTemplateList
  ],
  providers: [],
  schemas: []
})
export class ChannelModule { }
