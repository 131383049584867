<div class="flex flex-1 flex-col px-5">
    <div class="mb-4">
        <h1 class="font-black text-[20px] text-black">Relatórios</h1>
        <h6 class="text-zinc-400 font-light text-sm my-2">Selecione um dos gráfico para consultar e exportar
        </h6>
        <hr />
    </div>

    <div class="card-report flex flex-wrap gap-3">
        <div class="cursor-pointer bg-white p-4 shadow rounded-lg w-full max-w-[calc(33.333%-1rem)] h-[200px] border-2 border-gray-100" (click)="viewPerformece('Performance atendente')">
          <div class="flex justify-between items-center">
            <i class="ph ph-projector-screen-chart text-[24px]"></i>
          </div>
          <div class="flex flex-col h-full justify-center items-start whitespace-pre-line">
            <h2 class="text-black">Performance atendente</h2>
            <span class="text-sm mt-2 text-gray-400">Relatório de performance de atendimento</span>
          </div>
        </div>
      
        <div class="cursor-pointer bg-white p-4 shadow rounded-lg w-full max-w-[calc(33.333%-1rem)] h-[200px] border-2 border-gray-100" (click)="viewContact('Novos contatos')">
          <div class="flex justify-between items-center">
            <i class="ph ph-projector-screen-chart text-[24px]"></i>
          </div>
          <div class="flex flex-col h-full justify-center items-start whitespace-pre-line">
            <h2 class="text-black">Performance novo contatos</h2>
            <span class="text-sm mt-2 text-gray-400">Relatório de performance de novos contatos</span>
          </div>
        </div>
      
        <div *ngIf="env !== 'prod'" class="cursor-pointer bg-white p-4 shadow rounded-lg w-full max-w-[calc(33.333%-1rem)] h-[200px] border-2 border-gray-100" >
          <div class="flex justify-between items-center">
            <i class="ph ph-projector-screen-chart text-[24px]"></i>
          </div>
          <div class="flex flex-col h-full justify-center items-start whitespace-pre-line">
            <h2 class="text-black">Cloud API</h2>
            <span class="text-sm mt-2 text-gray-400">Relatório de Cloud API</span>
          </div>
        </div>

        <div class="cursor-pointer bg-white p-4 shadow rounded-lg w-full max-w-[calc(33.333%-1rem)] h-[200px] border-2 border-gray-100" (click)="viewImportation()">
          <div class="flex justify-between items-center">
            <i class="ph ph-projector-screen-chart text-[24px]"></i>
          </div>
          <div class="flex flex-col h-full justify-center items-start whitespace-pre-line">
            <h2 class="text-black">Importações</h2>
            <span class="text-sm mt-2 text-gray-400">Relatório de importações</span>
          </div>
        </div>
      </div>
      
      
</div>