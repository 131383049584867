import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { Attendance, AttendanceService, Company, CompanyService, ContactService, Message, MessageService, Note, NoteService, Pager, Tag, TagService, User, UserService } from "lib-trend-core";
import { of, Subject } from "rxjs";
import { catchError, concatMap, exhaustMap, map, mergeMap, takeUntil } from "rxjs/operators";
import * as AttendanceActions from "./actions";
import { CountState, PagerParamsState } from './app.state';

@Injectable()
export class Effects {

  private destroy$ = new Subject<void>();

  constructor(
    private store: Store,
    private actions$: Actions,
    private attendanceService: AttendanceService,
    private messageService: MessageService,
    private companyService: CompanyService,
    private userService: UserService,
    private noteService: NoteService,
    private tagService: TagService,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(AttendanceActions.clearFullState({ clear: true }));
  }

  ngOnDestroy(): void {
    this.destroyEffects();
  }

  destroyEffects(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadInitCompanyEffect$ = createEffect(() => {
    let userSelector: User = undefined;
    return this.actions$.pipe(
      ofType(AttendanceActions.getLoadInit),
      exhaustMap((action) =>
        this.userService.getById(action.idUser).pipe(
          takeUntil(this.destroy$),
          mergeMap((user: User) => {
            userSelector = user;
            return this.companyService.getById(action.idCompany);
          }),
          map((company: Company) => {
            return AttendanceActions.setLoadInit({ user: userSelector, company: company });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  attedancesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendances),
      exhaustMap((action) => {
        return this.attendanceService.getAll(action.page, action.perPage, action.search, action.params).pipe(
          takeUntil(this.destroy$),
          map((pager: Pager<Attendance>) => {
            return AttendanceActions.getAttendancesSuccess({ attendances: pager });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      }))
  });

  attedanceEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendance),
      exhaustMap((action) =>
        this.attendanceService.getById(action.idAttendance).pipe(
          takeUntil(this.destroy$),
          map((attendance: Attendance) => {
            return AttendanceActions.setAttendance({ attendance });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  messagesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getMessages),
      exhaustMap((action) =>
        this.messageService.getAll(action.page, action.perPage, null, { attendance: action.idAttendance }).pipe(
          takeUntil(this.destroy$),
          concatMap((messages: Pager<Message>) => {
            if ((!!messages && messages.list.length > 0 && messages.list[0].attendance.countUnreadMessages > 0)) {
              return this.attendanceService.updateCountUnreadMessages(messages.list[0].attendance._id)
                .pipe(
                  map(() => messages)
                );
            } else {
              return of(messages);
            }
          }),
          map((pager: Pager<Message>) => {
            return AttendanceActions.setMessages({ messages: pager });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  notesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getNotes),
      exhaustMap((action) =>
        this.noteService.getByAttendanceAndContact(action.idAttendance, action.idContact).pipe(
          takeUntil(this.destroy$),
          map((notes: Array<Note>) => AttendanceActions.setNotes({ notes })),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  pagerParamsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getAttendances),
      exhaustMap((action) => {
        return of({
          page: action.page,
          perPage: action.perPage,
          search: action.search,
          params: action.params
        }).pipe(
          takeUntil(this.destroy$),
          map((pagerParams: PagerParamsState) => {
            return AttendanceActions.setPagerParams({ pagerParams })
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message })))
        )
      })
    )
  });

  tagsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getTags),
      exhaustMap((action) =>
        this.tagService.getList().pipe(
          takeUntil(this.destroy$),
          map((tags: Array<Tag>) => {
            return AttendanceActions.setTags({ tags });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

  countsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AttendanceActions.getCounts),
      exhaustMap((action) =>
        this.attendanceService.getCountAttendancesByStatus(action.search, action.params).pipe(
          takeUntil(this.destroy$),
          map((counts: Array<CountState>) => {
            return AttendanceActions.setCounts({ counts });
          }),
          catchError((error) => of(AttendanceActions.setFailure({ error: error.message }))),
        )
      )
    );
  });

}
