import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { CardComponentModule, SharedModule } from 'lib-trend-core';
import { WaTemplateModule } from '../wa-template/wa-template.module';
import { CampaignEvoTemplateComponent } from './campaign-evo-template/campaign-evo-template.component';
import { CampaignFormComponent } from './campaign-form/campaign-form.component';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaigSendMessageComponent } from './campaign-send-message/campaign-send-message.component';
import { CampaignTemplateListComponent } from './campaign-template-list-dialog/campaign-template-list.component';
import { CampaingSaveConfirmModalComponent } from './campaing-save-confirm-modal/campaing-save-confirm-modal.component';
import { CampaignWaTemplateMessageComponent } from './campaign-wa-template-message/campaign-wa-template-message.component';



@NgModule({
  imports: [
    SharedModule,
    CommonModule,
    CampaignRoutingModule,
    WaTemplateModule,
    CardComponentModule,
    PickerModule
  ],
  declarations: [
    CampaignListComponent,
    CampaignFormComponent,
    CampaignTemplateListComponent,
    CampaignEvoTemplateComponent,
    CampaingSaveConfirmModalComponent,
    CampaignWaTemplateMessageComponent,
    CampaigSendMessageComponent
  ],
  exports: [
    CampaignListComponent,
    CampaignFormComponent,
    CampaignTemplateListComponent,
    CampaingSaveConfirmModalComponent,
    CampaignWaTemplateMessageComponent,
  ],
  providers: [
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },

  ]
})
export class CampaignModule { }
