import { Component, Inject, Injector, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { AbstractComponent } from "lib-trend-core";
import { Note } from "lib-trend-core";
import { Contact } from "lib-trend-core";
import { NoteService } from "lib-trend-core";
import { AlertService } from "lib-trend-core";
import { BehaviorSubject, Observable } from "rxjs";

@Component({
  selector: 'contact-view-note-edit-component',
  templateUrl: './contact-view-note-edit.component.html'
})
export class ContactViewNoteEditComponent extends AbstractComponent implements OnInit {
  note: Note;
  contact: Contact;
  override isNew: boolean;
  isEditing: boolean = false;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$: Observable<boolean> = this.loadingSpinnerSubject.asObservable();

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<ContactViewNoteEditComponent>,
    private noteService: NoteService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      note?: Note, 
      contact: Contact 
    }
  ) {
    super(injector);
    this.contact = this.data.contact;
    this.createForm();
  }

  ngOnInit() {
    this.isNew = (!this.data.note);
    this.isEditing = this.isNew;

    if (!this.isNew) {
      this.note = { ...this.data.note };
      this.formGroup.patchValue({
        description: this.note.description
      });
    }
  }

  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      description: ['', Validators.required]
    });
  }

  save() {
    if (this.formGroup.invalid) {
      this.alertService.error('Por favor, preencha a descrição.');
      return;
    }

    this.loadingSpinnerSubject.next(true);
    const noteData = this.formGroup.value;

    if (this.isNew) {
      const newNote: Note = {
        ...noteData,
        contact: this.contact._id,
        removed: false,
        attendance: null,
        createdAt: new Date(),
        updatedAt: new Date()
      } as Note;

      this.noteService.create(newNote).subscribe({
        next: (createdNote) => {
          this.loadingSpinnerSubject.next(false);
          this.alertService.success('Nota criada com sucesso.');
          this.dialogRef.close(createdNote);
          
        },
        error: (err) => {
          this.loadingSpinnerSubject.next(false);
          this.alertService.error(err.error.message);
        }
      });
    } else {
      const updatedNote: Note = {
        ...this.note,
        description: noteData.description,
        updatedAt: new Date()
      };

      this.noteService.update(this.note._id, updatedNote).subscribe({
        next: (updatedNote) => {
          this.loadingSpinnerSubject.next(false);
          this.alertService.success('Nota atualizada com sucesso.');
          this.dialogRef.close(updatedNote);
        },
        error: (err) => {
          this.loadingSpinnerSubject.next(false);
          this.alertService.error(err.error.message);
        }
      });
    }
  }

  toggleEditing() {
    this.isEditing = true;
  }

  cancel() {
    this.dialogRef.close(null);
  }
}