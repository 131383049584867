import { Component, Injector, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Channel, ChannelService, ChannelTypeEnum, ConfirmationComponent, Pager } from 'lib-trend-core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import { ChannelConfigComponent } from './channel-config/channel-config.component';
import { ChannelEditComponent } from './channel-edit/channel-edit.component';

enum StatusConnection {
  CONNECTED = 'Conectado',
  DISCONNECT = 'Desconectado',
};

@Component({
  selector: 'channel-component',
  templateUrl: 'channel.component.html'
})
export class ChannelComponent extends AbstractComponent implements OnInit {

  pager: Pager<Channel> = new Pager<Channel>({ perPage: 9 });

  listObservable: Observable<Pager<Channel>>;
  private termOfSearch: Subject<string> = new Subject<string>();

  cloudAPI: ChannelTypeEnum = ChannelTypeEnum.CLOUD_API;
  evoAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_API;
  evoGoAPI: ChannelTypeEnum = ChannelTypeEnum.EVOLUTION_GO_API;

  constructor(
    public injector: Injector,
    public dialog: MatDialog,
    private channelService: ChannelService
  ) {
    super(injector);
  }

  ngOnInit() {
    this.getList();
  }

  getList() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
    }

    this.channelService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (pager: Pager<Channel>) => {
        this.pager = pager;
        this.setupObservableSearch();
      },
      error: (err) => this.alertService.error(err.error.message)
    })
  }

  loadPage(page: number) {
    this.pager.page = page;
    this.getList();
  }

  delete(channel: Channel) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        this.channelService.delete(channel._id).subscribe({
          next: () => {
            this.getList();
            this.alertService.success();
          },
          error: err => this.alertService.error(err.error.message)
        })
      }
    });
  }

  // activated(): void {
  //   this.channelService.actived(this.channelSelected._id).subscribe({
  //     next: () => {
  //       this.getList();
  //       this.alertService.success('Operação realizada com sucesso');
  //     },
  //     error: (err) => this.alertService.error(err.error.message)
  //   });
  // }

  // unactivated(): void {
  //   this.channelService.unactived(this.channelSelected._id).subscribe(
  //     () => {
  //       this.getList();
  //       this.alertService.success('Operação realizada com sucesso');
  //     },
  //     err => this.alertService.error(err.error.message)
  //   );
  // }

  setupObservableSearch() {
    this.listObservable = this.termOfSearch
      .pipe(debounceTime(500))
      .pipe(distinctUntilChanged())
      .pipe(switchMap(term => {
        return this.channelService.getAll(this.pager.page, this.pager.perPage, term, this.searchParams);
      }));
    this.listObservable.subscribe((pager: Pager<Channel>) => {
      this.pager = pager;
    });
  }

  search(term: string) {
    this.pager.page = 1;
    this.searchString = term;
    this.termOfSearch.next(term);
  }

  edit(item: Channel): void {
    const dialogRef = this.dialog.open(ChannelEditComponent, {
      width: '600px',
      data: item
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getList();
    });
  }


  goStatusChannel(channel: Channel): void {
    const dialogRefStatus = this.dialog.open(ChannelConfigComponent, {
      width: '600px',
      data: channel,
    });

    dialogRefStatus.afterClosed().subscribe(() => {
      this.getList();
    });

  }

  add() {
    this.router.navigate(['/channel/add']);
  }

  formattedNumberInfoChannel(numberInfo: string): string {
    if (!!numberInfo) {
      return numberInfo?.replace(/@s\.whatsapp\.net/, '')?.split(':')[0];
    }

    return '-';
  }

  getConnectionOfChannel(channel: Channel): string {
    if (!!channel.metadata) {
      if (channel.type === ChannelTypeEnum.EVOLUTION_GO_API) {
        return channel.metadata?.['connected'] ? StatusConnection.CONNECTED : StatusConnection.DISCONNECT;
      } else if (channel.type === ChannelTypeEnum.EVOLUTION_API) {
        return channel.metadata?.['connectionStatus'] === 'connecting' ? StatusConnection.DISCONNECT : StatusConnection.CONNECTED;
      } else {
        return StatusConnection.CONNECTED;
      }
    } else {
      return StatusConnection.DISCONNECT;
    }
  }
}
