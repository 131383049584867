<form class="tag-main" [formGroup]="formGroup">
  <h4 class="label-attendance mb-1">Campos</h4>
  <div class="main-tag-selector">
    @if (showSelectCustomField) {
    <button class="button-add" (click)="toogleSelectCustomField()">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.8337 6.83268H6.83366V11.8327H5.16699V6.83268H0.166992V5.16602H5.16699V0.166016H6.83366V5.16602H11.8337V6.83268Z"
          fill="#4213F6" />
      </svg>
    </button>
    } @else {
    <mat-select class="input-field" formControlName="selectedFields" multiple
      (selectionChange)="addCustomField($event.value)" placeholder="Selecione">
      @for (item of listFieldsCustom; track $index) {
      <mat-option [value]="item._id">{{ item.name }}</mat-option>
      }
    </mat-select>
    }
  </div>
  <div class="w-full">
    <div class="w-full">
      @for (item of listCustomField; track $index) {
      @switch (getFieldType(item._id)) {
      @case ('TEXT') {
      <div
        class="mt-[15px] mb-[15px] flex bg-slate-200 rounded-lg p-3 gap-2 items-center justify-between h-[20px] shadow-md">
        <label class="font-bold text-[11px] text-black">{{ (item.name.charAt(0).toUpperCase() +
          item.name.slice(1).toLowerCase()) | truncate: 5 }}:</label>
        <input type="text" class="text-black text-[11px] outline-none bg-transparent rounded-lg"
          [formControlName]="item._id" placeholder="Coloque o valor aqui...">
        <div class="flex items-center justify-center">
          <button class="flex items-center" (click)="editCustomField(item._id)"><mat-icon
              class="text-[1rem] mt-2 text-[#4213F6]">create</mat-icon></button>
          <button class="text-[#4213F6] text-[11px]" (click)="removeCustomField(item._id)">x</button>
        </div>
      </div>
      }
      @case ('NUMBER') {
      <div
        class="mt-[15x] mb-[15px] flex bg-slate-200 p-3 rounded-lg gap-2 items-center justify-between h-[20px] shadow-md">
        <label class="font-bold text-[11px] text-black">{{ (item.name.charAt(0).toUpperCase() +
          item.name.slice(1).toLowerCase()) | truncate: 5 }}:</label>
        <input type="number" class="text-black text-[11px] bg-transparent outline-none rounded-lg"
          [formControlName]="item._id" placeholder="Coloque o valor aqui...">
        <div class="flex items-center justify-center">
          <button class="flex items-center" (click)="editCustomField(item._id)"><mat-icon
              class="text-[1rem] mt-2 text-[#4213F6]">create</mat-icon></button>
          <button class="text-[#4213F6] text-[11px]" (click)="removeCustomField(item._id)">x</button>
        </div>
      </div>
      }
      @case ('FREETEXT') {
      <div
        class="mt-[15px] mb-[15px] flex bg-slate-200 p-3 rounded-lg gap-2 items-center justify-between h-[20px] shadow-md">
        <label class="font-bold text-[11px] text-black">{{ (item.name.charAt(0).toUpperCase() +
          item.name.slice(1).toLowerCase()) | truncate: 5}}:</label>
        <textarea class="text-black text-[11px] bg-transparent rounded-lg"
          style="width: 100%; resize: none; outline: none;" maxlength="320" [formControlName]="item._id"></textarea>
        <div class="flex items-center">
          <button class="flex items-center" (click)="editCustomField(item._id)"><mat-icon
              class="text-[1rem] mt-2 text-[#4213F6]">create</mat-icon></button>
          <button class="text-[#4213F6] text-[11px]" (click)="removeCustomField(item._id)">x</button>
        </div>
      </div>
      }
      @case ('DATE') {
      <div class="flex mt-[15px] mb-[15px] bg-slate-200 p-3 rounded-lg gap-2 items-center justify-between h-[20px] ">
        <label class="font-bold text-[11px] text-black">{{ (item.name.charAt(0).toUpperCase() +
          item.name.slice(1).toLowerCase()) | truncate: 5 }}:</label>
        <div class="bg-transparent flex items-center h-[100%] rounded-lg">
          <input class="text-black bg-transparent text-[11px] rounded-lg" [matDatepicker]="picker"
            [formControlName]="item._id" placeholder="Coloque o valor aqui..." (focus)="picker.open()">
          <mat-datepicker #picker></mat-datepicker>
        </div>
        <div class="flex items-center">
          <button class="flex items-center" (click)="editCustomField(item._id)"><mat-icon
              class="text-[1rem] mt-2 text-[#4213F6]">create</mat-icon></button>
          <button class="text-[#4213F6] text-[11px]" (click)="removeCustomField(item._id)">x</button>
        </div>
      </div>
      }
      }
      }
    </div>
  </div>
</form>
