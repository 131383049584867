import { Component, ElementRef, Injector, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { AbstractComponent, Company, ConfirmationComponent, FirebaseService, RoleUserEnum, UploadTypeEnum, User, UserService, UtilHelper } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrl: './user-form.component.scss'
})
export class UserFormComponent extends AbstractComponent {
  @ViewChild('picker', { static: true }) picker: MatDatepicker<Date>;

  @ViewChild('inputfile', { static: false }) inputfile: ElementRef;

  @ViewChild('slideToggleAdmin') slideToggleAdmin: MatSlideToggle;
  @ViewChild('slideToggleSupervisor') slideToggleSupervisor: MatSlideToggle;
  @ViewChild('slideToggleAttendant') slideToggleAttendant: MatSlideToggle;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  user: User = {} as User;
  file: File;
  bytes: string;

  constructor(
    injector: Injector,
    public firebaseService: FirebaseService,
    public userService: UserService,
    public dialog: MatDialog,
  ) {
    super(injector);
    this.createForm();
  }

  async ngOnInit() {
    const userId = this.getParam("id");
    this.isNew = !userId;

    if (userId) {
      this.userService.getById(userId).subscribe(user => {
        this.user = user;
        if (this.user) {
          this.formGroup.patchValue({
            ...this.user,
            roleAdmin: this.user.roles.includes(RoleUserEnum.ADMIN),
            roleSupervisor: this.user.roles.includes(RoleUserEnum.SUPERVISOR),
            roleAttendant: this.user.roles.includes(RoleUserEnum.ATTENDANT)
          });
        };
      });
    };
  }

  /***/
  private createForm(): void {
    this.formGroup = this.formBuilder.group({
      _id: [null, []],
      name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(255)]],
      email: [null, [Validators.required, Validators.pattern(this.emailRegex)]],
      phone: [null, [Validators.required,]],
      tin: [null, []],
      birthDate: [null, []],
      image: [null, []],
      signatureConversation: [false, []],
      roleAdmin: [false, []],
      roleSupervisor: [false, []],
      roleAttendant: [false, []],
      addLeads: [false, []],
      clientVisibilitys: [false, []],
      deleteMessage: [false, []],
      editMessage: [false, []],
      viewFullHistory: [false, []],
    });
  }

  /***/
  addImage() {
    this.inputfile.nativeElement.click();
  }

  /***/
  back(): void {
    history.back();
  }

  userImage() {
    const imageUser = this.user.image;

    if (imageUser) {
      return imageUser;
    } else {
      return UtilHelper.no_image;
    }
  }

  save() {
    if (!this.formGroup.valid) {
      this.validateAllFormFields(this.formGroup);
      return;
    };

    const roles = [];
    if (this.formGroup.value.roleAdmin) roles.push(RoleUserEnum.ADMIN);
    if (this.formGroup.value.roleSupervisor) roles.push(RoleUserEnum.SUPERVISOR);
    if (this.formGroup.value.roleAttendant) roles.push(RoleUserEnum.ATTENDANT);

    const user = { ...this.formGroup.value, roles: roles };
    delete user.roleAdmin;
    delete user.roleAttendant;
    delete user.roleSupervisor;

    user.company = <Company>{ _id: super.getIDCurrentCompany() };

    if (this.isNew) {
      this.userService.create(user).subscribe({
        next: (value) => this.alertService.success(),
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.back(),
      });
    } else {
      this.userService.update(this.user._id, user).subscribe({
        next: (value) => this.alertService.success(),
        error: (err) => this.alertService.error(err.error.message),
        complete: () => this.back()
      });
    }
  }

  deleteUser(): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, { width: '600px' });
    this.route.paramMap.subscribe((params) => {
      const id = params.get('id');

      dialogRef.afterClosed().subscribe((result) => {
        if (Boolean(result) === true) {
          this.userService.delete(id).subscribe({
            next: () => {
              this.alertService.success();
              this.router.navigate(['/user']);
            },
            error: (err) => this.alertService.error(err.error.message),
          });
        };
      });
    });
  };

  openFileBrowser(event: any) {
    event.preventDefault();
    const element: HTMLElement = document.getElementById('dropzone-file') as HTMLElement;
    element.click();
  }

  onSelectImagem(event) {
    const target = event.target;
    const files: FileList = target.files;

    if (files[0].size > 1000000) { // 1 MB
      this.alertService.warning('Imagem inválida. O tamanho do arquivo de imagem deve ser inferior a 1 MB.');
      return;
    }

    if (!(files[0].type === 'image/jpeg' || files[0].type === 'image/png')) {
      this.alertService.warning('Imagem inválida. O arquivo de imagem deve estar no formato JPG ou PNG.');
      return;
    }

    this.file = files[0];

    let reader = new FileReader();
    reader.onload = async (file) => {
      if (this.file) {
        this.loadingSpinnerSubject.next(true);
        const dateMilisecond = new Date().getTime();
        const filename: string = dateMilisecond.toString();

        this.firebaseService.uploadFile(this.file, filename, UploadTypeEnum.USER).then((snapshot: { url: string }) => {
          this.loadingSpinnerSubject.next(false);
          this.user.image = snapshot.url;
          this.formGroup.patchValue({ image: snapshot.url });
        }).catch((error) => this.alertService.error('Ops! Ocorreu um erro ao tentar realizar upload do seu arquivo.'));
      }
    };
    reader.readAsArrayBuffer(this.file);
  }

  toogleRoles(checked: boolean, role: RoleUserEnum | string): void {
    if (role === RoleUserEnum.ADMIN) {
      this.slideToggleSupervisor.writeValue(checked);
      this.slideToggleAttendant.writeValue(checked);
      this.formGroup.patchValue({ roleSupervisor: checked, roleAttendant: checked });
    } else if (role === RoleUserEnum.SUPERVISOR) {
      this.slideToggleAdmin.writeValue(false);
      this.slideToggleAttendant.writeValue(checked);
      this.formGroup.patchValue({ roleAdmin: false, roleAttendant: checked });
    } else if (role === RoleUserEnum.ATTENDANT) {
      this.slideToggleAdmin.writeValue(false);
      this.slideToggleSupervisor.writeValue(false);
      this.formGroup.patchValue({ roleAdmin: false, roleSupervisor: false })
    }
  }

  removeMidia(): void {
    if (this.user.image) {
      this.firebaseService.deleteFile(this.user.image)
        .then(() => {
          this.resetFileState();
        })
        .catch(error => {
          this.alertService.error('Ops! Ocorreu um erro ao tentar deletar o arquivo.');
          this.resetFileState();
        });
    }
  }

  private resetFileState(): void {
    this.user.image = '';
  }

}
